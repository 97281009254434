import React, {useEffect, useState} from 'react';
import './style.css';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar'

import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
import Activity from "./Activity";
import LastOpportunities from "./LastOpportunities";
import {server, server_path_files} from "../../const";
import {toast} from "react-toastify";
import moment from 'moment'
import {useIntl} from "react-intl";

export default function Dashboard() {

    var total = 0

    const intl = useIntl();
    const token = sessionStorage.getItem('token')
    const profileType = sessionStorage.getItem('profileType')
    const [currency,setCurrency] = useState(sessionStorage.getItem('currency'))
    
    const [tabAgents, setTabAgents] = useState(true);
    const [tabLeads, setTabLeads] = useState(false);
    const [tabOpportunity, setTabOpportunity] = useState(false);
    const [stats, setStats] = useState('');

    useEffect(() => {
        var data = new FormData()
        if (profileType === 'user') {
            data.append('role', 'user')
        } else {
            data.append('role', 'company')
        }

        const getStats = () => {
            fetch(server + '/stats', {
                method: 'POST',
                body: data,
                headers: {
                    //   'Accept': 'application/json',
                    //    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    setStats(json['data'])
                    if (json['data']['agent']) {
                        if (json['data']['agent']['currency'] === 0) {
                            sessionStorage.setItem('currency', ' Ron')
                        } else {
                            sessionStorage.setItem('currency', ' €')
                        }
                    } else {
                        if (json['data']['company'] !== null) {
                            if (json['data']['company']['currency'] === 0) {
                                sessionStorage.setItem('currency', ' Ron')
                            } else {
                                sessionStorage.setItem('currency', ' €')
                            }
                        }
                    }
                }

                setCurrency(sessionStorage.getItem('currency'))
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        getStats()
    }, [token, profileType, currency, intl]);


    return (
        
        stats !== '' && currency !== null &&
        <div className={"dashboard"}>
            <Row>
                <Col md={4}>
                    <div className={"item congrat"}>
                        <h2>
                            
                            {stats.congratulations ?
                                stats.agent ?
                                    intl.formatMessage({id: 'congratulations'}) + ' '+ stats.agent.first_name + ' ' + stats.agent.last_name + '! 🎉'
                                    :
                                    stats.company ?
                                        intl.formatMessage({id: 'congratulations'}) + ' '+ stats.company.name + '! 🎉' :
                                        ''
                                :
                                intl.formatMessage({id: 'at_the_moment_you_have_no_achieved_objective'})
                            }


                            
                        </h2>
                        <Row>
                            <Col md={6}>
                                {/*  <div className={"desc"}>
                                    Proiectul lunei
                                </div>*/}
                                {
                                    stats.congratulations && <div className={"views"}>
                                        {stats.congratulations.id ? stats.congratulations.value : ''}
                                        {' '}
                                        {
                                            stats.congratulations.type === 0 ?
                                                stats.congratulations.value === 1 ?
                                                    intl.formatMessage({id: 'views_first_lowercase'}):
                                                intl.formatMessage({id: 'views_first_lowercase'})
                                                :
                                                stats.congratulations.value === 1 ?
                                        intl.formatMessage({id: 'contract_first_lowercase'}) :
                                        intl.formatMessage({id: 'contracts_first_lowercase'})
                                        }
                                    </div>}
                                {/*  <Link to={"/collaboration"}>
                                    <Button>View detailes</Button>
                                </Link>*/}
                            </Col>
                            <Col md={6} className={"congratImage"}>
                                <img src={"/images/dashboard/Group 1351.svg"} alt={""}/>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={8}>
                    <div className={"item"}>
                        <h2>
                            {intl.formatMessage({id: 'statistically'})}
                        </h2>
                        <Row className={"m-0"}>
                            <Col md={4} className={"statisticIdem"}>
                                <Row>
                                    <Col md={3} className={"statisticImage green"}>
                                        <img src={"/images/dashboard/building-2-line.svg"} alt={""}/>
                                    </Col>
                                    <Col md={9} className={"statisticInfo"}>
                                        <h3>{stats.statistics.companies}</h3>
                                        <Link to={"/collaboration/companies"}>
                                            {intl.formatMessage({id: 'number_of_companies'})}
                                            <img src={"/images/dashboard/Vector-green.svg"} alt={""}/>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} className={"statisticIdem"}>
                                <Row>
                                    <Col md={3} className={"statisticImage yellow"}>
                                        <img src={"/images/dashboard/user-3-line.svg"} alt={""}/>
                                    </Col>
                                    {
                                        sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?

                                            <Col md={9} className={"statisticInfo"}>
                                                <h3>{stats.statistics.agents}</h3><Link to={"/collaboration/agents"}>
                                                {intl.formatMessage({id: 'number_of_agents'})}
                                                <img src={"/images/dashboard/Vector-yellow.svg"} alt={""}/>
                                            </Link>
                                            </Col>
                                            :

                                            <Col md={9} className={"statisticInfo"}>
                                                <h3>{stats.statistics.leads}</h3> <Link to={"/finance"}>
                                                {intl.formatMessage({id: 'number_of_leads'})}
                                                <img src={"/images/dashboard/Vector-yellow.svg"} alt={""}/>
                                            </Link>
                                            </Col>
                                    }

                                </Row>
                            </Col>
                            <Col md={4} className={"statisticIdem"}>
                                <Row>
                                    <Col md={3} className={"statisticImage blue"}>
                                        <img src={"/images/dashboard/BriefcaseSimpleBlue.svg"} alt={""}/>
                                    </Col>
                                    {
                                        sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?

                                            <Col md={9} className={"statisticInfo"}>
                                                <h3>{stats.statistics.leads}</h3><Link to={"/leads"}>
                                                {intl.formatMessage({id: 'contracts_unsigned'})}Nr de leaduri
                                                <img src={"/images/dashboard/arrow-right-line-blue.svg"} alt={""}/>
                                            </Link>
                                            </Col>
                                            :

                                            <Col md={9} className={"statisticInfo"}>
                                                <h3>{stats.statistics.contracts}</h3><Link to={"/contracts"}>
                                                {intl.formatMessage({id: 'contracts_concluded'})}
                                                <img src={"/images/dashboard/arrow-right-line-blue.svg"} alt={""}/>
                                            </Link>
                                            </Col>
                                    }

                                </Row>
                            </Col>
                        </Row>
                    </div>

                </Col>
            </Row>

            {
                sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                    <Row>
                        <Col md={8}>
                            <Chart1
                                active={stats.visitors_actives}
                                total={stats.visitors_profile}/>
                            <Chart2
                                total={stats.order_summary_total}
                                paid={stats.order_summary_paid}
                            />
                        </Col>
                        <Col md={4}>
                            <Activity
                                stats={stats.activity}/>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col md={8}>
                            <Chart2
                                total={stats.order_summary_total}
                                paid={stats.order_summary_paid}
                            />
                            <div className={"tableALO"}>
                                <Row>
                                    <Col md={4} className={tabAgents ? "active" : ""}>
                                        <Link to={""} onClick={() => {
                                            setTabAgents(true)
                                            setTabLeads(false)
                                            setTabOpportunity(false)
                                        }}>
                                            <div className={"tab"}>
                                                {intl.formatMessage({id: 'leads'})}
                                            </div>
                                        </Link>
                                    </Col>
                                    <Col md={4} className={tabOpportunity ? "active" : ""}>
                                        <Link to={""} onClick={() => {
                                            setTabAgents(false)
                                            setTabLeads(false)
                                            setTabOpportunity(true)
                                        }}>
                                            <div className={"tab"}>
                                                {intl.formatMessage({id: 'my_collaborations'})}
                                            </div>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row className={"tabsALO"}>
                                    <Col>
                                        <div className={"item"}>
                                            {
                                                tabAgents && stats.leads ?
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th>{intl.formatMessage({id: 'company_name'})}</th>
                                                            <th>{intl.formatMessage({id: 'date'})}</th>
                                                            <th>{intl.formatMessage({id: 'total_sales'})}</th>
                                                            <th>{intl.formatMessage({id: 'sales_objective'})}</th>
                                                            <th>{intl.formatMessage({id: 'percentage_achieved'})}</th>
                                                            <th>{intl.formatMessage({id: 'estimated_commission'})}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            stats.leads.map((lead, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div className={"d-inline"}>
                                                                            <img src={server_path_files + lead.logo} alt={""}/>
                                                                        </div>
                                                                        <div className={"d-inline"}>
                                                                            {lead.name}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className={"startDate"}>
                                                                            {moment(lead.created_at).format('YYYY-MM-DD')}
                                                                        </div>
                                                                        <div className={"endDate"}>
                                                                            {lead.deadline}
                                                                        </div>
                                                                    </td>
                                                                    <td className={"total"}>
                                                                        {(lead.paid !== null ? lead.paid : 0) + currency}
                                                                    </td>
                                                                    <td className={"obiectiv"}>
                                                                        {(lead.commission * lead.budget / 100) + currency}
                                                                    </td>
                                                                    <td className={"etape"}>
                                                                        <div className={"value"}>
                                                                            {parseFloat(lead.paid * 100 / (lead.commission * lead.budget / 100)).toFixed(1) + ' %'}
                                                                        </div>
                                                                        <ProgressBar variant="success" now={parseFloat(lead.paid * 100 / (lead.commission * lead.budget / 100)).toFixed(1)} max={100}/>
                                                                    </td>
                                                                    <td className={"comision"}>
                                                                        {(lead.commission * lead.budget / 100 - lead.paid) + currency}
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }

                                                        </tbody>
                                                        <thead>

                                                        <tr>
                                                            <th colSpan={1} className={"empty"}>

                                                            </th>
                                                            <th className={"green title"}>
                                                                {intl.formatMessage({id: 'total'})} :

                                                            </th>

                                                            <th className={"green comision"}>
                                                                {
                                                                    stats.leads.forEach((item, i) => {
                                                                        total += parseInt(item.paid !== null ? item.paid : 0)
                                                                    })
                                                                }
                                                                {parseFloat(total).toFixed(1)}
                                                                {total = parseInt(0)}

                                                            </th>
                                                            <th className={"green obiectiv"}>
                                                                {
                                                                    stats.leads.forEach((item, i) => {
                                                                        total += item.commission * item.budget / 100
                                                                    })
                                                                }
                                                                {total}
                                                                {total = parseInt(0)}
                                                            </th>
                                                            <th className={"green etape"}>
                                                                <div className={"value"}>
                                                                    {
                                                                        stats.leads.forEach((item, i) => {
                                                                            total += item.paid * 100 / (item.commission * item.budget / 100)
                                                                        })
                                                                    }
                                                                    {total}
                                                                    {total = parseInt(0)}
                                                                    {' %'}
                                                                </div>
                                                                <ProgressBar variant="success" now={total} max={100}/>

                                                            </th>
                                                            <th className={"green comision"}>
                                                                {
                                                                    stats.leads.forEach((item, i) => {
                                                                        total += item.commission * item.budget / 100 - item.paid
                                                                    })
                                                                }
                                                                {total}
                                                                {total = parseInt(0)}
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                    </table>
                                                    :
                                                    tabOpportunity && stats.companies ?
                                                        <div className={"tabLeads"}>
                                                            <Row>
                                                                <Col>

                                                                    <table>
                                                                        <thead>
                                                                        <tr>
                                                                            <th>
                                                                                {intl.formatMessage({id: 'company_name'})}
                                                                            </th>
                                                                            <th>
                                                                                {intl.formatMessage({id: 'domain'})} :
                                                                            </th>
                                                                            <th>
                                                                                {intl.formatMessage({id: 'total_leads'})}
                                                                            </th>
                                                                            <th>
                                                                                {intl.formatMessage({id: 'fee_charged'})}
                                                                            </th>
                                                                            <th>
                                                                                {intl.formatMessage({id: 'company_details'})}
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            stats.companies.map((company, i) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className={"d-inline"}>
                                                                                            <img src={server_path_files + company.logo} alt={""}/>
                                                                                        </div>
                                                                                        <div className={"d-inline"}>
                                                                                            {company.name}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td> {
                                                                                        company.domains.map((domain, i) => (
                                                                                            i < 2 &&
                                                                                            domain.activity + ', '
                                                                                        ))
                                                                                    }
                                                                                        {'[...]'}
                                                                                    </td>
                                                                                    <td>
                                                                                        {company.totalLeads + ' '}
                                                                                        leaduri
                                                                                    </td>
                                                                                    <td className={"leadValue"}>
                                                                                        {company.commission + ' %'}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Link to={"/company-about/" + company.user_id}>
                                                                                            <div className={"view"}>
                                                                                                {intl.formatMessage({id: 'view_profile'})}
                                                                                                <img src={"/images/dashboard/arrow-right-line-purple.svg"} alt={""}/>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                        </tbody>

                                                                    </table>
                                                                </Col>
                                                            </Row>
                                                        </div> :
                                                        <div></div>
                                            }
                                        </div>

                                    </Col>

                                </Row>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <Activity
                                    stats={stats.activity}/>
                            </div>
                            <div>

                                <LastOpportunities
                                    opportunities={stats.opportunities}
                                />
                            </div>
                        </Col>
                    </Row>
            }
            {
                sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                    <Row>
                        <Col md={4}>
                            <LastOpportunities
                                opportunities={stats.opportunities}
                            />
                        </Col>
                        <Col md={8} className={"tableALO"}>
                            <Row>
                                <Col className={tabAgents ? "active" : ""}>
                                    <Link to={""} onClick={() => {
                                        setTabAgents(true)
                                        setTabLeads(false)
                                        setTabOpportunity(false)
                                    }}>
                                        <div className={"tab"}>
                                            {intl.formatMessage({id: 'agents'})}
                                        </div>
                                    </Link>
                                </Col>
                                <Col className={tabLeads ? "active" : ""}>
                                    <Link to={""} onClick={() => {
                                        setTabAgents(false)
                                        setTabLeads(true)
                                        setTabOpportunity(false)

                                    }}>
                                        <div className={"tab"}>
                                            {intl.formatMessage({id: 'leads'})}
                                        </div>
                                    </Link>
                                </Col>
                                <Col className={tabOpportunity ? "active" : ""}>
                                    <Link to={""} onClick={() => {
                                        setTabAgents(false)
                                        setTabLeads(false)
                                        setTabOpportunity(true)
                                    }}>
                                        <div className={"tab"}>
                                            {intl.formatMessage({id: 'my_opportunities'})}
                                        </div>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className={"tabsALO"}>
                                <Col>
                                    <div className={"item"}>
                                        {
                                            tabAgents && stats.agents ?
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th className={"w-20"}>{intl.formatMessage({id: 'agent_name'})}</th>
                                                        <th className={"w-20"}>{intl.formatMessage({id: 'date'})}</th>
                                                        <th className={"w-12"}>{intl.formatMessage({id: 'total_sales'})}</th>
                                                        <th className={"w-12"}>{intl.formatMessage({id: 'sales_objective'})}</th>
                                                        <th className={"w-20"}>{intl.formatMessage({id: 'goal_achievement_percentage'})}</th>
                                                        <th className={"w-16"}>{intl.formatMessage({id: 'commission_due'})}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        stats.agents.map((agent, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    <div className={"d-inline"}>
                                                                        <img src={server_path_files + agent.logo} alt={""}/>
                                                                    </div>
                                                                    <div className={"d-inline"}>
                                                                        {agent.first_name + ' ' + agent.last_name}
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className={"startDate"}>
                                                                        {moment(agent.created_at).format('YYYY-MM-DD')}
                                                                    </div>
                                                                    <div className={"endDate"}>
                                                                        {agent.deadline}
                                                                    </div>
                                                                </td>
                                                                <td className={"total"}>
                                                                    {(agent.paid !== null ? agent.paid : 0) + currency}
                                                                </td>
                                                                <td className={"obiectiv"}>
                                                                    {(agent.commission * agent.budget / 100) + currency}
                                                                </td>
                                                                <td className={"etape"}>
                                                                    <div className={"value"}>
                                                                        {agent.commission !== null ? parseFloat(agent.paid * 100 / (agent.commission * agent.budget / 100)).toFixed(1) + ' %' : 0 + ' %'}
                                                                    </div>
                                                                    <ProgressBar variant="success" now={parseInt(agent.paid * 100 / (agent.commission * agent.budget / 100))} max={100}/>
                                                                </td>
                                                                <td className={"comision"}>
                                                                    {(agent.commission * agent.budget / 100 - agent.paid) + currency}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }

                                                    </tbody>
                                                    <thead>

                                                    <tr>
                                                        <th className={"empty"}>

                                                        </th>
                                                        <th className={"green title"}>
                                                            {intl.formatMessage({id: 'total'})} :

                                                        </th>
                                                        <th className={"green total"}>
                                                            {stats.totalPaidAgent + currency}
                                                        </th>
                                                        <th className={"green obiectiv"}>
                                                            {
                                                                stats.agents.forEach((item, i) => {
                                                                    total += item.commission * item.budget / 100
                                                                })
                                                            }
                                                            {parseFloat(total).toFixed(1)}
                                                            {total = parseInt(0)}
                                                            {currency}
                                                        </th>
                                                        <th className={"green etape"}>
                                                            <div className={"value"}>

                                                                {
                                                                    stats.agents.forEach((item, i) => (
                                                                        item.commission !== null && item.paid !== null ?
                                                                            total += item.paid * 100 / (item.commission * item.budget / 100)
                                                                            :
                                                                            ''
                                                                    ))
                                                                }
                                                                {parseFloat(total).toFixed(1)}

                                                                {total = parseInt(0)}
                                                                {' %'}
                                                            </div>
                                                            <ProgressBar variant="success" now={parseInt(total)} max={100}/>

                                                        </th>


                                                        <th className={"green comision"}>

                                                            {
                                                                stats.agents.forEach((item, i) => {
                                                                    total += item.commission * item.budget / 100 - item.paid
                                                                })
                                                            }
                                                            {total + currency}
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                </table>
                                                :
                                                tabLeads && stats.leads ?
                                                    <div className={"tabLeads"}>
                                                        <Row>
                                                            <Col>
                                                                <table>
                                                                    <tbody>
                                                                    {
                                                                        stats.leads.map((lead, i) => (
                                                                            <tr>
                                                                                <td>
                                                                                    <div className={"d-inline"}>
                                                                                        <img src={server_path_files + lead.logo} alt={""}/>
                                                                                    </div>
                                                                                    <div className={"d-inline"}>
                                                                                        {lead.first_name + ' ' + lead.last_name}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        lead.domains.map((domain, i) => (
                                                                                            i < 2 &&
                                                                                            domain.activity + ' '
                                                                                        ))
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {lead.totalLeads + ' '}
                                                                                    {intl.formatMessage({id: 'leads_first_lowercase'})}
                                                                                </td>
                                                                                <td>
                                                                                    {parseFloat(lead.commission).toFixed(2) + ' '}
                                                                                    % {intl.formatMessage({id: 'commision_first_lowercase'})}
                                                                                </td>
                                                                                <td>
                                                                                    <Link to={"/profile-job-history/" + lead.user_id}>
                                                                                        <div className={"view"}>
                                                                                            {intl.formatMessage({id: 'profile'})}
                                                                                            <img src={"/images/dashboard/arrow-right-line-purple.svg"} alt={""}/>
                                                                                        </div>
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    </tbody>

                                                                </table>
                                                            </Col>
                                                        </Row>
                                                    </div> :
                                                    tabOpportunity && stats.opportunity ?
                                                        <div className={"tabLeads"}>

                                                            <Row>
                                                                <Col>
                                                                    <table>
                                                                        <tbody>
                                                                        {
                                                                            stats.opportunity.map((company, i) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <div className={"d-inline"}>
                                                                                            <img src={server_path_files + company.logo} alt={""}/>
                                                                                        </div>
                                                                                        <div className={"d-inline"}>
                                                                                            {company.name}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            company.domains.map((domain, i) => (
                                                                                                i < 2 &&
                                                                                                domain.activity + ' '
                                                                                            ))
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {company.totalOpportunities + ' '}
                                                                                        {intl.formatMessage({id: 'opportunities_first_lowercase'})}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Link to={"/company-about/" + company.user_id}>
                                                                                            <div className={"view"}>
                                                                                                {intl.formatMessage({id: 'profile'})}
                                                                                                <img src={"/images/dashboard/arrow-right-line-purple.svg"} alt={""}/>
                                                                                            </div>
                                                                                        </Link>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                        </tbody>

                                                                    </table>
                                                                </Col>
                                                            </Row>
                                                        </div> :
                                                        <div></div>
                                        }
                                    </div>

                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    :
                    <Row></Row>
            }


        </div>
    )
}