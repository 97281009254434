import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import {readableMonths, server, server_path_files} from "../../const";
import DateSelector from "../Utils/DateSelector/DateSelector";
import {toast} from "react-toastify";
import moment from 'moment'
import Checkbox from "../Utils/Checkbox/Checkbox";
import {useIntl} from "react-intl";

export default function Invoices() {

    const date = new Date();
    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    var pay = []

    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [invoices, setInvoices] = useState('');
    const [unpaid, setUnpaid] = useState(0);
    const [payment, setPaymnet] = useState('');

    const incrementMonth = () => {
        setMonth(month === 11 ? 0 : month + 1)
    }
    const decrementMonth = () => {
        setMonth(month === 0 ? 11 : month - 1)
    }

    useEffect(() => {
        const getInvoices = () => {
            var data = new FormData()
            data.append('month', month)
            data.append('year', year)
            data.append('unpaid', unpaid)
            fetch(server + '/invoices', {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setInvoices(json['data'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getInvoices()
    }, [year, month, token,unpaid, intl])

    const addPayment = (invoice_id,due,payment) => {
        var data = new FormData()
        data.append('due', due)
        data.append('payment', payment[invoice_id])
        fetch(server + '/finance/add_payment/' + invoice_id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.reload()
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }


    return (
            <div className={"financeInvoices"}>
                <Row>
                    <Col className={"title"}>
                        {intl.formatMessage({id: 'invoices'})}
                    </Col>
                </Row>
             <div className={"invoicesList"}>
                 <Row>
                     <Col md={12}>

                         <DateSelector
                             value={readableMonths[month]}
                             increment={incrementMonth}
                             decrement={decrementMonth}
                         />

                         <DateSelector
                             value={year}
                             increment={() => setYear(year + 1)}
                             decrement={() => setYear(year - 1)}
                         />
                         <div className={"d-inline"} onClick={()=> setUnpaid(!unpaid)}>
                             <Checkbox
                                 ck={unpaid ? 1:0}
                                 info={intl.formatMessage({id: 'unpaid_invoices'})}
                                 disable={true}
                             />
                         </div>
                     </Col>
                 </Row>

                 { invoices !== '' &&
                     <Row>
                         <Col>
                             <div className={"table-container"}>
                                 <table className={"tasks invoices"}>
                                     <thead>
                                     <tr>
                                         <th>
                                             {intl.formatMessage({id: 'date'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'sales_agent'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'commission_applied'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'objective_achieved'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'objective'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'liability'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'paid'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'add_payment'})}
                                         </th>
                                         <th>
                                             {intl.formatMessage({id: 'invoice'})}
                                         </th>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     {
                                         invoices.map((item, i) => (
                                             <tr key={i}>
                                                 <td>
                                                     {moment(item.updated_at).format("YYYY-MM-DD")}
                                                 </td>
                                                 <td>
                                                     <img src={server_path_files + item.logo} alt={""}/>
                                                     {item.first_name + ' ' + item.last_name}
                                                 </td>
                                                 <td>
                                                     {item.commission + ' %'}
                                                 </td>
                                                 <td className={"obi"}>
                                                     {item.paidTotal + sessionStorage.getItem('currency')}
                                                 </td>

                                                 <td>
                                                     {item.commission * item.budget / 100  + sessionStorage.getItem('currency')}
                                                 </td>
                                                 <td className={"dat"}>
                                                     {item.due - item.paid_value  + sessionStorage.getItem('currency')}
                                                 </td>
                                                 <td>
                                                     {
                                                         item.paid === 1 &&
                                                         <img src={'/images/check-line.svg'} alt={""}/>
                                                     }
                                                 </td>
                                                 <td className={"p-0"}>
                                                     <div className={"addPayment"}>
                                                         <input type={'number'} max={item.due} value={payment[item.id]}  onChange={(e) => {
                                                             parseInt(e.target.value) > (item.due - item.paid_value) ?
                                                                 pay[item.id] = item.due- item.paid_value:
                                                                 pay[item.id] = e.target.value

                                                             setPaymnet(pay)

                                                         }}/>
                                                         <Button onClick={() => addPayment(item.id, item.due, payment)}>{intl.formatMessage({id: 'add'})}</Button>
                                                     </div>
                                                 </td>
                                                 <td onClick={() => window.location.href='/invoice/' + item.id}>
                                                     <a href={'/'}>
                                                         {intl.formatMessage({id: 'view'})}
                                                     </a>
                                                 </td>
                                             </tr>
                                         ))
                                     }

                                     </tbody>
                                 </table>
                             </div>
                         </Col>
                     </Row>
                 }

             </div>
            </div>
 

    )
}