import React from 'react';
import './style.css';
import {useParams} from 'react-router-dom'
import LeadOpportunityCreate from "../../Utils/LeadOpportunityCreate/LeadOpportunityCreate";
export default function OpportunityEdit() {

    const { id } = useParams()

    return (
        <div>
            <LeadOpportunityCreate
                id={id}
                opportunity={0}
                opportunityId={id}
            />
        </div>
    )
}