import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import FileCard from "../../Utils/FileCard/FileCard";
import {server} from "../../../const";
import {toast} from "react-toastify";
import moment from "moment";
import {useParams} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {useIntl} from "react-intl";

export default function CompanyFiles() {
    
    const [files,setFiles] = useState([])
    const [fileName, setFileName] = useState("");
    const user_id = useParams().id


    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    useEffect( () => {
        const getCompanyInfo = () => {

            fetch(server + '/company/' + user_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if(json['name']) {
                    setFiles(json['file'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        
        getCompanyInfo()
    },[token,user_id, intl] );

    const addNameFile = (file) => {
        confirmAlert({
            title: intl.formatMessage({id: 'enter_the_file_name'}),
            childrenElement:()=> <input onChange={(e) => {setFileName(e.target.value)}}/>,
            buttons: [
                {
                    label: intl.formatMessage({id: 'add'}),
                    onClick:() => addFile(file,fileName)
                },
                {
                    label: intl.formatMessage({id: 'cancel'}),
                }
            ]
        });
    };
    
    const addFile = (e,name) => {
        const data = new FormData();

        data.append('file[]', e);
        data.append('fileName', name);
        fetch(server + '/company/files', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
            
        }).then(response => {
            if (response.ok) {
              window.location.reload()
            }

            return response.json()
        }).then(json => {
            if (json['error']) {
                toast.warning(json['error']);
            }
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
return (
        <div className={"companyFiles"}>
            <Row>
                <Col>
                    <div className={"position-relative"}>
                        <h2 className={"mb-4 mt-2 d-inline-block"}>
                            { intl.formatMessage({id: 'download_the_company_presentation'})}
                        </h2>

                        <label  htmlFor={"file-upload"} className={"addFile btn-primary"}>
                            <img src={"/images/add-circle-line.svg"} alt={""}/>
                            { intl.formatMessage({id: 'add_file'})}
                        </label>
                        <input id={"file-upload"} className={"d-none"} type="file" onChange={(e) => addNameFile(e.target.files[0])}/>

                    </div>
                    

                    <div className={"filesContainer"}>
                        {
                            files.map((file,i) => (
                                <FileCard
                                    key={i}
                                    id={file.id}
                                    type={file.type}
                                    title={file.name}
                                    size={file.size}
                                    path={file.path}
                                    lastAccessed={moment(file.updated_at).format('YYYY-MM-DD')}
                                    deleteButton={true}
                                    pathDelete={'/company/file/'}
                                />
                            ))
                        }
                    </div>


                </Col>
            </Row>
        </div>
    )
}