import React from 'react';
import './style.css';
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {server, server_path_files} from "../../../const";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {useIntl} from "react-intl";

export default function CollaborationCard({id, image, name, description, request = false,collabFavorite = false, deleteCollab = false, favourite = false, requestData = '', lead = false,profilePath}) {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const addAccept = (value) => {
        var data = new FormData()
        data.append('accepted', value)
        data.append('user_id', id)
        fetch(server + ( lead ? '/lead/' : '/opportunity/') + 'accept_request/' + requestData, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            window.location.reload()
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
    const addFavorite = () => {
        fetch(server + '/favorite/add/' + id, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            window.location.reload()
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const deleteFavorite = () => {
        fetch(server + '/favorite/delete/' + id, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            window.location.reload()
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const deleteCollaboration = () => {
        fetch(server + '/deleteCollaborator/' + id, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            window.location.reload()
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const confirmDeleteCollaboration = () => {
        confirmAlert({
            title: intl.formatMessage({id: 'are_you_sure_you_want_to_delete_this_contributor'}),
            message: intl.formatMessage({id: 'if_you_do_not_intend_to_delete_click_cancel_if_you_want_to_confirm_the_deletion_click_delete'}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'delete'}),
                    onClick: () => deleteCollaboration()
                },
                {
                    label: intl.formatMessage({id: 'cancel'}),
                }
            ]
        });
    };
    const confirmDeleteFavorite = () => {
        confirmAlert({
            title: intl.formatMessage({id: 'are_you_sure_you_want_to_delete_this_contributor'}),
            message: intl.formatMessage({id: 'if_you_do_not_intend_to_delete_click_cancel_if_you_want_to_confirm_the_deletion_click_delete'}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'delete'}),
                    onClick: () => deleteFavorite()
                },
                {
                    label: intl.formatMessage({id: 'cancel'}),
                }
            ]
        });
    };



    return (
        <div className={"collabCard"}>
            <img src={"/images/Vector 68.svg"} alt={""} className={"imgLeft"}/>
            <img src={"/images/Vector 69.svg"} alt={""} className={"imgRight"}/>
            <div className={"profileImage"}>
                <img src={image ? (server_path_files + image) : ''} alt={""}/>
            </div>
            <div className={"name"}>
                {name}
            </div>
            <div className={"domain"}>

            </div>
            <Link to={profilePath + id}>
                <div className={"viewProfile"}>

                    { intl.formatMessage({id: 'profile'})}
                    <img src={"/images/arrow-right-line.svg"} alt={""}/>
                </div>
            </Link>

            <div className={"description"}>
                {description.substring(0, 50) + (description.length > 50 ? '...' : '')}
            </div>
            <div className={"message"}>
                <div>
                    {
                        request ?
                            <div className={"d-inline-block"}>
                                <Button variant={"primary"} className={"request"} onClick={() => addAccept(1)}>

                                    { intl.formatMessage({id: 'accept'})}
                                </Button>

                                <Button variant={"secondary"} className={"request"} onClick={() => addAccept(0)}>

                                    { intl.formatMessage({id: 'decline'})}
                                </Button>
                            </div>
                            :
                            <Button variant={"secondary"} href={'/inbox/' + id}>
                                <img src={"/images/send-plane-line-blue.svg"} alt={""}/>

                                { intl.formatMessage({id: 'message'})}
                            </Button>
                    }
                </div>
                {
                    deleteCollab &&
                    <div className={"action heart " + (collabFavorite ? 'active' : '')} onClick={() => addFavorite()}>
                        <img src={"/images/heart-3-line.svg"} alt={""}/>
                    </div>
                }


                {
                    favourite || deleteCollab ?

                        sessionStorage.getItem('profileType') !== 'user' && <div className={"action"} onClick={() => favourite ? confirmDeleteFavorite() : confirmDeleteCollaboration()}>
                            <img src={"/images/delete-bin-5-line.svg"} alt={""}/>
                        </div>
                        :
                        request &&
                        <div className={"action"} onClick={() => window.location.href= '/inbox/' +id}>
                            <img src={"/images/send-plane-line-blue.svg"} alt={""}/>
                        </div>
                }

            </div>

        </div>
    )

}