import React from 'react';
import moment from 'moment';
import './style.css';
import {server_path_files} from "../../../const";

export default function Message(props) {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp,
        image
    } = props;

    
    const friendlyTimestamp = moment(data.created_at).format('LLLL');
    return (
      <div className={[
        'messageItem',
        `${isMine ? 'mine' : ''}`,
        `${startsSequence ? 'start' : ''}`,
        `${endsSequence ? 'end' : ''}`
      ].join(' ')}>
        {
          showTimestamp &&
            <div className="timestamp">
              { friendlyTimestamp }
            </div>
        }
         
        <div className="bubble-container">
            {
                startsSequence && !isMine ?
                    <div className="image_message">
                        <img src={image ? (server_path_files + image) : ''} alt={""}/>
                    </div>
                    :
                    <div className="image_message"></div>
            }
          <div className="bubble" title={friendlyTimestamp}>
            { data.body }
          </div>
            {
                startsSequence && isMine ?
                <div className="image_message">
                    <img src={image ? (server_path_files + image) : ''} alt={""}/>
                </div>
                    :
                    <div className="image_message"></div>
            }
        </div>
      </div>
    );
}