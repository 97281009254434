import React, {useEffect, useState} from 'react';
import './style.css';
import {confirmAlert} from 'react-confirm-alert';
import {Row, Col, Button} from "react-bootstrap";
import FileCard from "../../Utils/FileCard/FileCard";
import Select from "react-select";
import {customSelectStyle, server} from "../../../const";
import Checkbox from "../../Utils/Checkbox/Checkbox";
import {toast} from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import moment from 'moment'
import {useIntl} from "react-intl";


export default function LeadOpportunityEdit({
                                                id,
                                                opportunity = '',
                                                opportunityId = '',
                                                type = 0
                                            }
) {
    const intl = useIntl();
    const [optionsCountry, setOptionsCountry] = useState([])
    const [country, setCountry] = useState("");
    const [optionsCompanies, setOptionsCompanies] = useState([])
    const [company, setCompany] = useState("");

    const token = sessionStorage.getItem('token')
    const [tasks, setTasks] = useState([])
    const [opportunities, setOpportunities] = useState('')
    const [subject, setSubject] = useState('')
    const [files, setFiles] = useState('')
    const [newFiles, setNewFiles] = useState([])
    const [editedFiles, setEditedFiles] = useState('')
    const [deadline, setDeadline] = useState([])
    const [budget, setBudget] = useState('')
    const [paid, setPaid] = useState(0)
    const [description, setDescription] = useState('')
    const [expired, setExpired] = useState(false)
    const [completed, setCompleted] = useState(false)
    const [requestSend, setRequestSend] = useState(false)


    const [taskSubject, setTaskSubject] = useState("")
    const [taskUnits, setTaskUnits] = useState("")
    const [taskDeadline, setTaskDeadline] = useState("")

    const typeOps = [
        'private_opportunities_first_lowercase',
        'public_opportunities_first_lowercase',
        'funding_first_lowercase',
        'auction_first_lowercase',
        'opportunities_for_agents_first_lowercase',
        'leads_first_lowercase'
    ]

    useEffect(() => {
        const getData = () => {
            fetch(server + (opportunity === 5 ? "/lead" : "/opportunity/details") + (opportunityId !== '' ? '/' + opportunityId : ''), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(json['error']);
                } else {
                    setDescription(json['data']['about'])
                    setSubject(json['data']['subject'])
                    setBudget(json['data']['budget'])
                    setPaid(json['data']['paid'])
                    setDeadline(json['data']['deadline'].split('-'))
                    setFiles(json['data']['files'])
                    setEditedFiles(json['data']['editedFiles'])
                    setExpired(parseInt(json['data']['expired']) === 1)
                    setCompleted(parseInt(json['data']['completed']) === 1)
                    for (const contr of optionsCountry) {
                        if (contr.value === parseInt(json['data']['region'])) {
                            setCountry({
                                value: contr.value,
                                label: contr.label
                            })
                        }
                    }


                    for (const comp of optionsCompanies) {
                        if (comp.value === parseInt(json['data']['company']['user_id'])) {
                            setCompany({
                                value: comp.value,
                                label: comp.label
                            })
                        }
                        if (comp.value === parseInt(json['data']['company_sel']['user_id'])) {
                            setRequestSend(1)
                            setCompany({
                                value: comp.value,
                                label: comp.label
                            })
                        }
                    }
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getTasks = () => {
            fetch(server + '/tasks?leadId=' + id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setTasks(json['data'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getCountries = () => {

            setOpportunities(opportunity)
            fetch(server + '/country', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    setOptionsCountry([])
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.id,
                                label: opt.country
                            })
                        }
                    )
                    setOptionsCountry(arr2)
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (optionsCountry.length === 0 && opportunities === '') {
            getCountries()
            if (id && opportunity === 5) {
                getTasks()
            }
        }

        const getCompanies = () => {
            fetch(server + '/get_companies', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    setOptionsCompanies([])
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.user_id,
                                label: opt.name
                            })
                        }
                    )
                    setOptionsCompanies(arr2)
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (optionsCompanies.length === 0 && opportunities === '') {
            getCompanies()
        }
        
        if (id && subject === '' && optionsCountry.length !== 0) {
            getData()
        }


    }, [optionsCountry, id, opportunityId, opportunity, token, subject, opportunities,optionsCompanies, intl])

    const addFile = (e) => {
        let newFile = []
        newFile.push(Object.assign(e, {
            preview: URL.createObjectURL(e)
        }))
        setNewFiles(newFiles.concat(newFile));
    }
    const addLO = (e) => {
        console.log('fdfdfdfd')
        e.preventDefault()
        var data = new FormData()
        data.append('subject', subject)
        if (!opportunityId) {
            data.append('region', country.value)
            data.append('deadline', moment(deadline).format('YYYY-MM-DD'))
            data.append('budget', budget)
        }
        data.append('lead_type', type)
        if (company.value){
            data.append('company_id', company.value)
        }
        data.append('paid', paid)
        data.append('about', description)
        data.append('expired', expired ? 1 : 0)
        data.append('completed', completed ? 1 : 0)
        if (opportunities !== 5) {
            data.append('type', opportunities)
        }

        for (const file of newFiles) {
            data.append('file[]', file)
        }

        fetch(server + (opportunities === 5 ? "/lead" : "/opportunity") + (opportunityId !== '' ? '/update/' + opportunityId : ''), {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                if (json['error']) {
                    toast.warning(json['error']);
                } else {
                    toast.warning(intl.formatMessage({id: 'error'}));
                }
            } else {
                if (opportunities === 5) {
                    window.location.href = '/leads'
                } else {
                    window.location.href = '/opportunity'
                }
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const deleteTask = (task_id) => {
        fetch(server + '/tasks/delete/' + task_id, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            window.location.href = '/leads/edit/' + id
            return response.json()
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const confirmDeleteTask = (taskId) => {
        confirmAlert({
            title: intl.formatMessage({id: 'are_you_sure_you_want_to_delete_this_task'}),
            message: intl.formatMessage({id: 'this_task_cannot_be_restored_after_deletion_if_you_do_not_intend_to_delete_click_cancel_If_you_want_to_confirm_the_deletion_click_delete'}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'delete'}),
                    onClick: () => deleteTask(taskId)
                },
                {
                    label: intl.formatMessage({id: 'cancel'}),
                }
            ]
        });
    };

    const addTask = (e) => {
        var data = new FormData()
        data.append('name', taskSubject)
        data.append('total', taskUnits)
        if (deadline.length > 2) {
            data.append('deadline', moment(new Date()).format('YYYY-MM-DD'))
        } else {
            data.append('deadline', moment(taskDeadline).format('YYYY-MM-DD'))
        }
        fetch(server + '/tasks/' + id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.href = id
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        <Row>
            <Col className={"loEdit"}>
                <h1>

                    {
                        opportunityId !== '' ?
                            intl.formatMessage({id: 'edit'}) :
                            intl.formatMessage({id: 'create'})
                    }
                    {
                       ' ' + intl.formatMessage({id: typeOps[opportunities !== '' ? opportunities : 1]})

                    }
                </h1>
                <form className={"form-container p-0 "} onSubmit={(e) => addLO(e)}>
                    <div className={"info"}>
                        <h2>
                            { intl.formatMessage({id: 'information'})}

                        </h2>
                        <Row>
                            {opportunities !== '' && sessionStorage.getItem('profileType') !== 'user' && <Col className={"position-relative"}>

                                <div className={"d-inline"} onClick={() => !opportunityId && opportunities !== 5 && setOpportunities(5)}>
                                    <Checkbox
                                        ck={opportunities === 5 ? 1 : 0}
                                        info={"Leaduri"}
                                        disable={!!opportunityId}
                                    />
                                </div>

                                <div className={"d-inline"} onClick={() => !opportunityId && opportunities !== 0 && setOpportunities(0)}>
                                    <Checkbox
                                        ck={opportunities === 0 ? 1 : 0}
                                        info={"Oportunitati private"}
                                        disable={!!opportunityId}
                                    />
                                </div>
                                {
                                    sessionStorage.getItem('profileType') === 'admin' ?
                                        <div className={"d-inline"}>

                                            <div className={"d-inline"} onClick={() => !opportunityId && opportunities !== 1 && setOpportunities(1)}>
                                                <Checkbox
                                                    ck={opportunities === 1 ? 1 : 0}
                                                    info={"Oportunitati publice"}
                                                    disable={!!opportunityId}
                                                />
                                            </div>
                                            <div className={"d-inline"} onClick={() => !opportunityId && opportunities !== 2 && setOpportunities(2)}>
                                                <Checkbox
                                                    ck={opportunities === 2 ? 1 : 0}
                                                    info={"Finantari"}
                                                    disable={!!opportunityId}
                                                />
                                            </div>
                                            <div className={"d-inline"} onClick={() => !opportunityId && opportunities !== 3 && setOpportunities(3)}>
                                                <Checkbox
                                                    ck={opportunities === 3 ? 1 : 0}
                                                    info={"Licitatii"}
                                                    disable={!!opportunityId}
                                                />
                                            </div>
                                            <div className={"d-inline"} onClick={() => !opportunityId && opportunities !== 4 && setOpportunities(4)}>
                                                <Checkbox
                                                    ck={opportunities === 4 ? 1 : 0}
                                                    info={"Oportunitati pentru agenti"}
                                                    disable={!!opportunityId}
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div></div>
                                }
                            </Col>}
                        </Row>
                        {
                            sessionStorage.getItem('profileType') === 'user' && company !== '' && !!opportunityId && requestSend === 1 &&
                            intl.formatMessage({id: 'the_request_has_been_sent'})
                                
                        }
                        {
                            sessionStorage.getItem('profileType') === 'user' &&
                            <Row className={"mb-2"}>
                                <Col >
                                    <div className={"item select"}>
                                        <span>{intl.formatMessage({id: 'company'})}</span>
                                        <Select
                                            placeholder={ intl.formatMessage({id: 'select'})}
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'}
                                            styles={customSelectStyle}
                                            options={optionsCompanies}
                                            value={company}
                                            onChange={(e) => (company === '' || !opportunityId) && setCompany(e)}
                                         
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'subject'})}</span>
                                    <input required value={subject} onChange={(e) => setSubject(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>
                        <Row className={"mb-2"}>
                            <Col md={4}>
                                <div className={"item select"}>
                                    <span>{intl.formatMessage({id: 'region'})}</span>
                                    <Select
                                        placeholder={ intl.formatMessage({id: 'select'})}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        styles={customSelectStyle}
                                        options={optionsCountry}
                                        value={country}
                                        onChange={(e) => !opportunityId && setCountry(e)}
                                        required
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'deadline'})}</span>
                                    <div>
                                        <DateTimePicker
                                            minDate={new Date()}
                                            value={opportunityId && deadline.length > 0 ? new Date(deadline[0], deadline[1], deadline[2]) : deadline.length === undefined ? deadline : new Date()}
                                            onChange={(date) => !opportunityId && setDeadline(date)}
                                            dateFormat={"yyyy-MM-dd"}
                                            format={"yyyy-MM-dd"}
                                            formatDate={"yyyy-MM-dd"}
                                            required
                                        />
                                    </div>
                                </div>

                            </Col>
                            <Col md={4}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'budget'})}</span>
                                    <input required type={'number'} value={budget} onChange={(e) => !opportunityId && setBudget(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>

                        {
                            sessionStorage.getItem('profileType') === 'admin' &&
                            <h3>
                                {intl.formatMessage({id: 'payment_option'})}

                            </h3>
                        }
                        {
                            sessionStorage.getItem('profileType') === 'admin' &&
                            <Row>
                                <Col md={4}>
                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'amount'})}</span>
                                        <input required value={paid} onChange={(e) => setPaid(e.target.value)}/>
                                    </div>
                                </Col>
                            </Row>
                        }


                        {
                            opportunityId !== '' && expired !== null && completed !== null &&  sessionStorage.getItem('profileType') !== 'user' &&
                            <Row>
                                <Col>
                                    <div className={"d-inline"} onClick={() => setExpired(!expired)}>
                                        <Checkbox
                                            ck={expired ? 1 : 0}
                                            info={intl.formatMessage({id: 'expired'})}
                                            disable={true}
                                        />
                                    </div>
                                    <div className={"d-inline"} onClick={() => setCompleted(!completed)}>
                                        <Checkbox
                                            ck={completed ? 1 : 0}
                                            info={intl.formatMessage({id: 'finished'})}
                                            disable={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        }

                        <h3>
                            {intl.formatMessage({id: 'description_of_the_opportunity'})}

                        </h3>

                        <h5>
                            {intl.formatMessage({id: 'what_is_your_opportunity_about'})}

                        </h5>

                        <textarea required placeholder=
                            {intl.formatMessage({id: 'opportunity_description_in_maximum_200_characters'})}
                                  className={"desciption"} value={description} onChange={(e) => setDescription(e.target.value)}>
                        </textarea>
                    </div>

                    <div className={"files"}>
                        <h2>
                            {intl.formatMessage({id: 'files'})}

                        </h2>
                        <div>
                            <h3 className={"d-inline-block"}>
                                {intl.formatMessage({id: 'upload_the_files'})}

                            </h3>

                            <label htmlFor={"file-upload"} className={"addFile btn-primary"}>
                                <img src={"/images/add-circle-line.svg"} alt={""}/>
                                {intl.formatMessage({id: 'add_file'})}

                            </label>
                            <input id={"file-upload"} className={"d-none"} type="file"
                                   onChange={(e) => addFile(e.target.files[0])}
                                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf"/>

                        </div>

                        <div className={"filesContainer"}>
                            {
                                files !== '' &&
                                files.map((file, i) => (
                                    <FileCard
                                        key={i}
                                        id={file.id}
                                        type={file.type}
                                        title={file.name}
                                        size={file.size}
                                        lastAccessed={moment(file.updated_at).format('YYYY-MM-DD')}
                                        deleteButton={true}
                                        path={file.path}
                                        pathDelete={opportunities === 5 ? '/leads/delete_file/' : '/opportunity/delete_file/'}
                                    />
                                ))
                            }
                            {
                                newFiles !== '' &&
                                newFiles.map((file, i) => (
                                    <FileCard
                                        key={i}
                                        id={i}
                                        type={file.type === 'application/pdf' ? 'pdf' : 'doc'}
                                        title={file.name}
                                        size={file.size}
                                        lastAccessed={''}
                                        deleteButton={false}
                                    />
                                ))
                            }
                        </div>

                        <div>
                            <h3 className={"d-inline-block"}>
                                {editedFiles !== '' &&
                                    intl.formatMessage({id: 'uploaded_files'})}
                            </h3>
                        </div>


                        <div className={"filesContainer"}>
                            {
                                editedFiles !== '' &&
                                editedFiles.map((file, i) => (
                                    <FileCard
                                        key={i}
                                        id={file.id}
                                        type={file.type}
                                        title={file.name}
                                        size={file.size}
                                        lastAccessed={moment(file.updated_at).format('YYYY-MM-DD')}
                                        deleteButton={false}
                                        path={file.path}
                                    />
                                ))
                            }
                        </div>

                        <div className={"saveLead"}>
                            <Button variant={"primary"} type={"submit"}>

                                {
                                    opportunities === 5 ?
                                        intl.formatMessage({id: 'save_lead'})
                                        :
                                    intl.formatMessage({id: 'save_opportunity'})
                                }
                            </Button>
                        </div>
                    </div>
                </form>
                {
                    opportunities === 5 && id ?
                        <div className={"infoTask"}>
                            <h2>
                                {intl.formatMessage({id: 'task_information'})}

                            </h2>
                            <Row className={"mb-2"}>
                                <Col md={4}>
                                    <div className={"item"}>
                                        <span>
                                {intl.formatMessage({id: 'name'})}</span>
                                        <input value={taskSubject} onChange={(e) => setTaskSubject(e.target.value)}/>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <div className={"item"}>
                                        <span>
                                {intl.formatMessage({id: 'units'})}</span>
                                        <input value={taskUnits} onChange={(e) => setTaskUnits(e.target.value)}/>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className={"item"}>
                                        <span>
                                {intl.formatMessage({id: 'deadline'})}</span>
                                        <div>
                                            <DateTimePicker
                                                minDate={new Date()}
                                                value={taskDeadline ? taskDeadline : new Date()}
                                                onChange={(date) => setTaskDeadline(date)}
                                                dateFormat={"yyyy-MM-dd"}
                                                format={"yyyy-MM-dd"}
                                                formatDate={"yyyy-MM-dd"}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className={"createTask mb-5"}>
                                <Button variant={"primary"} onClick={() => id ? addTask() : ''}>
                                    {intl.formatMessage({id: 'create_task'})}

                                </Button>
                            </div>
                            {
                                id && tasks.length > 0 ?
                                    <div>
                                        <h3>
                                            {intl.formatMessage({id: 'task_management'})}

                                        </h3>

                                        {
                                            tasks.map((task, i) => (
                                                <div key={i}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <div className={"item"}>
                                                                <span>
                                {intl.formatMessage({id: 'name'})}</span>
                                                                <input value={task.name}/>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>

                                                            <div className={"item"}>
                                                                <span>
                                {intl.formatMessage({id: 'units'})}</span>
                                                                <input value={task.total}/>
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>

                                                            <div className={"item"}>
                                                                <span>
                                {intl.formatMessage({id: 'accomplished'})}</span>
                                                                <input value={task.done}/>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>

                                                            <div className={"item"}>
                                                                <span>
                                {intl.formatMessage({id: 'deadline'})}</span>
                                                                <input value={task.deadline}/>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className={"mb-4"}>
                                                        <Col>
                                                            <Button variant={"secondary"} onClick={() => confirmDeleteTask(task.id)}>
                                                                <img src={"/images/delete-bin-line.svg"} alt={""}/>
                                                                {intl.formatMessage({id: 'delete'})}
                                                            </Button>

                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <div></div>

                            }
                        </div>
                        :
                        <div></div>
                }
            </Col>
        </Row>
    )
}