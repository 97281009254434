import React, {useEffect, useState} from 'react';
import './style.css';
import {Col, Row} from "react-bootstrap";
import {server} from "../../const";
import {toast} from "react-toastify";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useIntl} from "react-intl";

export default function Reports() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const [data, setData] = useState('')
    const [type, setType] = useState('domain')
    useEffect(() => {
        const getData = () => {
            var data = new FormData()
            data.append('type', type)
            fetch(server + '/reports', {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setData(json['data'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (data === '') {
            getData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token,type])


    return (
        sessionStorage.getItem('profileType') === 'admin' &&
        <div className={"report"}>
            <Row>
                <Col>
                        <div className={"item checkbox " + (type === 'domain' ? "checked" : "")} onClick={() => {
                            setType('domain')
                            setData('')
                        }}>
                            {
                                type === 'domain' ?
                                    <img src={"/images/check-line2.svg"} alt={""}/>
                                    :
                                    ''
                            }
                            {intl.formatMessage({id: 'fields_of_activity'})}
                        </div>
                        <div className={"item checkbox " + (type === 'region' ? "checked" : "")} onClick={() => {
                            setType('region')
                            setData('')
                        }}>
                            {
                                type === 'region' ?
                                    <img src={"/images/check-line2.svg"} alt={""}/>
                                    :
                                    ''
                            }
                            {intl.formatMessage({id: 'regions'})}
                        </div>
                </Col>
            </Row>
            
            {
                data.data && data.data.length !== 0 &&
                <table className={"tasks invoices w-100"}>
                    <thead>
                    <tr>
                        <th>
                            {intl.formatMessage({id: 'no'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'domain'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'number_of_users'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'profile_completion'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'commission'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'leads'})}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.data.map((item, i) => (
                            <tr key={i}>
                                <td>
                                    {i}
                                </td>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.users}
                                </td>
                                <td>
                                    {item.completed}
                                </td>
                                <td className={"etape"}>
                                    <div className={"value"}>
                                        {parseFloat(item.commission).toFixed(1) + ' %'}
                                    </div>
                                        <ProgressBar variant="success" now={parseInt(item.commission)} max={100}/>
                                </td>
                                <td>
                                    {item.leads}
                                </td>
                            </tr>
                        ))
                    }

                    </tbody>
                </table>

            }

         
        </div>
    )
}