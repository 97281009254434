import React, {useState} from 'react';
import './style.css';
import {toast} from 'react-toastify';
import {server} from "../../const";
import {useIntl} from "react-intl";

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';



export default function Login() {

    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [incorectCredential, setIncorectCredential] = useState(false);



    const login = (e, em, ps) => {

        if (e !== ''){
            e.preventDefault()
        }
        fetch(server + '/auth/login', {
            method: 'POST',
            body: JSON.stringify({
                'email': em,
                'password': ps
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {
            if (response.ok) {
                sessionStorage.setItem('token', response.headers.get('Authorization'))
                window.location.reload()
            } else {
                setIncorectCredential(true);
            }
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });

    }

    return (
        <div className="main">
            <div className={"background"}>
                <img className={"img1"} src={"/images/login/freepik  background complete  inject 31.svg"} alt={""}/>
            </div>
            <div className={"login"}>
                <img className={"img4"} src={"/images/login/freepik  Character  inject 31.svg"} alt={""}/>
                <img className={"img2"} src={"/images/login/freepik  Plant  inject 31.svg"} alt={""}/>
                <img className={"img3"} src={"/images/login/freepik  Floor  inject 31.svg"} alt={""}/>
                <div className={"fcontainer"}>

                    <h1 className={"login_title"}> {intl.formatMessage({id: 'welcome'})}</h1>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'sign_in_by_entering_the_information_below'})}</p>


                            <div className="social-buttons-container">
                                <LoginSocialFacebook
                                        appId="840606294475263" 
                                        onResolve={(response) => {
                                            const { email, id } = response.data;
                                            login('', email, id)
                                        }}
                                        onReject={(error) => {
                                            console.log(error);
                                        }}
                                        >
                                   <FacebookLoginButton/>
                                </LoginSocialFacebook>

                                <div className="google-login-button-container">
                                    <GoogleOAuthProvider clientId="1016943213858-b4iio29ng6jh6k68938pa69mor1ce89v.apps.googleusercontent.com">
                                        <GoogleLogin
                                            className="google-login-button"
                                                onSuccess = { credentialResponse => {
                                                    var googleDecoded = jwt_decode(credentialResponse.credential)
                                                    login('', googleDecoded.email, googleDecoded.sub)
                                                }}
                                                onError={() => {
                                                    console.log('Login Failed');
                                                }}
                                        />
                                    </GoogleOAuthProvider>
                                </div>  
                            </div>


                    

                    <form className={"form-container"} onSubmit={(e) => login(e, email, password)}>

                        <div className={"form-group"}>
                            <div className={"form-group col-lg-12 mx-auto d-flex align-items-center my-4"}>
                                <div className={"border-bottom w-100 ml-5"}>

                                </div>
                                <span className={"or px-2 small text-muted font-weight-bold text-muted"}>or</span>
                                <div className={"border-bottom w-100 mr-5"}>

                                </div>
                            </div>
                            <div>
                                <div className={"inputs"}>
                                    <div className={"invalid " + (incorectCredential ? "d-block" : "d-none")}>
                                        {intl.formatMessage({id: 'invalid_email'})}
                                    </div>
                                    <div className={"item " + (incorectCredential ? "incorect" : "")}>
                                        <span>{intl.formatMessage({id: 'email'})}</span>
                                        <input type={"email"} value={email} onChange={(e) => setEmail(e.target.value)} className={"form-control"} id={"InputEmail1"}
                                               placeholder={intl.formatMessage({id: 'enter_email'})}/>
                                    </div>
                                    <div className={"item " + (incorectCredential ? "incorect" : "")}>
                                        <span>{intl.formatMessage({id: 'password'})}</span>
                                        <input type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} className={"form-control"} id={"InputPassword1"}
                                               placeholder={intl.formatMessage({id: 'password'})}/>
                                    </div>

                                    <div className={"row px-3 mb-4"}>
                                        <div className={"rememberMe col-6 custom-control custom-checkbox custom-control-inline p-0"}>
                                            <input id="chk1" type="checkbox" name="chk" className="custom-control-input">
                                            </input>
                                            <label htmlFor={"chk1"} className={"custom-control-label text-sm"}>
                                                {intl.formatMessage({id: 'remember_me'})}</label>
                                        </div>
                              {/*          <a href={"/reset-password"} className={"forgotPassword col-6 ml-auto mb-0 text-sm"}>
                                            Forgot Password?</a>*/}
                                    </div>
                                    <button className={"btn btn-primary btn-block w-100"}>{intl.formatMessage({id: 'sign_in'})}</button>
                                    <div className={"form-footer"}>
                                        <p>{intl.formatMessage({id: 'do_not_have_an_account'})} ? <a href={"/create-account"}>{intl.formatMessage({id: 'sign_up'})}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}