import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css'
import ReactPaginate from 'react-paginate';
import ContractsCard from "../Utils/ContractsCard/ContractsCard";
import {server} from "../../const";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function Contracts() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const [contractSign,setContractSign] = useState(true);
    const [contractUnsign,setContractUnsign] = useState(false);
    const [contracts,setContracts] = useState('');

    const [page,setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);


    useEffect(() => {
        const getContracts = () => {
            var data = new FormData()
            data.append('page', page)

            fetch(server + '/contracts/' + (contractSign ? 'signed' : 'unsigned'), {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
                
            }).then(response => {
                return response.json()
            }).then(json => {
                if(json['data']) {
                    setContracts(json['data'])
                    setMaxPages(json['pages'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
       // if (maxPages > 0) {
            getContracts()
        //}

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token,page,maxPages,contractUnsign]);
  
    return (
        contracts !== '' && 
        <div className={"collaboration"}>
            <Row>
                <Col className={"title"}>
                    {
                        contractSign ?
                            intl.formatMessage({id: 'contracts_signed'})
                            :
                            contractUnsign ?
                    intl.formatMessage({id: 'contracts_unsigned'})
                                :
                                ""
                    }
                </Col>
            </Row>
            <Row>
                <Col className={"filters"}>
                    <div className={"item checkbox " + (contractSign ? "checked" : "")} onClick={() => {
                        setContractSign(!contractSign)
                        setContractUnsign(false)
                        setPage(1)
                    }}>
                        {
                            contractSign ?
                                <img src={"/images/check-line2.svg"} alt={""}/>
                                :
                                ''
                        }
                        {intl.formatMessage({id: 'contracts_signed'})}
                    </div>
                    <div className={"item checkbox " + (contractUnsign ? "checked" : "")}  onClick={() => {
                        setContractSign(false)
                        setContractUnsign(!contractUnsign)
                        setPage(1)
                    }}>
                        {
                            contractUnsign ?
                                <img src={"/images/check-line2.svg"} alt={""}/>
                                :
                                ''
                        }
                        {intl.formatMessage({id: 'contracts_unsigned'})}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>


                    {
                        contracts.map((item, i) => (
                            <ContractsCard 
                                key={i}
                                id={item.contract.id}
                                image={item.user.logo}
                                name={item.user.name ? item.user.name : item.user.first_name + ' ' + item.user.last_name}
                                uploadSign={!!item.contract.signed_path}
                                contractSign={item.contract.signed_path}/*path*/
                                uploadUnsign={!!item.contract.unsigned_path}
                                contractUnsign={item.contract.unsigned_path} /*path*/
                                access={item.access}
                            />
                        ))
                    }
                </Col>
            </Row>
            {
                maxPages > 0 &&
                <Row>
                    <Col className={"text-center"}>
                        <ReactPaginate
                            pageCount={maxPages}
                            pageRangeDisplayed={4}
                            marginPagesDisplayed={2}
                            onPageChange={(val) => setPage(val.selected)}
                            forcePage={page}
                            initialPage={page}
                            containerClassName={"react-paginate"}
                            previousLabel={<img src={"/images/arrow-left-s-line.svg"} alt={""}/>}
                            nextLabel={<img src={"/images/arrow-right-s-line.svg"} alt={""}/>}
                        />
                    </Col>
                </Row>
            }
        </div>
    )
}