export const ro = {

    'package_activated': 'Pachetul a fost activat!',
    'error': 'A aparut o eroare!',
    'companies': 'Companii',
    'agents': 'Agenți',
    'agent': 'Agent',
    'No': 'Nr',
    'name': 'Denumire',
    'full_name': 'Nume',
    'enter_full_name': 'Introduceți numele',
    'email': 'Email',
    'enter_email': 'Introduceți adresa de email',
    'phone': 'Telefon',
    'location': 'Locație',
    'profile_completion': 'Completare profil',
    'actions': 'Actiuni',
    'block': 'Blochează',
    'my_leads': 'Leadurile mele',
    'leads': 'Leaduri',
    'agents_leads': 'Leaduri agenți',
    'lead': 'Lead',
    'create_lead': 'Crează lead',
    'region': 'Regiune',
    'regions': 'Regiuni',
    'budget': 'Buget',
    'deadline': 'Deadline',
    'edit': 'Editează',
    'domain': 'Domeniu',
    'profile': 'Profil',
    'pay': 'Plătește',
    'details': 'Detalii',
    'message': 'Mesaj',
    'collaboration': 'COLABORARE',
    'renewable_resources_manager': 'MANAGER RESURSE REGENERABILE',
    'back': 'Înapoi',
    'description': 'Descriere',
    'accept': 'Accept',
    'decline': 'Refuz',
    'download_files_for_viewing': 'Descarcă fisiere pentru vizualizare',
    'you_do_not_have_access_to_the_files_wait_until_you_receive_access': 'Nu aveti acces la fișiere, așteptați până primiți accesul!',
    'download': 'Descarcă',
    'delete': 'Sterge',
    'last_accessed': 'Ultima accesare',
    'add_the_files': 'Adaugă fișierele',
    'add_files': 'Adaugă fișiere',
    'add_file': 'Adaugă fișier',
    'add_payment': 'Adaugă plată',
    'add': 'Adaugă',
    'upload_the_files': 'Incarcă fișierele',
    'are_you_sure_you_want_to_delete_this_task': 'Sigur doriți să ștergeți acest task?',
    'this_task_cannot_be_restored_after_deletion_if_you_do_not_intend_to_delete_click_cancel_If_you_want_to_confirm_the_deletion_click_delete.': 'Acest task nu se va putea restabili dupa ștergere.Dacă nu intenționați să ștergeți faceți clic pe Anulare. Dacă doriți să confirmați ștergerea faceți clic pe Șterge.',
    'cancel':'Anulare',
    'opportunities_first_lowercase':'oportunități',
    'private_opportunities_first_lowercase':'oportunități private',
    'public_opportunities_first_lowercase':'oportunități publice',
    'funding_first_lowercase':'finanțări',
    'auction_first_lowercase':'licitații',
    'opportunities_for_agents_first_lowercase':'oportunități pentru agenti',
    'leads_first_lowercase':'leaduri',
    'commision_first_lowercase':'comision',
    'information':'Informații',
    'the_request_has_been_sent':'Cererea a fost trimisă',
    'company': 'Companie',
    'subject':'Subiect',
    'payment_option':'Optiune de plata',
    'amount':'Suma',
    'description_of_the_opportunity':'Descrierea oportunității',
    'what_is_your_opportunity_about':'Despre ce este oportunitatea ta ?',
    'opportunity_description_in_maximum_200_characters':'Descriere oportunitate în maxim 200 caractere',
    'files':'Fișiere',
    'uploaded_files':'Fișiere încarcate',
    'save_lead':'Salvează lead',
    'save_opportunity':'Salvează oportunitate',
    'accomplished':'Realizat',
    'units':'Unitați',
    'task_management':' Gestiune task',
    'create_task':'Crează task',
    'create':'Crează',
    'task_information':'Informații task',
    'favorite_collaborators':'Colaboratori favoriti',
    'collaborations':'colaborări',
    'collaborations_companies':'Colaborări companii',
    'collaborations_agents':'Colaborări agenti',
    'pending_applications':'Cereri în așteptare',
    'collaborators_agents':'Colaboratori agenți',
    'collaborators_companies':'Colaboratori companii',
    'visitors_profile':'Vizitatori profil',
    'active_visitors':'Vizitatori activi',
    'all_visitors':'Toți vizitatorii',
    'select':'Selectează',
    'all_companies':'Toate companiile',
    'visitors':'Vizitatori',
    'are_you_sure_you_want_to_delete_this_contributor':'Sigur doriți să ștergeți acest colaborator?',
    'if_you_do_not_intend_to_delete_click_cancel_if_you_want_to_confirm_the_deletion_click_delete.':'Dacă nu intenționați să ștergeți faceți clic pe Anulare. Dacă doriți să confirmați ștergerea  faceți clic pe Șterge.',
    'company_opportunities':'Oportunități companie',
    'opportunities_uppercase':'Oportunități',
    'download_company_materials':'Descarcă materiale companie',
    'leads_made':'Leaduri realizate',
    'fields_of_activity':'Domenii de activitate',
    'download_the_company_presentation':'Descarcării prezentarii companiei',
    'enter_the_file_name':'Introduceți numele fișierului',
    'about_us':'Despre noi',
    'download_presentation':'Descarcă Prezentare',
    'watch_the_video':'Vizioneaza Video',
    'contact_person':'Persoana de contact',
    'about_the_company':'Despre companie',
    'recent_activity':'Activitate recenta',
    'settings':'Setări',
    'files_management':'Gestiune fișiere',
    'save':'Salvează',
    'secret_key':'Secret key',
    'publishable_key':'Publishable key',
    'stripe_data':'Date Stripe',
    'keywords':'Cuvinte cheie',
    'complete_the_blanks':'Completează spațiile',
    'company_information':'Informație companie',
    'drag_and_drop_image_here_or':'Trageți și fixați imaginea aici sau ',
    'drag_and_drop_logo_here_or':'Trageți și fixați logo aici sau',
    'searching_for':'cautați',
    'to_choose_a_logo':'pentru a alege un logo',
    'to_choose_a_image':'pentru a alege o imagine',
    'company_description':'Descrierea companiei',
    'company_overview_summary':'Prezentare generală a companiei (rezumat)',
    'about_the_company_max_500_characters':'Despre companie max 500 caractere',
    'description_of_customers_of_interest':'Descrierea clienti de interes',
    'general_presentation':'Prezentare generală',
    'about_clients_of_interest_max_400_characters':'Despre clienti de interes max 400 caractere',
    'upload_the_company_presentation':'Încarca prezentarea companiei',
    'upload_a_file_about_the_company':'Încarca un fișier despre companie.',
    'drag_and_drop_file_here_or':'Trageți și fixați fișierul aici sau',
    'to_choose_a_file':'pentru a alege un fișier',
    'your_youtube_link':'Link-ul tău de pe YouTube',
    'your_url':'URL-ul dvs',
    'useful_links':'Linkuri utile',
    'facebook':'Facebook',
    'linkedin':'Linkedin',
    'field_of_activity':'Domeniu de activitate',
    'countries':'Țări',
    'currency':'Monedă',
    'interest_filters':'Filtre de interes',
    'load_the_platform_logo':'Încarca logo platforma',
    'title':'Titlu',
    'metadata':'Metadate',
    'contracts_signed':'Contracte semnate',
    'contracts_unsigned':'Contracte nesemnate',
    'last_opportunities':'Ultimele oportunități',
    'expire':'Expiră',
    'private_opportunities':'Oportunități private',
    'opportunities':'Oportunități',
    'public_opportunities':'Oportunități publice',
    'financing':'Finanțări',
    'auction':'Licitații',
    'opportunities_for_agents':'Oportunități pentru agenți',
    'favorite_companies':'Companii favorite',
    'total_auctions_in_progress':'Total licitații în lucru',
    'activity':'Activitate',
    'tasks_in_progress':'Taskuri în lucru',
    'pending_contracts':'Contracte în asteptare',
    'total_leads_in_the_works':'Total leaduri în lucru',
    'total_opportunities_in_the_works':'Total oportunități în lucru',
    'total_financing_in_progress':'Total finanțări în lucru',
    'objective_achieved':'Obiectiv realizat',
    'total_sales':'Total vânzări',
    'total_objective':'Total Obiectiv',
    'estimated_receipts':'Încasări estimate',
    'sales':'Vanzări',
    'sales_evolution':'Evoluție vânzări',
    'sales_objective':'Obiectiv vânzări',
    'objective':'Obiective',
    'total':'Total',
    'date':'Data',
    'agent_name':'Nume agent',
    'commission_due':'Comision datorat',
    'goal_achievement_percentage':'Procent realizare obiectiv',
    'my_opportunities':'Oportunitățile mele',
    'create_opportunity':'Crează oportunitate',
    'company_details':'Detalii companie',
    'fee_charged':'Comision perceput',
    'company_name':'Denumire companie',
    'total_leads':'Total leaduri',
    'percentage_achieved':'Procent realizat',
    'estimated_commission':'Comision previzionat',
    'my_collaborations':'Colaborările mele',
    'contracts_concluded':'Contracte încheiate',
    'contracts':'Contracte',
    'congratulations':'Felicitări',
    'at_the_moment_you_have_no_achieved_objective':'Momentan nu aveți obiectiv realizat',
    'views_first_lowercase':'vizualizare',
    'contract_first_lowercase':'contract',
    'contracts_first_lowercase':'contracte',
    'statistically':'Statistici',
    'number_of_companies':'Număr companii',
    'number_of_agents':'Număr agenți',
    'number_of_leads':'Număr leaduri',
    'notes':'Notițe',
    'you_have':'Ai',
    'active_leads_first_lowercase':'leaduri active',
    'send':'Trimite',
    'view_profile':'Vizualizare profil',
    'messages':'Mesaje',
    'last_access_first_lowercase':'ultima accesare',
    'add_invoice':'Adaugă factura',
    'total_commission':'Comision total',
    'goal_achievement':'Realizare obiectiv',
    'commissions_paid':'Comisioane achitate',
    'commission_applied':'Comision practicat',
    'sales_agent':'Agent de vanzare',
    'adjacent_documents':'Documente adiacente',
    'download_files_for_editing_then_use_the_upload_button_to_add_to_the_list.':'Descarcă fisiere pentru editare,dupa care folosește butonul încarca pentru a adaugă în lista.',
    'list_of_tasks':'Listă Taskuri',
    'achievement_level':'Nivel realizare',
    'lead_realization':'Realizare Lead',
    'description_lead':'Descriere Lead',
    'tasks_situation':'Situatie taskuri',
    'tasks':'Sarcini',
    'commission':'Comision',
    'commissions':'Comisioane',
    'paid':'Plătite',
    'liability':'Datorii',
    'objectiv':'Obiectiv',
    'invoice':'Factură',
    'invoice_to':'Factură către',
    'view':'Vizualizare',
    'invoices':'Facturi',
    'unpaid_invoices':'Facturi neachitate',
    'request_access_to_view':'Cere acces pentru vizualizare',
    'create_account':'Creează cont',
    'with_social_media_account_or_your_email':'Cu contul de social media sau emailul dvs',
    'or':'sau',
    'account_type':'Tipul contului',
    'password':'Parola',
    'agree':'De acord',
    'terms_of_service_privacy_policy':'Termeni și condiții, Politica de confidențialitate.',
    'sign_up':'Inscrie-te',
    'have_an_account':'Aveți deja un cont',
    'then_sign_in':'Conectați-vă',
    'sign_up_with_google':'Conectați-vă cu Google',
    'sign_in':'Conectați-vă',
    'do_not_have_an_account':'Nu ai cont',
    'remember_me':'Amintește-ți de mine',
    'invalid_email':'Email invalid',
    'welcome':'Bine ați venit',
    'sign_in_by_entering_the_information_below':'Conectați-vă introducând informațiile de mai jos',
    'confirm_password':'Confirmă parola',
    'create_password':'Creaza parola',
    'your_new_password_must_be_different':'Noua ta parolă trebuie să fie diferită',
    'number_of_users':'Număr de utilizatori',
    'average_commission':'Comision mediu',
    'target':'Target',
    'about_collaborations':'Despre colaborări',
    'date_start':'Data Start',
    'date_end':'Data finalizare',
    'personal_information':'Informatii personale',
    'first_name':'Prenume',
    'last_name':'Nume',
    'biography':'Biografie',
    'upload_image':'Încarca imagine',
    'about_agent':'Despre agent',
    'upload_a_picture_of_yourself_profile_picture':'Încarca imagine cu tine: fotografie profil',
    'the_experience':'Experiența',
    'my_biography':'Biografia mea',
    'companies_i_have_worked_with':'Companii cu care am lucrat',
    'about_me':'Despre mine',
    'download_cv':'Descarcă CV',
    'upload_cv':'Incărcă CV',
    'packets':'Pachete',
    'access_requests':'Cereri de acces',
    'notifications':'Notificări',
    'you_have_a_new_contract_signed':'Ai un contract nou semnat',
    'you_have_a_new_request':'Ai o cerere noua',
    'you_have_a_contract_to_sign':'Ai de semnat un contract',
    'you_have_been_added_to_favorites':'Ai fost agăugat la favoriți',
    'you_have_a_new_view':'Ai o vizualizare noua',
    'logout':'Deconectare',
    'download_xml':'Descarcă XML',
    'my_profile':'Profilul meu',
    'financial_activity':'Activitate financiară',
    'of_course_you_want_to_extract_lead_data':'Sigur doriți să extrageți datele despre leaduri?',
    'the_file_contains_for_each_lead_id_user_id_name_region_deadline_budget_description_and_for_each_month_of_the_year_the_financial_situation_agent_debt_and_amount_paid':'Fișierul conține pentru fiecare lead: id, user id, denumire, regiune, termen, buget, descriere, iar pentru fiecare luna din an situația financiara (agent, datorie și valoare platită)',
    'reports':'Rapoarte',
    'administration':'Administrare',
    'do_you_want_the_subscription_to_be_extended_automatically':'Doriți ca abonamentul să se prelungească automat?',
    'yes':'Da',
    'nu':'Nu',
    'active':'Activ',
    'start':'Start',
    'vailable':'Discponibil',
    'PREMIUM_PACKAGE':'PACHET PREMIUM',
    'PLUS_PACKAGE':'PACHET PLUS',
    'BASIC_PACKAGE':'PACHET DE BAZĂ',
    'we_are_really_happy_to_see_you_here':'Ne bucurăm cu adevărat să te vedem aici',
    'your_plan_your_choice':'Planul tău, alegerea ta',
    'FREE_FOR_LIFETIME_PACKAGE':'PACHET GRATUIT PE VIAȚĂ',
    'create_user_profile':'Creare profil de utilizator',
    'full_dashboard':'Dashboard integral',
    'visible_3_companies_that_visited_the_profile':'Vizibil 3 companii care au vizitat profilul',
    'visible_companies_that_visited_the_profile':'Vizibil companii care au vizitat profilul',
    'visible_10_companies_and_10_agents_who_visited_the_profile':'Vizibil 10 companii și 10 agenti care au vizitat profilul',
    'visible_25_companies_and_25_agents_who_visited_the_profile':'Vizibil 25 companii și 25 agenti care au vizitat profilul',
    'visible_10_companies':'Vizibil 10 companii',
    'visible_50_companies':'Vizibil 50 companii',
    'visible_100_companies':'Vizibil 100 companii',
    'visible_250_companies':'Vizibil 250 companii',
    'visible_companies':'Vizibil companii',
    'upload_cv_packages':'Incarcare CV',
    'access_financial_activity':'Access activitate financiară',
    'access_leads':'Access lead-uri',
    'access_50_leads':'Access la 50 lead-uri',
    'access_3_leads':'Access la 3 lead-uri',
    'access_to_5_opportunities':'Access la 5 oportunități',
    'access_to_25_opportunities':'Access la 25 oportunități',
    'access_to_50_opportunities':'Access la 50 oportunități',
    'access_opportunities':'Access oportunități',
    'add_a_maximum_of_3_opportunities':'Adaugare maxim 3 oportunități',
    'add_a_maximum_of_10_opportunities':'Adaugare maxim 10 oportunități',
    'access_collaborations':'Access colaborări',
    'access_contracts':'Access contracte',
    'inbox_only_125_messages':'Inbox doar 125 mesaje',
    'inbox_only_250_messages':'Inbox doar 250 mesaje',
    'inbox_only_500_messages':'Inbox doar 500 mesaje',
    'inbox_only_5000_messages':'Inbox doar 5000 mesaje',
    'to_add_collaborations':'A adaugă colaborări',
    'access_to_5_private_opportunities':'Access la 5 oportunități private',
    'access_to_25_private_opportunities':'Access la 25 oportunități private',
    'access_to_private_opportunities':'Access la oportunități private',
    'access_to_3_public_opportunities':'Access la 3 oportunități publice',
    'access_to_25_public_opportunities':'Access la 25 oportunități publice',
    'access_to_public_opportunities':'Access la oportunități publice',
    'unlimited_file_upload':'Încarcare fisier nelimitat',
    'upload_1_file':'Încarcare 1 fișier',
    'access_financing':'Access finanțari',
    'access_auctions':'Access licitații',
    'upload_video':'Încarcare video',
    'search':'Căutare',
    'start_a_conversation':'Începe o conversație',
    'expired':'Expirat',
    'finished':'Finalizat',
    'terms_of_service_title_1':'Termeni si conditii',
    'terms_of_service_content_1':'Acești termeni și condiții („Termeni”, „Acord”) reprezintă un acord între SC ARIA ORIGINAL DESIGN SRL („SC ARIA ORIGINAL DESIGN SRL”, „ARIA”, „noi”, „noi” sau „nostru”) și dvs. ( „Utilizator”, „tu” sau „al tău”). Acest Acord stabilește termenii și condițiile generale ale utilizării de către dumneavoastră a site-ului web https://clossers.com și a oricăror produse sau servicii ale acestuia (colectiv, „Site web” sau „Servicii”).',
    'terms_of_service_title_2':'Conturi și calitatea de membru',
    'terms_of_service_content_2':'Dacă creați un cont pe site, sunteți responsabil pentru menținerea securității contului dvs. și sunteți pe deplin responsabil pentru toate activitățile care au loc în cadrul contului și pentru orice alte acțiuni întreprinse în legătură cu acesta. Furnizarea de informații de contact false de orice fel poate duce la desființarea contului dvs. Trebuie să ne anunțați imediat cu privire la orice utilizare neautorizată a contului dvs. sau orice alte încălcări ale securității. Nu vom fi răspunzători pentru niciun act sau omisiune din partea dumneavoastră, inclusiv orice daune de orice fel suferite ca urmare a unor astfel de acte sau omisiuni. Putem suspenda, dezactiva sau șterge contul dvs. (sau orice parte a acestuia) dacă determinăm că ați încălcat orice prevedere a acestui Acord sau că conduita sau conținutul dvs. ar tinde să ne afecteze reputația și bunăvoința. Dacă vă ștergem contul din motivele de mai sus, nu vă puteți reînregistra pentru Serviciile noastre. Este posibil să vă blocăm adresa de e-mail și adresa protocolului de Internet pentru a preveni înregistrarea ulterioară.',
    'terms_of_service_title_3':'Conținutul utilizatorului',
    'terms_of_service_content_3':'Nu deținem niciun fel de date, informații sau materiale („Conținut”) pe care le trimiteți site-ului web în timpul utilizării Serviciului. Veți avea responsabilitatea exclusivă pentru acuratețea, calitatea, integritatea, legalitatea, fiabilitatea, caracterul adecvat și proprietatea asupra proprietății intelectuale sau dreptul de utilizare a întregului Conținut trimis. Putem, dar nu avem nicio obligație, să monitorizăm Conținutul de pe site-ul web trimis sau creat de dvs. folosind Serviciile noastre. Cu excepția cazului în care este permis în mod specific de către dvs., utilizarea de către dvs. a site-ului web nu ne acordă licența de a folosi, reproduce, adapta, modifica, publica sau distribui conținutul creat de dvs. sau stocat în contul dvs. de utilizator în scopuri comerciale, de marketing sau în orice scop similar. Dar ne acordați permisiunea de a accesa, copia, distribui, stoca, transmite, reformata, afișa și executa Conținutul contului dvs. de utilizator numai așa cum este necesar în scopul de a vă furniza Serviciile. Fără a limita niciuna dintre aceste declarații sau garanții, avem dreptul, deși nu și obligația, să refuzăm sau să eliminam, la propria noastră discreție, orice conținut care, în opinia noastră rezonabilă, încalcă oricare dintre politicile noastre sau este dăunător în vreun fel. sau inacceptabil.',
    'terms_of_service_title_4':'Facturare și plăți',
    'terms_of_service_content_4':'Veți plăti toate taxele sau taxele în contul dvs. în conformitate cu taxele, taxele și termenii de facturare în vigoare la momentul în care o taxă sau taxă este datorată și plătibilă. În cazul în care Serviciile sunt oferite pe bază de probă gratuită, plata poate fi necesară după încheierea perioadei de probă gratuită și nu atunci când introduceți detaliile de facturare (care pot fi necesare înainte de începerea perioadei de probă gratuită). Dacă reînnoirea automată este activată pentru Serviciile la care v-ați abonat, veți fi taxat automat în conformitate cu termenul pe care l-ați selectat. Dacă, în opinia noastră, achiziția dvs. constituie o tranzacție cu risc ridicat, vă vom cere să ne furnizați o copie a actului de identitate cu fotografie valabil emis de guvern și, eventual, o copie a extrasului bancar recent pentru cardul de credit sau de debit utilizat pentru cumparatura. Ne rezervăm dreptul de a modifica produsele și prețurile produselor în orice moment. De asemenea, ne rezervăm dreptul de a refuza orice comandă pe care o plasați la noi. Putem, la discreția noastră exclusivă, să limităm sau să anulăm cantitățile achiziționate per persoană, pe gospodărie sau pe comandă. Aceste restricții pot include comenzi plasate de către sau sub același cont de client, același card de credit și/sau comenzi care utilizează aceeași adresă de facturare și/sau de expediere. În cazul în care efectuăm o modificare sau anulăm o comandă, putem încerca să vă anunțăm contactând adresa de e-mail și/sau adresa de facturare/numărul de telefon furnizate în momentul în care a fost efectuată comanda.',
    'terms_of_service_title_5':'Politica de rambursare',
    'terms_of_service_content_5':'Oferim 30 de zile garanție de returnare a banilor și asistență de top pentru toate planurile noastre. Puteți anula oricând, din orice motiv .',
    'terms_of_service_title_6':'Acuratețea informațiilor',
    'terms_of_service_content_6':'Ocazional, pe site-ul nostru web pot exista informații care să conțină erori de tipar, inexactități sau omisiuni care pot avea legătură cu descrierile produselor, disponibilitatea, promoțiile și ofertele. Ne rezervăm dreptul de a corecta orice erori, inexactități sau omisiuni și de a modifica sau actualiza informații sau de a anula comenzi dacă orice informație de pe site sau de pe orice site aferent este incorectă în orice moment, fără notificare prealabilă (inclusiv după ce ați trimis comanda) . Nu ne asumăm nicio obligație de a actualiza, modifica sau clarifica informațiile de pe site, inclusiv, fără limitare, informațiile privind prețurile, cu excepția cazurilor impuse de lege. Nicio actualizare specificată sau o dată de reîmprospătare aplicată pe site-ul web nu trebuie luată în considerare pentru a indica faptul că toate informațiile de pe site-ul web sau de pe orice site aferent au fost modificate sau actualizate.',
    'terms_of_service_title_7':'Servicii ale terților',
    'terms_of_service_content_7':'Dacă decideți să activați, să accesați sau să utilizați servicii de la terți, fiți informat că accesul și utilizarea de către dvs. a acestor alte servicii este guvernată exclusiv de termenii și condițiile acestor alte servicii și nu aprobăm, nu suntem responsabili sau răspunzători pentru, și nu face nicio declarație cu privire la niciun aspect al acestor alte servicii, inclusiv, fără limitare, conținutul acestora sau modul în care acestea manipulează datele (inclusiv datele dumneavoastră) sau orice interacțiune între dumneavoastră și furnizorul de astfel de servicii. Renunțați irevocabil la orice pretenție împotriva SC ARIA ORIGINAL DESIGN SRL cu privire la astfel de alte servicii. SC ARIA ORIGINAL DESIGN SRL nu este responsabilă pentru nicio daune sau pierderi cauzate sau presupuse a fi cauzate de sau în legătură cu activarea, accesul sau utilizarea de către dumneavoastră a oricăror astfel de servicii, sau încrederea dumneavoastră în practicile de confidențialitate, procesele de securitate a datelor sau alte politici. de asemenea alte servicii. Este posibil să vi se solicite să vă înregistrați sau să vă conectați la astfel de alte servicii pe site-urile lor respective. Prin activarea oricăror alte servicii, permiteți în mod expres SC ARIA ORIGINAL DESIGN SRL să vă dezvăluie datele dacă este necesar pentru a facilita utilizarea sau activarea unui astfel de alt serviciu.',
    'terms_of_service_title_8':'Backup-uri',
    'terms_of_service_content_8':'Efectuăm copii de rezervă regulate ale site-ului și ale conținutului și vom face tot posibilul pentru a asigura caracterul complet și acuratețea acestor copii de siguranță. În cazul defecțiunii hardware sau pierderii datelor, vom restabili backup-urile automat pentru a minimiza impactul și timpul de nefuncționare.',
    'terms_of_service_title_9':'Link-uri către alte site-uri web',
    'terms_of_service_content_9':'Deși acest site web poate fi legat de alte site-uri web, nu implicăm, direct sau indirect, vreo aprobare, asociere, sponsorizare, susținere sau afiliere cu orice site web legat, cu excepția cazului în care este specificat în mod expres aici. Nu suntem responsabili pentru examinarea sau evaluarea și nu garantăm ofertele oricărei companii sau persoane fizice sau conținutul site-urilor lor web. Nu ne asumăm nicio responsabilitate sau răspundere pentru acțiunile, produsele, serviciile și conținutul altor terți. Ar trebui să revizuiți cu atenție declarațiile legale și alte condiții de utilizare ale oricărui site web pe care îl accesați printr-un link de pe acest site web. Legăturile dvs. către orice alte pagini din afara site-ului sau alte site-uri web sunt pe propriul risc.',
    'terms_of_service_title_10':'Utilizări interzise',
    'terms_of_service_content_10':'În plus față de alți termeni prevăzuți în Acord, vi se interzice utilizarea site-ului web sau a conținutului acestuia: (a) în orice scop ilegal; (b) să solicite altora să efectueze sau să participe la orice acte ilegale; (c) pentru a încălca orice reglementări, reguli, legi sau ordonanțe locale internaționale, federale, provinciale sau de stat; (d) pentru a încălca sau încălca drepturile noastre de proprietate intelectuală sau drepturile de proprietate intelectuală ale altora; (e) să hărțuiască, să abuzeze, să insulte, să rănească, să defăimeze, să defăimeze, să denigreze, să intimideze sau să discrimineze pe criterii de sex, orientare sexuală, religie, etnie, rasă, vârstă, origine națională sau dizabilitate; (f) să prezinte informații false sau înșelătoare; (g) pentru a încărca sau transmite viruși sau orice alt tip de cod rău intenționat care va sau poate fi utilizat în orice mod care va afecta funcționalitatea sau funcționarea Serviciului sau a oricărui site web conexe, a altor site-uri web sau a internetului; (h) pentru a colecta sau urmări informațiile personale ale altora; (i) pentru a trimite spam, phishing, pharm, pretext, spider, crawl sau scrape; (j) pentru orice scop obscen sau imoral; sau (k) pentru a interfera cu sau eluda caracteristicile de securitate ale Serviciului sau ale oricărui site web conexe, ale altor site-uri web sau ale internetului. Ne rezervăm dreptul de a înceta utilizarea Serviciului sau a oricărui site web asociat pentru încălcarea oricăreia dintre utilizările interzise.\n' +
        'Trebuie să aveți cel puțin 16 ani pentru a utiliza platforma.\n',
    'terms_of_service_title_11':'Drepturi pentru proprietate intelectuala',
    'terms_of_service_content_11':'Acest Acord nu vă transferă de la SC ARIA ORIGINAL DESIGN SRL nicio proprietate intelectuală a SC ARIA ORIGINAL DESIGN SRL sau a unei terțe părți, iar toate drepturile, titlurile și interesul asupra și asupra unei astfel de proprietăți vor rămâne (între părți) exclusiv SC ARIA. ORIGINAL DESIGN SRL. Toate mărcile comerciale, mărcile de serviciu, grafica și logo-urile utilizate în legătură cu site-ul sau serviciile noastre, sunt mărci comerciale sau mărci comerciale înregistrate ale licențiatorilor SC ARIA ORIGINAL DESIGN SRL sau SC ARIA ORIGINAL DESIGN SRL. Alte mărci comerciale, mărci de servicii, elemente grafice și logo-uri utilizate în legătură cu site-ul sau serviciile noastre pot fi mărci comerciale ale altor terți. Utilizarea de către dvs. a site-ului și serviciilor noastre nu vă acordă niciun drept sau licență de a reproduce sau de a utiliza în alt mod orice mărci comerciale SC ARIA ORIGINAL DESIGN SRL sau terțe părți.',
    'terms_of_service_title_12':'Exonerare de garantie',
    'terms_of_service_content_12':'Sunteți de acord că utilizarea de către dvs. a site-ului web sau a serviciilor noastre se face exclusiv pe propriul risc. Sunteți de acord că un astfel de Serviciu este furnizat „ca atare” și „așa cum este disponibil”. Renunțăm în mod expres la toate garanțiile de orice fel, indiferent dacă sunt exprese sau implicite, inclusiv, dar fără a se limita la, garanțiile implicite de vandabilitate, potrivire pentru un anumit scop și neîncălcare. Nu oferim nicio garanție că Serviciile vor îndeplini cerințele dumneavoastră sau că Serviciul va fi neîntrerupt, în timp util, sigur sau fără erori; nici nu oferim nicio garanție cu privire la rezultatele care pot fi obținute din utilizarea Serviciului sau cu privire la acuratețea sau fiabilitatea oricărei informații obținute prin Serviciu sau că defectele Serviciului vor fi corectate. Înțelegeți și sunteți de acord că orice material și/sau date descărcate sau obținute în alt mod prin utilizarea Serviciului se face la discreția și riscul dumneavoastră și că veți fi singurul responsabil pentru orice deteriorare a sistemului dumneavoastră informatic sau pierderea de date care rezultă din descărcarea unor astfel de materiale și/sau date. Nu oferim nicio garanție cu privire la bunurile sau serviciile achiziționate sau obținute prin Serviciu sau orice tranzacții încheiate prin intermediul Serviciului. Niciun sfat sau informație, orală sau scrisă, obținută de dvs. de la noi sau prin intermediul Serviciului nu va crea nicio garanție care nu este prevăzută în mod expres aici.',
    'terms_of_service_title_13':'Limitare a răspunderii',
    'terms_of_service_content_13':'În măsura permisă de legea aplicabilă, în niciun caz SC ARIA ORIGINAL DESIGN SRL, afiliații, ofițerii, directorii, angajații, agenții, furnizorii sau licențiatorii săi nu vor fi răspunzători față de orice persoană pentru (a): orice indirect, incidental, special, daune punitive, de acoperire sau consecvente (inclusiv, fără limitare, daune pentru profituri pierdute, venituri, vânzări, bunăvoință, utilizare sau conținut, impact asupra afacerii, întreruperea activității, pierderea economiilor anticipate, pierderea oportunității de afaceri) oricum cauzate, în conformitate cu orice teorie de răspundere, inclusiv, fără limitare, contract, delict, garanție, încălcare a obligației statutare, neglijență sau altfel, chiar dacă SC ARIA ORIGINAL DESIGN SRL a fost informată cu privire la posibilitatea unor astfel de daune sau ar fi putut prevedea astfel de daune. În măsura maximă permisă de legea aplicabilă, răspunderea totală a SC ARIA ORIGINAL DESIGN SRL și a afiliaților săi, ofițerilor, angajaților, agenților, furnizorilor și licențiatorilor săi, în legătură cu serviciile, va fi limitată la o sumă mai mare de un dolar sau orice sume efective. plătite în numerar de către dumneavoastră către SC ARIA ORIGINAL DESIGN SRL pentru perioada anterioară de o lună anterioară primului eveniment sau eveniment care a dat naștere unei astfel de răspunderi. Limitările și excluderile se aplică și în cazul în care acest remediu nu vă compensează în totalitate pentru orice pierderi sau eșecuri în scopul său esențial.',
    'terms_of_service_title_14':'Despăgubiri',
    'terms_of_service_content_14':'Sunteți de acord să despăgubiți și să țineți SC ARIA ORIGINAL DESIGN SRL și afiliații săi, directorii, ofițerii, angajații și agenții săi fără răspundere și împotriva oricăror datorii, pierderi, daune sau costuri, inclusiv onorariile rezonabile ale avocaților, suportate în legătură cu sau care decurg din orice acuzații, pretenții, acțiuni, dispute sau solicitări ale unor terțe părți formulate împotriva oricăreia dintre acestea ca urmare a sau legate de Conținutul dvs., utilizarea de către dvs. a site-ului web sau a serviciilor sau a oricărei abateri intenționate din partea dvs.',
    'terms_of_service_title_15':'Separabilitate',
    'terms_of_service_content_15':'Toate drepturile și restricțiile cuprinse în acest acord pot fi exercitate și vor fi aplicabile și obligatorii numai în măsura în care nu încalcă nicio lege aplicabilă și sunt destinate a fi limitate în măsura în care este necesar, astfel încât să nu facă acest acord ilegal, invalid. sau inaplicabile. Dacă orice prevedere sau parte a oricărei prevederi a acestui acord va fi considerată ilegală, invalidă sau inaplicabilă de către o instanță de jurisdicție competentă, părțile intenționează ca prevederile rămase sau părți ale acestora să constituie acordul lor cu privire la subiectul prezentului, iar toate aceste prevederi rămase sau părți ale acestora vor rămâne în vigoare și efect deplin.',
    'terms_of_service_title_16':'Soluționare a litigiilor',
    'terms_of_service_content_16':'Formarea, interpretarea și executarea prezentului Acord și orice dispute care decurg din acesta vor fi guvernate de legile substanțiale și procedurale din Cluj, România, fără a ține cont de regulile sale privind conflictele sau alegerea legii și, în măsura în care este aplicabilă, de legile România. Jurisdicția exclusivă și locul de desfășurare a acțiunilor legate de obiectul prezentului articol sunt instanțele de stat și federale situate în București, România, iar prin prezenta vă supuneți jurisdicției personale a acestor instanțe. Prin prezenta, renunțați la orice drept la un proces cu juriu în orice procedură care decurge din sau este legată de acest Acord. Convenția Națiunilor Unite privind contractele de vânzare internațională de bunuri nu se aplică acestui acord.',
    'terms_of_service_title_17':'Misiune',
    'terms_of_service_content_17':'Nu aveți dreptul să cedați, să vindeți, să acordați sub-licență sau să transferați sau să delegați în orice alt mod oricare dintre drepturile sau obligațiile dvs. în temeiul prezentului, în totalitate sau în parte, fără acordul nostru prealabil scris, consimțământ care va fi la propria noastră discreție și fără obligații; orice astfel de cesiune sau transfer este nulă. Suntem liberi să cesionăm oricare dintre drepturile sau obligațiile sale în temeiul prezentului, în întregime sau parțial, oricărei terțe părți, ca parte a vânzării tuturor sau în mod substanțial a tuturor activelor sau acțiunilor sale sau ca parte a unei fuziuni.',
    'terms_of_service_title_18':'Modificări și modificări',
    'terms_of_service_content_18':'Ne rezervăm dreptul de a modifica acest Acord sau politicile sale referitoare la Site-ul sau Serviciile în orice moment, începând cu publicarea unei versiuni actualizate a acestui Acord pe Site. Când o vom face, vom revizui data actualizată în partea de jos a acestei pagini. Continuarea utilizării site-ului web după orice astfel de modificări va constitui consimțământul dumneavoastră pentru astfel de modificări.',
    'terms_of_service_title_19':'Acceptarea acestor termeni',
    'terms_of_service_content_19':'Confirmați că ați citit acest Acord și sunteți de acord cu toți termenii și condițiile acestuia. Prin utilizarea site-ului web sau a serviciilor sale, sunteți de acord să fiți legat de acest acord. Dacă nu sunteți de acord să respectați termenii acestui Acord, nu sunteți autorizat să utilizați sau să accesați site-ul web și Serviciile acestuia.',
    'terms_of_service_title_20':'Contactându-ne',
    'terms_of_service_content_20':'Dacă aveți întrebări despre această politică, vă rugăm să ne contactați.\n' +
        'SC ARIA ORIGINAL DESIGN SRL \n' +
        'Adresa: STR. FIZICIENILOR, NR.16, BL.10A, SC.A, ET.3, AP.18 \n' +
        'Bucuresti, sector 3, Romania \n' +
        'TVA: 21336993\n',
    'advantages' : 'Avantaje',   
    'about' : 'Despre',
    'home' : 'Acasă',
    'login':'Autentificare',
    'language' : 'Limbă',
    'header_text_1':'Investim în viitorul tău! Proiect selectat în cadrul Programului Operațional Regional și cofinanțat de Uniunea Europeană prin Fondul European de Dezvoltare Regională.',
    'header_text_2':'Conținutul acestui material nu reprezintă în mod obligatoriu poziția oficială a Uniunii Europene sau a Guvernului României.',
    'home_section_1_title' : 'Forța ta de vânzare.',
    'home_section_1_paragraph' : 'Clossers.com oferă un serviciu profesional pentru a conecta companii din toate domeniile cu experți în vânzări care au acces la clienți de interes sau care sunt interesați să câștige comisioane de vânzări. Acest serviciu reprezintă o modalitate de a externaliza departamentul de vânzări sau o parte din acest departament către experți în vânzări.',
    'home_section_2_paragraph' : 'Oferim suport companiilor la începutul călătoriei sau celor care nu își permit să investească într-un departament de vânzări și în activitatea de generare de lead-uri pe care trebuie să o facă agenții de vânzări: pot opta pentru a-și direcționa bugetele în comisioane, astfel încât să plătească doar pentru rezultatele.',
    'home_section_3_paragraph' : 'Este o strategie atractivă pentru un număr mare de antreprenori și o șansă pentru startup-urile care au produse și servicii de calitate și interesante de a ajunge la clienți mari și nu vor putea face acest lucru fără agenți de vânzări care au legături în aceste companii și care au a dezvoltat o relație comercială și în unele cazuri personală cu acești clienți de interes.',
    'home_section_card_1_title' : 'Avantaje pentru agenți',
    'home_section_card_2_title' : 'Avantaje pentru companii',
    'home_section_card_1_paragraph' : 'Înregistrarea profilului in portal, incarcarea CV-ului, recomandarile, portofoliul de clienti la care acceseaza sau/si domeniile in care sunt activi si unde pot genera lead-uri si semna contracte, informatii despre asteptarile pe care le au de la companii. privind comisionul minim perceput și orice alte informații care pot determina o companie să-l contacteze pentru contractarea și începerea lucrului împreună.',
    'home_section_card_2_paragraph' : 'Înregistrarea profilului în portal, încărcarea prezentării companiei și a unui set de documente care vor ajuta agenții de vânzări să înțeleagă viziunea, strategia, obiectivele pe care doresc să le rezolve apelând la o forță de vânzări externă, comisioanele pe care le oferă pt. fiecare tip de serviciu și produs separat, documentație de prezentare a portofoliului, imagini și scripturi de prezentare, portofoliu de clienți, diferențieri față de concurenții de pe piață, mărturii ale clienților și orice alte informații care pot susține agentul de vânzări. De asemenea, compania va încărca date referitoare la interesele lor comparativ cu alte companii, astfel încât utilizatorii aceluiași tip de portal să le găsească cu ușurință pentru a începe o colaborare B2B.',
    'footer_email_placeholder' : 'Introduceți emailul',
    'footer_email_send_button' : 'Trimite',
    

}