import React, {useContext, useEffect, useState} from 'react';
import {Badge, Button, Dropdown, NavLink} from "react-bootstrap";
import Notification from "../Utils/Notification/Notification";
import {server, server_path_files} from "../../const";
import {toast} from "react-toastify";
import LanguageContext from "../../contexts/LanguageContext";
import {useIntl} from "react-intl";
export default function Header() {

    const context = useContext(LanguageContext);
    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const [unread,setUnread] =useState('')
    const [request,setRequest] =useState('')
    const [requestUnread,setRequestUnread] =useState('')
    const [notifications,setNotifications] =useState('')
    const [notificationsUnread,setNotificationsUnread] =useState('')
    const [activeLanguage, setActiveLanguage] = useState('RO')


    const [usersList, setUsersList] = useState('')
    
    const languages = [
    {
        title:'RO',
            image: '/images/Romania.jpg'
    },
        {
            title:'ENG',
            image: '/images/US.png'
        }
    ]

    useEffect(() => {
        const getUnreadMessages = () => {
            fetch(server + '/notifications' , {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['id']) {
                    setUnread(json['unread_messages'])
                    setRequest(json['request_notifications'])
                    setRequestUnread(json['request_notifications_unread'])
                    setNotifications(json['notifications'])
                    setNotificationsUnread(json['notifications_unread'])

                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getUnreadMessages()
    },[token, intl])


    const getUsers = (search) => {

        var data = new FormData()
        data.append('search', search)
        fetch(server + '/get_users', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            setUsersList(json['data'])
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        <div className={"header"}>

            <div className={"notifications"}>

                <Dropdown>
                    <Dropdown.Toggle variant={"info"}>
                        <div className={"itemHeader"}>
                            <img src={"/images/quest.png"} className={"icon"} alt={""}/>
                            {
                                requestUnread !== 0 && <Badge pill>{requestUnread}</Badge>
                            }
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'access_requests'})}
                        </div>
                        {/*<div className={"markRead"}>
                            Mark all as read
                        </div>*/}
                        {
                            request !== '' && request.map((item,i) => (
                                <Notification
                                    key={i}
                                    id={item.id}
                                    type={item.type}
                                    description={item.user.name ? item.user.name : item.user.first_name + ' ' + item.user.last_name}
                                    date={item.created_at}
                                    image={item.user.logo}
                                    company={item.company}
                                />
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
          
                <div className={"itemHeader"} onClick={() =>window.location.href='/inbox/0'}>
                    <img src={"/images/Group.png"} className={"icon"} alt={""}/>
                    {
                        unread !== 0 && <Badge pill>{unread}</Badge>
                    }
                </div>
                <Dropdown>
                    <Dropdown.Toggle variant={"info"}>
                        <div className={"itemHeader"}>
                            <img src={"/images/bell.png"} className={"icon"} alt={""}/>
                            {
                                notificationsUnread !== 0 && <Badge pill>{notificationsUnread}</Badge>
                            }
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'notifications'})}
                        </div>
                      {/*  <div className={"markRead"}>
                            Mark all as read
                        </div>*/}
                        {
                            notifications !== '' && notifications.map((item,i) => (
                                <Notification
                                    key={i}
                                    id={item.id}
                                    type={item.type}
                                    description={item.user.name ? item.user.name : item.user.first_name + ' ' + item.user.last_name}
                                    date={item.created_at}
                                    image={item.user.logo}
                                    company={item.company}
                                />
                            ))
                        } 
                        
                         </Dropdown.Menu>
                </Dropdown>


            </div>
            <div className={"search"}>
                <div className={"cont"}>
                    <input placeholder={ intl.formatMessage({id: 'search'})} onChange={(e) =>
                        e.target.value.length > 2 && getUsers(e.target.value)}/>
                    <img src={"/images/search-line.png"} alt={""}/>
                </div>
                {usersList !== '' &&
                <div className={"usersList"}>
                    {
                        usersList.map((item, i) => (
                            <div key={i} className={"discussionItem"} onClick={() => {
                                item.name ?
                                    window.location.href = "/company-about/" + item.user_id
                                    :
                                    window.location.href = "/profile-job-history/" + item.user_id
                            }}>
                                <div className={"image"}>
                                    <img src={item.logo ? (server_path_files + item.logo) : ''} alt={""}/>
                                </div>
                                <div className={"d-inline-flex"}>
                                    <div className={"name m-auto"}>
                                        {
                                            item.name ?
                                                item.name
                                                :
                                                item.first_name + ' ' + item.last_name

                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                }
            </div>
            <div className={"account"}>
                <div className={"language"}>
                    <Dropdown>
                        {
                            languages.map((item,i) => (
                                item.title === activeLanguage &&
                                <Dropdown.Toggle
                                    key={i} 
                                    variant={"info"}>
                                    <img className={"flag"} src={item.image} alt={""}/>
                                    <div className={"title"}>
                                        {item.title}
                                    </div>
                                    <img src={"/images/arrow-down-s-line.png"} alt={""}/>
                                </Dropdown.Toggle>
                            ))
                        }

                        <Dropdown.Menu className={"language"}>

                            {
                                languages.map((item,i) => (
                                    item.title !== activeLanguage &&

                                            <Dropdown.Item key={i} onClick={() => {
                                                setActiveLanguage(item.title)
                                                context.setLanguage(item.title)
                                                localStorage.setItem('language', item.title)
                                            }}>
                                                <img className={"flag"} src={item.image} alt={""}/>
                                                <div className={"title"}>
                                                    {item.title}
                                                </div>
                                            </Dropdown.Item>

                                ))
                            }
                            
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className={"m-auto"}>
                <NavLink href={sessionStorage.getItem('profileType') === 'user' ? '/packages-agents':'/packages'}>
                    <Button variant={'secondary'}>
                        {intl.formatMessage({id: 'packets'})}
                    </Button>
                </NavLink>
            </div>
        </div>
    )
}