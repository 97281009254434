import React, {useState} from 'react';
import './style.css';
import {toast} from 'react-toastify';
import {customSelectStyle, server} from "../../const";
import Select from "react-select";
import {useIntl} from "react-intl";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import { LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton } from 'react-social-login-buttons';


export default function CreateAccount() {

    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState({value: 1, label: intl.formatMessage({id: 'company'}), role: 'company'});

    const optionsType = [
        {value: 1, label: intl.formatMessage({id: 'company'}), role: 'company'},
        {value: 2, label: intl.formatMessage({id: 'agent'}), role: 'agent'},
    ]

    const [loginFacebook, setLoginFacebook] = useState(false);


   

    const register = (e) => {

        e.preventDefault()

        fetch(server + '/auth/register', {
            method: 'POST',
            body: JSON.stringify({
                'email': email,
                'password': password,
                'name': name,
                'role': role.role

            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }


        }).then(response => {
            if (response.ok) {
                window.location.href = "/"
            } else {
                toast.warning(response.error);
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        <div className="main">
            <div className={"background"}>
                <img className={"img1"} src={"/images/login/freepik  background complete  inject 31.svg"} alt={""}/>
            </div>
            <div className={"login"}>
                <img className={"img4"} src={"/images/login/freepik  Character  inject 31.svg"} alt={""}/>
                <img className={"img2"} src={"/images/login/freepik  Plant  inject 31.svg"} alt={""}/>
                <img className={"img3"} src={"/images/login/freepik  Floor  inject 31.svg"} alt={""}/>
                <div className={"fcontainer"}>

                    <h1 className={"login_title"}> {intl.formatMessage({id: 'create_account'})}</h1>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'with_social_media_account_or_your_email'})}</p>
                    <div className="social-buttons-container">
                        {
                            <LoginSocialFacebook
                                    appId="840606294475263" 
                                    onResolve={(response) => {
                                        const { email, name ,id } = response.data;
                                        setLoginFacebook(true)
                                        setEmail(email)
                                        setName(name)
                                        setPassword(id)
                                    }}
                                    onReject={(error) => {
                                        console.log(error);
                                        setLoginFacebook(false);
                                    }}
                                >
                                <FacebookLoginButton/>
                            </LoginSocialFacebook>
                        }

                        <div className="google-login-button-container">
                                    <GoogleOAuthProvider clientId="1016943213858-b4iio29ng6jh6k68938pa69mor1ce89v.apps.googleusercontent.com">
                                        <GoogleLogin
                                            className="google-login-button"
                                                onSuccess={credentialResponse => {
                                                    var decoded = jwt_decode(credentialResponse.credential)
                                                    setEmail(decoded.email)
                                                    setName(decoded.name)
                                                    setPassword(decoded.sub)
                                                }}
                                                onError={() => {
                                                    console.log('Login Failed');
                                                }}
                                        />
                                    </GoogleOAuthProvider>
                                </div>  
                         </div>
                    <div className={"form-group col-lg-12 mx-auto d-flex align-items-center my-4"}>
                        <div className={"border-bottom w-100 ml-5"}>

                        </div>
                        <span className={"or px-2 small text-muted font-weight-bold text-muted"}>{intl.formatMessage({id: 'or'})}</span>
                        <div className={"border-bottom w-100 mr-5"}>

                        </div>
                    </div>
                    <form className={"form-container"} onSubmit={(e) => register(e)}>
                        <div className={"form-group"}>
                            <div>
                                <div className={"inputs"}>
                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'email'})}</span>
                                        <input required type={loginFacebook ? 'text' : "email"} value={email} onChange={(e) => setEmail(e.target.value)}
                                               className={"form-control"} id={"InputEmail1"}
                                               placeholder={intl.formatMessage({id: 'enter_email'})}/>
                                    </div>

                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'full_name'})}</span>
                                        <input required type={"name"} value={name} onChange={(e) => setName(e.target.value)} className={"form-control"} id={"InputName"}
                                               placeholder={intl.formatMessage({id: 'enter_full_name'})}/>
                                    </div>
                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'account_type'})}</span>
                                        <Select
                                            type={"accountType"}
                                            placeholder={intl.formatMessage({id: 'select'})}
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'}
                                            styles={customSelectStyle}
                                            options={optionsType}
                                            value={role}
                                            onChange={(e) => setRole(e)}
                                            required
                                        />
                                    </div>

                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'password'})}</span>
                                        <input required type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} className={"form-control"}
                                               id={"InputPassword1"}
                                               placeholder={intl.formatMessage({id: 'password'})}/>
                                    </div>


                                    <div className={"row px-3 mb-4"}>
                                        <div className={"rememberMe col-12 custom-control custom-checkbox custom-control-inline p-0"}>
                                            <input id="chk1" type="checkbox" name="chk" className="custom-control-input" required>
                                            </input>
                                            {intl.formatMessage({id: 'agree'})}{" "}
                                            <a href={"/terms"} className={"forgotPassword col-6 ml-auto mb-0 text-sm"}>
                                                {intl.formatMessage({id: 'terms_of_service_privacy_policy'})}     </a>
                                        </div>
                                    </div>
                                    <button type={"submit"} className={"btn btn-primary btn-block w-100"}>{intl.formatMessage({id: 'sign_up'})}</button>
                                    <div className={"form-footer"}>
                                        <p>{intl.formatMessage({id: 'have_an_account'})}? <a href={"/login"}>{intl.formatMessage({id: 'then_sign_in'})}</a></p>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </div>

        </div>
    )
}