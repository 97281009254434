import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {React, useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route, useParams
} from "react-router-dom";
import Home from "./components/Home/Home";
import Layout from "./components/template/Layout";
import Company from "./components/Company/Company";
import OpportunityEdit from "./components/Opportunity/OpportunityEdit/OpportunityEdit";
import Contracts from "./components/Contracts/Contracts";
import Finance from "./components/Finance/Finance";
import Leads from "./components/Leads/Leads/Leads";
import LeadsEdit from "./components/Leads/LeadsEdit/LeadsEdit";
import LeadsCreate from "./components/Leads/LeadsCreate/LeadsCreate";
import Opportunity from "./components/Opportunity/Opportunity/Opportunity";
import OpportunityCreate from "./components/Opportunity/OpportunityCreate/OpportunityCreate";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import ViewOpportunities from "./components/ViewOpportunities/ViewOpportunities";
import ViewOpportunity from "./components/ViewOpportunities/ViewOpportunity/ViewOpportunity";
import ProfileUser from "./components/ProfileUser/ProfileUser";
import {toast, ToastContainer} from "react-toastify";
import {server} from "./const";
import CreateAccount from "./components/Login/CreateAccount";
import ResetPassword from "./components/Login/ResetPassword";
import LeadsAgent from "./components/Leads/LeadsAgent/LeadsAgent";
import ViewLead from "./components/Leads/ViewLead/ViewLead";
import CollaborationCompanies from "./components/Collaboration/CollaborationCompanies/CollaborationCompanies";
import CollaborationAgents from "./components/Collaboration/CollaborationAgents/CollaborationAgents";
import Inbox from "./components/Inbox/Inbox";
import Invoices from "./components/Invoices/Invoices";
import Invoice from "./components/Invoices/Invoice/Invoice";
import Packages from "./components/Packages/Packages";
import PackagesAgents from "./components/PackagesAgents/PackagesAgents";
import ActivatePackage from "./components/ActivatePackage/Activatepackage";
import MetaTags from 'react-meta-tags';
import Reports from "./components/Reports/Reports";
import Admin from "./components/Admin/Admin";
import AgentLeads from "./components/AgentLeads/AgentLeads/AgentLeads";
import AgentLeadsCompany from "./components/AgentLeads/AgentLeadsCompany/AgentLeadsCompany";
import AgentLeadsCreate from "./components/AgentLeads/AgentLeadsCreate/AgentLeadsCreate";
import AgentLeadsEdit from "./components/AgentLeads/AgentLeadsEdit/AgentLeadsEdit";
import AgentViewLead from "./components/AgentLeads/AgentViewLead/AgentViewLead";
import {ro} from "./translations/ro"
import {eng} from "./translations/eng"
import {IntlProvider} from "react-intl";
import LanguageContext from "./contexts/LanguageContext";
import Terms from "./components/Terms/Terms";

function App() {

    const token = sessionStorage.getItem('token')

    const [userType, setUserType] = useState('')
    const [platformInfo, setPlatformInfo] = useState('')
    const [language, setLanguage] = useState( localStorage.getItem('language') ?? 'RO')

    useEffect(() => {
        const getUser = () => {
            fetch(server + '/auth/user', {
                method: 'GET',
                headers: {
                    'content_type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']['id']) {
                    sessionStorage.setItem('activities_filtre', JSON.stringify(json['data']['activities_filtre']))
                    sessionStorage.setItem('countries_filtre', JSON.stringify(json['data']['countries_filtre']))
                    sessionStorage.setItem('user_id', json['data']['id'])
                    if (json['data']['role'] === "0") {
                        sessionStorage.setItem('profileType', 'admin')
                    } else {
                        if (json['data']['role'] === "1") {
                            sessionStorage.setItem('profileType', 'company')
                        } else {
                            sessionStorage.setItem('profileType', 'user')
                        }
                    }
                    setUserType(sessionStorage.getItem('profileType'))
                }

            }).catch(e => {
                toast.warning(e);
            });
        }


        const getPlatformInfo = () => {
            fetch(server + '/platform', {
                method: 'GET',
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    setPlatformInfo(json['data'])
                }

            }).catch(e => {
                toast.warning(e);
            });
        }

        if (token && userType === '')
            getUser()
        getPlatformInfo()
    }, [userType, token])

    const getLanguageFile = (lang) => {
        switch (lang) {
            case 'RO':
                return ro
            default:
                return eng
        }
    }

    const changeLanguage = (lang) => {
        setLanguage(lang)
        localStorage.setItem('language', lang)
    }
    const languageContextValue = {
        language: language,
        setLanguage: changeLanguage
    };

    return (
        platformInfo !== '' &&
        <div>
            <MetaTags>
                <title>{platformInfo['title']}</title>
                <meta id="meta-description" name="description" content={platformInfo['description']} />
                <meta id="meta-keywords" name="keywords" content={platformInfo['keywords']} />
                <meta id="og-title" property="og:title" content={platformInfo['title']} />
                <meta id="og-image" property="og:image" content={server + platformInfo['logo']} />
          
            </MetaTags>

            <LanguageContext.Provider value={languageContextValue}>
                <IntlProvider messages={getLanguageFile(language)} locale={language}>
                    <div>
                        <Router>
                            <ToastContainer/>
                            {
                                !token ?
                                    <Switch>
                                        <Route path="/" exact>
                                            <Home/>
                                        </Route>
                                        <Route path="/login" exact>
                                            <Login/>
                                        </Route>
                                        <Route path="/terms" exact>
                                            <Terms/>
                                        </Route>
                                        <Route path="/create-account" exact>
                                            <CreateAccount/>
                                        </Route>
                                        <Route path="/reset-password" exact>
                                            <ResetPassword/>
                                        </Route>
                                    </Switch>
                                    :
                                    <Layout>
                                        <Switch>
                                            <Route path="/terms" exact>
                                                <Terms/>
                                            </Route>
                                            <Route path="/" exact>
                                                <Dashboard/>
                                            </Route>
                                            <Route path="/reports" exact>
                                                <Reports/>
                                            </Route>
                                            <Route path="/admin" exact>
                                                <Admin/>
                                            </Route>
                                            <Route path="/company-about/:id">
                                                <Company/>
                                            </Route>
                                            <Route path="/company-activity/:id">
                                                <Company/>
                                            </Route>
                                            <Route path="/company-files/:id">
                                                <Company/>
                                            </Route>
                                            <Route path="/company-settings/:id">
                                                <Company/>
                                            </Route>
                                            <Route path="/profile-experience/:id">
                                                <ProfileUser/>
                                            </Route>
                                            <Route path="/profile-job-history/:id">
                                                <ProfileUser/>
                                            </Route>
                                            <Route path="/profile-settings/:id">
                                                <ProfileUser/>
                                            </Route>
                                            <Route path="/finance">
                                                <Finance/>
                                            </Route>

                                            <Route path="/invoice/:id" exact>
                                                <Invoice/>
                                            </Route>
                                            <Route path="/invoices" exact>
                                                <Invoices/>
                                            </Route>

                                            <Route path="/leads" exact>
                                                <Leads/>
                                            </Route>
                                            <Route path="/agent/leads" exact>
                                                <LeadsAgent/>
                                            </Route>
                                            <Route path="/leads/create">
                                                <LeadsCreate/>
                                            </Route>
                                            <Route path="/leads/edit/:id">
                                                <LeadsEdit/>
                                            </Route>
                                            <Route path="/inbox/:id">
                                                <Inbox/>
                                            </Route>

                                            <Route path="/opportunity" exact>
                                                <Opportunity/>
                                            </Route>
                                            <Route path="/opportunity/create/:type">
                                                <OpportunityCreate/>
                                            </Route>
                                            <Route path="/opportunity/edit/:id">
                                                <OpportunityEdit/>
                                            </Route>

                                            <Route path="/collaboration/companies">
                                                <CollaborationCompanies/>
                                            </Route>
                                            <Route path="/collaboration/agents">
                                                <CollaborationAgents/>
                                            </Route>

                                            <Route path="/contracts">
                                                <Contracts/>
                                            </Route>

                                            <Route path="/agent/lead/:id">
                                                <ViewLead/>
                                            </Route>
                                            <Route path="/view-opportunities/:type">
                                                <ViewOpportunitiesHook/>
                                            </Route>

                                            <Route path="/view-opportunity/:type/:id">
                                                <ViewOpportunityHook/>
                                            </Route>


                                            <Route path="/packages">
                                                <Packages/>
                                            </Route>

                                            <Route path="/activate-package">
                                                <ActivatePackage/>
                                            </Route>
                                            <Route path="/packages-agents">
                                                <PackagesAgents/>
                                            </Route>


                                            <Route path="/agent-leads" exact>
                                                <AgentLeads/>
                                            </Route>
                                            <Route path="/agent-leads-view" exact>
                                                <AgentLeadsCompany/>
                                            </Route>
                                            <Route path="/agent-leads/create">
                                                <AgentLeadsCreate/>
                                            </Route>
                                            <Route path="/agent-leads/edit/:id">
                                                <AgentLeadsEdit/>
                                            </Route>
                                            <Route path="/agent-lead-view/:id">
                                                <AgentViewLead/>
                                            </Route>

                                            <Route path="/logout">
                                                <Logout/>
                                            </Route>
                                        </Switch>
                                    </Layout>
                            }
                        </Router>
                    </div>
                </IntlProvider>
            </LanguageContext.Provider>
        </div>

    );
}

function ViewOpportunitiesHook() {
    const {type} = useParams()
    return <ViewOpportunities type={parseInt(type)}/>
}

function ViewOpportunityHook() {
    const {type} = useParams()
    return <ViewOpportunity type={parseInt(type)}/>
}

function Logout() {
    sessionStorage.clear()
    window.location.href = "/"
}

export default App;