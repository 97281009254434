import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import Select from 'react-select'
import {customSelectStyle, server, server_path_files} from "../../../const";
import {useDropzone} from 'react-dropzone';
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

export default function CompanySettings() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const id = useParams().id

    const [optionsDomains, setOptionsDomains] = useState([])
    const [optionsCountry, setOptionsCountry] = useState([])
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [about, setAbout] = useState("");
    const [aboutClients, setAboutClients] = useState("");
    const [link, setLink] = useState("");
    const [facebook, setFacebook] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [files, setFiles] = useState([]);
    const [activity, setActivity] = useState([]);
    const [country, setCountry] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [activityFiltre, setActivityFiltre] = useState("");
    const [countryFiltre, setCountryFiltre] = useState("");
    const [logoFile, setLogoFile] = useState([]);
    const [logoFileDb, setLogoFileDb] = useState([]);
    const [filesDb, setFilesDb] = useState([]);


    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [keywords, setKeywords] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const [publishableKey, setPublishableKey] = useState("");
    const [logoFilePlatform, setLogoFilePlatform] = useState([]);
    const [logoFileDbPlatform, setLogoFileDbPlatform] = useState([]);

    const {getRootProps: getRootPropsLogo, getInputProps: getInputPropsLogo} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setLogoFile(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setLogoFileDb([])
        }
    });
    const {getRootProps: getRootPropsLogoPlatform, getInputProps: getInputPropsLogoPlatform} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setLogoFilePlatform(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setLogoFileDbPlatform([])
        }
    });
    const {getRootProps: getRootPropsFiles, getInputProps: getInputPropsFiles} = useDropzone({
        accept: '.pdf, .doc, video/*',
        onDrop: acceptedFiles => {
            let newFile = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));
            setFiles(newFile);
            setFilesDb([])

        }
    });

    const optionsCurrency = [{
        value: 0,
        label: 'RON'
    }, {
        value: 1,
        label: 'EURO'
    }]

    const deleteFile = (files, setFiles, i) => {
        let newFiles = files.slice()
        delete newFiles[i]
        setFiles(newFiles)
    }

    const getThumbs = (files, setFiles) => {
        return (
            <div>
                {
                    files.map((file, i) => (
                        <div className={"thumb"} key={i}>
                            <div className={"droppedImage"}>
                                {
                                    file.type === 'video/mp4' ?
                                        <div className={"videoPreview"}>
                                            <img src={"/images/video-image.svg"} alt={""}/>
                                        </div>
                                        :
                                        file.type === 'application/pdf' ?
                                            <div className={"pdfPreview"}>
                                                <img src={"/images/pdf 1.svg"} alt={""}/>
                                            </div>
                                            :
                                            <img
                                                src={file.preview} alt={""}
                                            />
                                }
                                <img
                                    src={"/images/delete.svg"}
                                    className={"deleteIcon"}
                                    onClick={() => deleteFile(files, setFiles, i)}
                                    alt={""}
                                />

                            </div>
                        </div>
                    ))
                }

            </div>
        )
    }

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        logoFile.forEach(file => URL.revokeObjectURL(file.preview));
    }, [logoFile]);

    useEffect(() => {

        const getActivities = () => {

            fetch(server + '/activity', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr = []
                    setOptionsDomains([])
                    json['data'].forEach((opt, i) => {
                            arr.push({
                                value: opt.id,
                                label: opt.activity
                            })
                        }
                    )
                    setOptionsDomains(arr)
                    getCountries()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getCountries = () => {

            fetch(server + '/country', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    setOptionsCountry([])
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.id,
                                label: opt.country
                            })
                        }
                    )
                    setOptionsCountry(arr2)

                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        getActivities()
    }, [token, intl]);

    useEffect(() => {


        const getCompanyInfo = () => {

            if (optionsDomains.length === 0) {
                console.log('waiting')
                return;
            }

            fetch(server + '/company/' + id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['name']) {
                    setName(json['name'])
                    setEmail(json['email'])
                    setPhone(json['phone'])
                    setAddress(json['address'])
                    setAbout(json['about'])
                    setLink(json['link'])
                    setLinkedin(json['linkedin'])
                    setFacebook(json['facebook'])
                    setAboutClients(json['about_clients'])
                    for (const currency_opt of optionsCurrency) {
                        if (currency_opt.value === json['currency']) {
                            setCurrency({
                                value: currency_opt.value,
                                label: currency_opt.label
                            })
                        }
                    }
                    if (json['presentation'] !== null) {
                        setFilesDb([json['presentation']])
                    }

                    let arr = []
                    for (const act of json['activity']) {
                        for (const dom of optionsDomains) {
                            if (dom.value === act) {
                                arr.push({
                                    value: dom.value,
                                    label: dom.label
                                })
                            }
                        }
                    }
                    setActivity(arr)

                    arr = []
                    for (const act of json['country']) {
                        for (const co of optionsCountry) {
                            if (co.value === act) {
                                arr.push({
                                    value: co.value,
                                    label: co.label
                                })
                            }
                        }
                    }
                    setCountry(arr)

                    arr = []
                    for (const act of json['activity_filtre']) {
                        for (const dom of optionsDomains) {
                            if (dom.value === act) {
                                arr.push({
                                    value: dom.value,
                                    label: dom.label
                                })
                            }
                        }
                    }
                    setActivityFiltre(arr)

                    arr = []
                    for (const act of json['country_filtre']) {
                        for (const co of optionsCountry) {
                            if (co.value === act) {
                                arr.push({
                                    value: co.value,
                                    label: co.label
                                })
                            }
                        }
                    }
                    setCountryFiltre(arr)
                    setLogoFileDb([json['logo']])

                    if (json['platform']) {
                        setTitle(json['platform']['title'])
                        setDescription(json['platform']['description'])
                        setKeywords(json['platform']['keywords'])
                        setPublishableKey(json['platform']['publishable_key_stripe'])
                        setSecretKey(json['platform']['secret_key_stripe'])
                    }
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (optionsCountry.length !== 0 && optionsDomains.length !== 0) {
            getCompanyInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsDomains, optionsCountry, token, id]);

    const editCompany = (e) => {
        e.preventDefault()
        var data = new FormData()
        data.append('name', name)
        data.append('about', about)
        data.append('email', email)
        data.append('phone', phone)
        data.append('address', address)
        data.append('link', link)
        data.append('linkedin', linkedin)
        data.append('facebook', facebook)
        data.append('about_clients', aboutClients)
        //data.append('currency', currency.value)
        data.append('currency', 0)
        if (activity !== undefined && activity.length > 0) {
            for (const act of activity) {
                data.append('activity[]', act.value)
            }
        }
        if (country !== undefined && country.length > 0) {
            for (const co of country) {
                data.append('country[]', co.value)
            }
        }
        if (activityFiltre !== undefined && activityFiltre.length > 0) {
            for (const actFiltre of activityFiltre) {
                data.append('activity_filtre[]', actFiltre.value)
            }
        }
        if (countryFiltre !== undefined && countryFiltre.length > 0) {
            for (const country of countryFiltre) {
                data.append('country_filtre[]', country.value)
            }
        }
        data.append('logo', logoFile[0])
        for (const file of files) {
            data.append('file[]', file)
        }
        for (const file of filesDb) {
            data.append('file[]', file)
        }
        fetch(server + '/company', {
            method: 'POST',
            body: data,
            headers: {
                //'Accept': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            if (response.ok) {
                window.location.href = '/company-settings/' + id
            } else {
                toast.warning(intl.formatMessage({id: 'error'}));
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const editPlatformInfo = (e) => {
        e.preventDefault()
        var data = new FormData()
        data.append('logo', logoFilePlatform[0])
        data.append('title', title)
        data.append('description', description)
        data.append('keywords', keywords)
        data.append('publishableKey', publishableKey)
        data.append('secretKey', secretKey)
        fetch(server + '/platform', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            if (response.ok) {
                window.location.href = '/company-settings/' + id
            } else {
                toast.warning(intl.formatMessage({id: 'error'}));
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
    return (
        <div className={"companySettings"}>
            <Row>
                <form className={"form-container p-2"} onSubmit={(e) => editCompany(e)}>

                    <Col>
                        <h2 className={"mb-4"}>
                            {intl.formatMessage({id: 'complete_the_blanks'})}
                        </h2>

                        <h3>
                            {intl.formatMessage({id: 'company_information'})}
                        </h3>
                        <Row className={"companyInfo"}>
                            <Col md={3}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'company_name'})}</span>
                                    <input name={"1"} required value={name} onChange={(e) => setName(e.target.value)}/>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'email'})}</span>
                                    <input name={"1"} required value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'phone'})}</span>
                                    <input name={"1"} required value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'location'})}</span>
                                    <input name={"1"} required value={address} onChange={(e) => setAddress(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div {...getRootPropsLogo()} className={"dropzone"}>
                                    <input {...getInputPropsLogo()} />
                                    <img src={"/images/free-icon-svg-1197487 1.svg"} alt={""}/>
                                    <div className={"title"}>{intl.formatMessage({id: 'drag_and_drop_logo_here_or'})}
                                        <span>{intl.formatMessage({id: 'searching_for'})}</span> {intl.formatMessage({id: 'to_choose_a_logo'})}</div>
                                </div>
                                <aside className={"thumbsContainer"}>
                                    {getThumbs(logoFile, setLogoFile)}

                                    <div>
                                        {
                                            logoFileDb.map((file, i) => (
                                                <div className={"thumb"} key={i}>

                                                    <div className={"droppedImage"}>
                                                        {
                                                            <img
                                                                src={file ? (server_path_files + file) : ''} alt={""}
                                                            />
                                                        }
                                                        <img
                                                            src={"/images/delete.svg"}
                                                            className={"deleteIcon"}
                                                            onClick={() => deleteFile(logoFileDb, setLogoFileDb, i)}
                                                            alt={""}
                                                        />

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </aside>
                            </Col>
                        </Row>

                        <h3>
                            {intl.formatMessage({id: 'company_description'})}
                        </h3>
                        <h5 className={"subtitle"}>
                            {intl.formatMessage({id: 'company_overview_summary'})}
                        </h5>

                        <textarea
                            required
                            maxLength={500}
                            className={"companyDescription"}
                            placeholder={intl.formatMessage({id: 'about_the_company_max_500_characters'})}
                            value={about} onChange={(e) => setAbout(e.target.value)}
                        />
                        <h3>
                            {intl.formatMessage({id: 'description_of_customers_of_interest'})}
                        </h3>
                        <h5 className={"subtitle"}>
                            {intl.formatMessage({id: 'general_presentation'})}
                        </h5>
                        <textarea
                            required
                            maxLength={400}
                            className={"companyDescription"}
                            placeholder={intl.formatMessage({id: 'about_clients_of_interest_max_400_characters'})}
                            value={aboutClients} onChange={(e) => setAboutClients(e.target.value)}
                        />
                        <h3>
                            {intl.formatMessage({id: 'upload_the_company_presentation'})}
                        </h3>
                        <h5 className={"subtitle"}>
                            {intl.formatMessage({id: 'upload_a_file_about_the_company'})}
                        </h5>

                        <Row>
                            <Col>

                                <div {...getRootPropsFiles()} className={"dropzone"}>
                                    <input {...getInputPropsFiles()}/>
                                    <div className={"title"}>{intl.formatMessage({id: 'drag_and_drop_file_here_or'})}
                                        <span>{intl.formatMessage({id: 'searching_for'})}</span> {intl.formatMessage({id: 'to_choose_a_file'})}</div>
                                </div>
                                <aside className={"thumbsContainer"}>
                                    {getThumbs(files, setFiles)}

                                    <div>
                                        {
                                            filesDb.length > 0 && filesDb.map((file, i) => (
                                                <div className={"thumb"} key={i}>

                                                    <div className={"droppedImage"}>
                                                        {
                                                            file.type === 'video/mp4' ?
                                                                <div className={"videoPreview"}>
                                                                    <img src={"/images/video-image.svg"} alt={""}/>
                                                                </div>
                                                                :
                                                                file.includes('.pdf') ?
                                                                    <div className={"pdfPreview"}>
                                                                        <img src={"/images/pdf 1.svg"} alt={""}/>
                                                                    </div>
                                                                    :
                                                                    <img
                                                                        src={file ? (server_path_files + file) : ''} alt={""}
                                                                    />
                                                        }
                                                        <img
                                                            src={"/images/delete.svg"}
                                                            className={"deleteIcon"}
                                                            onClick={() => deleteFile(filesDb, setFilesDb, i)}
                                                            alt={""}
                                                        />

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>

                                </aside>
                            </Col>
                        </Row>


                        <h5 className={"subtitle"}>
                            {intl.formatMessage({id: 'your_youtube_link'})} :
                        </h5>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'your_url'})}</span>
                            <input name={"2"} required value={link} onChange={(e) => setLink(e.target.value)}/>
                        </div>
                        <h5 className={"subtitle"}>
                            {intl.formatMessage({id: 'useful_links'})}
                        </h5>
                        <Row>
                            <Col md={6}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'facebook'})}</span>
                                    <input name={"2"} required value={facebook} onChange={(e) => setFacebook(e.target.value)}/>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'linkedin'})}</span>
                                    <input name={"2"} required value={linkedin} onChange={(e) => setLinkedin(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>


                        <h3>
                            {intl.formatMessage({id: 'about_the_company'})}
                        </h3>

                        <Row>
                            <Col md={4}>
                                <div className={"item select"}>
                                    <div>
                                        <span>{intl.formatMessage({id: 'field_of_activity'})}</span>
                                        <Select
                                            isMulti
                                            placeholder={intl.formatMessage({id: 'select'})}
                                            menuPortalTarget={document.body}
                                            menuPosition={'fixed'}
                                            styles={customSelectStyle}
                                            options={optionsDomains}
                                            value={activity}
                                            onChange={(e) => setActivity(e)}
                                            required
                                            name={"1"}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={"item select"}>
                                    <span>{intl.formatMessage({id: 'countries'})} :</span>
                                    <Select
                                        isMulti
                                        placeholder={intl.formatMessage({id: 'select'})}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        styles={customSelectStyle}
                                        options={optionsCountry}
                                        value={country} onChange={(e) => setCountry(e)}
                                        required
                                        name={"1"}
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={"item select"}>
                                    <span>{intl.formatMessage({id: 'currency'})} :</span>
                                    <Select
                                        placeholder={intl.formatMessage({id: 'select'})}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        styles={customSelectStyle}
                                        options={optionsCurrency}
                                        value={currency} onChange={(e) => setCurrency(e)}
                                        name={"1"}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <h3>
                            {intl.formatMessage({id: 'interest_filters'})}
                        </h3>

                        <Row>

                            <Col md={4}>
                                <div className={"item select"}>
                                    <span>{intl.formatMessage({id: 'field_of_activity'})}</span>

                                    <Select
                                        isMulti
                                        placeholder={intl.formatMessage({id: 'select'})}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        styles={customSelectStyle}
                                        options={optionsDomains}
                                        value={activityFiltre} onChange={(e) => {
                                        setActivityFiltre(e)
                                    }}
                                        required
                                    />

                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={"item select"}>
                                    <span>{intl.formatMessage({id: 'countries'})}Tari :</span>
                                    <Select
                                        isMulti
                                        placeholder={intl.formatMessage({id: 'select'})}
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        styles={customSelectStyle}
                                        options={optionsCountry}
                                        value={countryFiltre} onChange={(e) => setCountryFiltre(e)}
                                        required
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className={"itemSave"}>
                            <Col>
                                <Button type={'submit'} variant={"primary"}>
                                    {intl.formatMessage({id: 'save'})}
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </form>
            </Row>

            {
                sessionStorage.getItem('profileType') === 'admin' &&
                <Row>

                    <form className={"form-container p-2"} onSubmit={(e) => editPlatformInfo(e)}>
                        <h3>
                            {intl.formatMessage({id: 'load_the_platform_logo'})}
                        </h3>
                        <Row>
                            <Col>
                                <div {...getRootPropsLogoPlatform()} className={"dropzone"}>
                                    <input {...getInputPropsLogoPlatform()} />
                                    <img src={"/images/free-icon-svg-1197487 1.svg"} alt={""}/>
                                    <div className={"title"}>{intl.formatMessage({id: 'drag_and_drop_logo_here_or'})}
                                        <span>{intl.formatMessage({id: 'searching_for'})}</span>{intl.formatMessage({id: 'to_choose_a_logo'})}</div>
                                </div>
                                <aside className={"thumbsContainer"}>
                                    {getThumbs(logoFilePlatform, setLogoFilePlatform)}

                                    <div>
                                        {
                                            logoFileDbPlatform.map((file, i) => (
                                                <div className={"thumb"} key={i}>

                                                    <div className={"droppedImage"}>
                                                        {
                                                            <img
                                                                src={file ? (server_path_files + file) : ''} alt={""}
                                                            />
                                                        }
                                                        <img
                                                            src={"/images/delete.svg"}
                                                            className={"deleteIcon"}
                                                            onClick={() => deleteFile(logoFileDb, setLogoFileDb, i)}
                                                            alt={""}
                                                        />

                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </aside>
                            </Col>
                        </Row>

                        <h3>
                            {intl.formatMessage({id: 'metadata'})}
                        </h3>
                        <Row className={"companyInfo"}>
                            <Col md={4}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'title'})}</span>
                                    <input name={"1"} required value={title} onChange={(e) => setTitle(e.target.value)}/>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'description'})}</span>
                                    <input name={"1"} required value={description} onChange={(e) => setDescription(e.target.value)}/>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'keywords'})}</span>
                                    <input name={"1"} required value={keywords} onChange={(e) => setKeywords(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>

                        <h3>
                            {intl.formatMessage({id: 'stripe_data'})}
                        </h3>
                        <Row className={"companyInfo"}>
                            <Col md={4}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'publishable_key'})}</span>
                                    <input name={"1"} required value={publishableKey} onChange={(e) => setPublishableKey(e.target.value)}/>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={"item"}>
                                    <span>{intl.formatMessage({id: 'secret_key'})}</span>
                                    <input name={"1"} required value={secretKey} onChange={(e) => setSecretKey(e.target.value)}/>
                                </div>
                            </Col>
                        </Row>


                        <Row className={"itemSave"}>
                            <Col>
                                <Button type={'submit'} variant={"primary"}>
                                    {intl.formatMessage({id: 'save'})}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Row>
            }
        </div>
    )
}