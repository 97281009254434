import React from 'react';
import './style.css';
import {useIntl} from "react-intl";


export default function Terms() {

    const intl = useIntl();

    return (
        <div className="terms">
            <div className={"background"}>
                <img className={"img1"} src={"/images/login/freepik  background complete  inject 31.svg"} alt={""}/>
            </div>
            <div className={"login"}>
                <img className={"img4"} src={"/images/login/freepik  Character  inject 31.svg"} alt={""}/>
                <img className={"img2"} src={"/images/login/freepik  Plant  inject 31.svg"} alt={""}/>
                <img className={"img3"} src={"/images/login/freepik  Floor  inject 31.svg"} alt={""}/>
                <div className={"fcontainer"} style={{height: '70vh', overflowY: "scroll"}}>

                    <h1 className={"login_title"}> {intl.formatMessage({id: 'terms_of_service_title_1'})}</h1>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_1'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_2'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_2'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_3'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_3'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_4'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_4'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_5'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_5'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_6'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_6'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_7'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_7'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_8'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_8'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_9'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_9'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_10'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_10'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_11'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_11'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_12'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_12'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_13'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_13'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_14'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_14'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_15'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_15'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_16'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_16'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_17'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_17'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_18'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_18'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_19'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_19'})}</p>
                    <h5> {intl.formatMessage({id: 'terms_of_service_title_20'})}</h5>
                    <p className={"login_subtitle"}>{intl.formatMessage({id: 'terms_of_service_content_20'})}</p>
                </div>
            </div>

        </div>
    )
}