import React from 'react';
import './style.css';

export default function Leads({id, type, title, dateStart, dateEnd}) {


    const classTypes = {
        1: {
            image: '1.svg',
            class: 'blue'
        },
        2: {
            image: '2.svg',
            class: 'green'
        },
        3: {
            image: '3.svg',
            class: 'yellow'
        },
        4: {
            image: '4.svg',
            class: 'purple'
        },
    }

    return (
        <div className={"lead"}>
            <div className={"leadIcon " + classTypes[type].class}>
                <img src={"/images/leads/" + classTypes[type].image} alt={""}/>
            </div>
            <div className={"leadTitle"}>
                {title}
            </div>
            <div className={"leadStartDate"}>
                <img src={"/images/leads/calendar-line.svg"} alt={""}/>
                {dateStart}
            </div>
            <div className={"leadEndDate"}>
                {dateEnd}
            </div>
        </div>
    )
}