import React from 'react';
import LeadOpportunityCreate from "../../Utils/LeadOpportunityCreate/LeadOpportunityCreate";
import {useParams} from "react-router-dom";
export default function OpportunityCreate() {

    const {type} = useParams()

    return (
        <div>
            <LeadOpportunityCreate
                opportunity={type}
                type={type}
            />
        </div>
    )
}