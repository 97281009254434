import React, {useEffect, useState} from 'react';
import './style.css';
import Leads2 from "../../Utils/Leads2/Leads2";
import {Col, Row, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {server} from "../../../const";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import moment from 'moment'
import {useIntl} from "react-intl";

export default function Leads() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();


    const [leads, setLeads] = useState([])

    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);

    useEffect(() => {
        const getData = () => {
            fetch(server + '/leads?page=' + page, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setLeads(json['data'])
                    setMaxPages(json['pages'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (maxPages > 0) {
            getData()
        }
    }, [page, maxPages, token, intl])


    return (

        <div className={"leads"}>
            <Row>
                <Col>
                    <div className={"mainTitle"}>
                        <div>
                            <h2>
                                {

                                    sessionStorage.getItem('profileType') !== 'user' ?
                                        intl.formatMessage({id: 'my_leads'})
                                        :
                                intl.formatMessage({id: 'leads'})
                                }
                            </h2>
                        </div>

                        <div>
                            <Link to={'/leads/create'}>
                                <Button variant={"primary"}>
                                    <img src={"/images/add-circle-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'create_lead'})}
                                </Button>
                            </Link>

                        </div>

                    </div>

                </Col>
            </Row>
            <Row className={"m-0"}>
                <Col>
                    <div className={"list"}>
                        {
                            leads.map((lead, i) => (
                                <Leads2
                                    key={i}
                                    id={lead.id}
                                    title={lead.subject}
                                    decription={lead.about}
                                    region={lead.region}
                                    budget={lead.budget}
                                    dateStart={moment(lead.created_at).format('YYYY-MM-DD')}
                                    dateEnd={moment(lead.deadline).format('YYYY-MM-DD')}
                                    leads={true}
                                    expired={lead.expired}
                                    completed={lead.completed}
                                />
                            ))
                        }
                    </div>
                </Col>
            </Row>

            {
                maxPages > 0 ?
                    <Row>
                        <Col className={"text-center"}>
                            <ReactPaginate
                                pageCount={maxPages}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={2}
                                onPageChange={(val) => setPage(val.selected + 1)}
                                forcePage={page - 1}
                                initialPage={page - 1}
                                containerClassName={"react-paginate"}
                                previousLabel={<img src={"/images/arrow-left-s-line.svg"} alt={""}/>}
                                nextLabel={<img src={"/images/arrow-right-s-line.svg"} alt={""}/>}
                            />
                        </Col>
                    </Row>
                    :
                    <Row></Row>
            }

        </div>
    )
}