import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import LanguageContext from "../../contexts/LanguageContext";
import './style.css';
import { eng } from '../../translations/eng';
import { ro } from '../../translations/ro';



export default function Home() {
    const context = useContext(LanguageContext);
    const lang = localStorage.getItem('language') ?? 'RO';
    const [activeLanguage, setActiveLanguage] = useState(lang);

    const languages = [
        { title: 'RO', image: '/images/Romania.jpg' },
        { title: 'ENG', image: '/images/US.png' }
    ];
    const translations = {
        'RO': ro,
        'ENG': eng
    };
    const currentTranslation = translations[activeLanguage];


    return (
        <>
            <div className="bg-wrapper" style={{ background: '#ffffff' }}>
                <div className="container">
                    <header>
                        <div className="logos">
                            <a href="https://www.europarl.europa.eu/factsheets/ro/sheet/95/el-fondo-europeo-de-desarrollo-regional-feder-" target="_blank" rel="noopener noreferrer">
                                <img src="images/ueLogo.svg" alt="Logo UE" />
                            </a>
                            <a href="https://www.gov.ro/" target="_blank" rel="noopener noreferrer">
                                <img src="images/govLogo.svg" alt="Logo GovRO" />
                            </a>
                            <a href="https://www.inforegio.ro/ro/" target="_blank" rel="noopener noreferrer">
                                <img src="images/regioLogo.svg" alt="Logo Regio" />
                            </a>
                            <a href="chrome-extension://efaidnbmnnnibpcajpcglclefindmkaj/https://www.fonduri-ue.ro/images/files/transparenta/comunicare/MIV.v2.2014.2020.pdf" target="_blank" rel="noopener noreferrer">
                                <img src="images/structuraleLogo.svg" alt="Logo Structurale" />
                            </a>
                        </div>
                            <p>{currentTranslation['header_text_1']}</p>
                            <p>{currentTranslation['header_text_2']}</p>
                    </header>
                </div>
            </div>

            <div className="bg-wrapper" style={{ background: '#e2eff8' }}>
                <div className="container">
                    <div className="nav">
                        <input type="checkbox" id="nav-check" />
                        <div className="nav-header">
                            <div className="nav-title">
                                <a href="/" target="_blank" rel="noopener noreferrer">    
                                    <img src="images/clossersLogo2.png" alt="CLOSSERS Logo" />
                                    </a>
                            </div>
                        </div>
                        <div className="nav-btn">
                            <label htmlFor="nav-check">
                                <span></span>
                                <span></span>
                                <span></span>
                            </label>
                        </div>
                        <div className="nav-links">
                            <a href="/" target="_blank">{currentTranslation['home']}</a>
                            <a href="/" target="_blank" rel="noopener noreferrer" >{currentTranslation['about']}</a>
                            <a href="/" target="_blank" rel="noopener noreferrer" >{currentTranslation['advantages']}</a>
                            <Link to="/login" className="nav-links">{currentTranslation['login']}</Link>
                            <div className="language-dropdown">
                                <button>{currentTranslation['language']}</button>
                                <div className="dropdown-content">
                                    {
                                        languages.map((item, i) => (
                                            item.title !== activeLanguage &&
                                            <div key={i} onClick={() => {
                                                setActiveLanguage(item.title);
                                                context.setLanguage(item.title);
                                                localStorage.setItem('language', item.title);
                                            }}>
                                                <a href="#">{item.title}</a>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-wrapper" style={{ background: 'linear-gradient(to bottom, #e2eff8, #ffffff)' }}>
                <div className="container">
                    <section className="two-column">
                        <div className="text-content">
                            <h2>{currentTranslation['home_section_1_title']}</h2>
                            <p>{currentTranslation['home_section_1_paragraph']}</p>
                        </div>
                        <div className="image-content">
                            <img src="images/appdevices.png" alt="Desktop Mobile Tablet" />
                        </div>
                    </section>
                </div>
            </div>

            <div className="bg-wrapper" style={{ background: '#ffffff' }}>
                <div className="container">
                    <section className="two-column-section1">
                        <div className="text-column">
                            <p>{currentTranslation['home_section_2_paragraph']}</p>
                        </div>
                        <div className="image-column1">
                            <img src="images/miniBusiness.png" alt="Mini Business" />
                        </div>
                    </section>
                </div>
            </div>

            <div className="bg-wrapper" style={{ background: 'linear-gradient(to bottom, #ffffff, #e2eff8 )' }}>
                <div className="container">
                    <section className="two-column-section2">
                        <div className="image-column2">
                            <img src="images/entreprenour.png" alt="Entreprenour" />
                        </div>
                        <div className="text-column2">
                            <p>{currentTranslation['home_section_3_paragraph']}</p>
                        </div>
                    </section>
                </div>
            </div>

            <div className="bg-wrapper" style={{ background: '#e2eff8' }}>
                <div className="container">
                    <section className="card-section">
                        <div className="card">
                            <img src="images/ladyavatar.png" alt="Lady Avatar" />
                            <h1>{currentTranslation['home_section_card_1_title']}</h1>
                            <p>{currentTranslation['home_section_card_1_paragraph']}</p>
                        </div>
                        <div className="card">
                            <img src="images/manavatar.png" alt="Man Avatar" />
                            <h1>{currentTranslation['home_section_card_2_title']}</h1>
                            <p>{currentTranslation['home_section_card_2_paragraph']}</p>
                        </div>
                    </section>
                </div>
            </div>

            <div className="bg-wrapper" style={{ background: 'linear-gradient(to bottom, #e2eff8, #4a99d2 )' }}>
                <div className="container">
                    <footer>
                        <h2>CLOSSERS.COM</h2>
                        <div className="newsletter">
                            <input type="email" placeholder={currentTranslation['footer_email_placeholder']} />
                            <button>{currentTranslation['footer_email_send_button']}</button>
                        </div>
                        <div className="footer-logos">
                        <a href="https://anpc.ro/ce-este-sal/" target="_blank" rel="noopener noreferrer">
                            <img src="images/anpc-sal1-1.png" alt="Footer Logo 1" />
                        </a>
                        <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">    
                            <img src="images/anpc-sol.png" alt="Footer Logo 2" />
                        </a>
                        </div>
                        <hr />
                        <div className="footer-content">
                            <p>&copy; 2023 clossers.com</p>
                            <div className="social-icons">
                            <a href="https:youtube.com" target="_blank" rel="noopener noreferrer">  
                                <img src="images/ytLogo.png" alt="YouTube" />
                            </a>
                            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">  
                                <img src="images/instalogo.png" alt="Instagram" />  
                            </a>
                            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">  
                                <img src="images/fblogo.png" alt="Facebook" /> 
                            </a>
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">  
                            <img src="images/twitlogo.png" alt="Twitter" /> 
                            </a>           
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    );
}