import React, {useEffect, useState} from 'react';
import ProfileUserHeader from "./ProfileUserHeader/ProfileUserHeader";
import {
    Switch,
    Route, useParams
} from "react-router-dom";
import './style.css';
import ProfileUserMenu from "./ProfileUserMenu/ProfileUserMenu";
import ProfileUserExp from "./ProfileUserExperience/ProfileUserExperience";
import ProfileUserJobHistory from "./ProfileUserJobHistory/ProfileUserJobHistory";
import ProfileUserSetting from "./ProfileUserSetting/ProfileUserSetting";
import {Col, Row} from "react-bootstrap";
import {server} from "../../const";
import {useIntl} from "react-intl";

export default function ProfileUser() {


    const id = useParams().id
    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const [leads,setLeads] = useState('')
    const [commissions,setCommissions] = useState('')
    const [commissionsMean,setCommissionsMean] = useState('')
    const [activityDom,setaAtivityDom] = useState('')

    useEffect(() => {
        const getExperience = () => {
            fetch(server + '/agent/activity/' + id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
                
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['domains']) {
                    setaAtivityDom(json['domains'])
                    setLeads(json['leads'])
                    setCommissions(json['commission'])
                    setCommissionsMean(json['commissionMean'])
                }
            }).catch(e => {
               // toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getExperience()
    }, [token,id]);


    return (
        <div>
            <ProfileUserHeader/>
            <ProfileUserMenu
            userId={id}/>
            <div className={"tabContent"}>
                <Row>
                    <Switch>
                        <Route path="/profile-experience/:id" >
                            <ProfileUserExp/>
                        </Route>
                        <Route path="/profile-job-history/:id" >
                            <ProfileUserJobHistory/>
                        </Route>
                        <Route path="/profile-settings/:id" >
                            <ProfileUserSetting/>
                        </Route>
                    </Switch>


                    <Col md={3} className={"activity"} >
                        <div className={"activityDom mb-4"}>
                            <h2>
                                {intl.formatMessage({id: 'activity'})}
                            </h2>

                            <div className={"itemActivity purple"}>
                                <Row>
                                    <Col md={3}>
                                        <div className={"itemImg "}>
                                            <img src={"/images/dashboard/checkbox-line.svg"} alt={""}/>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <div>
                                            <div className={"title"}>
                                                {intl.formatMessage({id: 'commissions'})}
                                            </div>
                                            <div className={"activityProgress"}>
                                                {commissions + sessionStorage.getItem('currency')}
                                            </div>
                                        </div>
{/*
                                        <ProgressBar variant="success" now={23467} max={50000}/>
*/}
                                    </Col>
                                </Row>
                            </div>

                            <div className={"itemActivity green"}>
                                <Row>
                                    <Col md={3}>
                                        <div className={"itemImg "}>
                                            <img src={"/images/dashboard/file-list-3-line.svg"} alt={""}/>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <div>
                                            <div className={"title"}>
                                                {intl.formatMessage({id: 'number_of_leads'})}
                                            </div>
                                            <div className={"activityProgress"}>
                                                {leads}
                                            </div>
                                        </div>
                                  {/*      <ProgressBar variant="success" now={80} max={100}/>*/}
                                    </Col>
                                </Row>
                            </div>

                            <div className={"itemActivity yellow"}>
                                <Row>
                                    <Col md={3}>
                                        <div className={"itemImg "}>
                                            <img src={"/images/dashboard/BriefcaseSimple Yellow.svg"} alt={""}/>
                                        </div>
                                    </Col>
                                    <Col md={9}>
                                        <div>
                                            <div className={"title"}>
                                                {intl.formatMessage({id: 'average_commission'})}
                                            </div>
                                            <div className={"activityProgress"}>
                                                {commissionsMean + sessionStorage.getItem('currency')}
                                            </div>
                                        </div>
                                {/*        <ProgressBar variant="success" now={1742} max={5000}/>*/}
                                    </Col>
                                </Row>
                            </div>

                        </div>
                        <div className={"activityDom"}>
                            <h2>
                                {intl.formatMessage({id: 'fields_of_activity'})} :
                            </h2>

                            {
                                activityDom !== '' && activityDom.map((item, i) => (
                                    <div className={"activityDomItem"} key={i}>
                                        <img src={"/images/check-line.svg"} alt={""}/>
                                        {item.activity}
                                    </div>
                                ))
                            }
                        </div>
                    </Col>
                </Row>

            </div>


        </div>
    )
}