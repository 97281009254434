import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import FileCard from "../../Utils/FileCard/FileCard";
import {server} from "../../../const";
import {toast} from "react-toastify";
import moment from "moment";
import {useIntl} from "react-intl";

export default function FinanceDescription({lead_id}) {
    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const [item, setItem] = useState('')
    
    useEffect(() => {

        const getLead = () => {
            fetch(server + "/lead/" + lead_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(json['error']);
                } else {
                    /*let arr = []
                    for (const act of json['data']['region']) {
                        for (const co of optionsCountry) {
                            if (co.value === parseInt(act)) {
                                arr.push({
                                    value: co.value,
                                    label: co.label
                                })
                            }
                        }
                    }
                    json['data']['region'] = arr*/
                    setItem(json['data'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
            getLead()
    },[lead_id,token, intl])

    return (
        <div className={"financeDescription"}>
            <Row>
                <Col>

                    <h2 className={"mb-3"}>
                        {intl.formatMessage({id: 'description'})}:
                    </h2>
                    <p className={"mb-4"}>
                        {item['about']}
                    </p>

                    <h2 className={"mb-4 mt-2"}>
                        {intl.formatMessage({id: 'adjacent_documents'})}
                    </h2>

                    <p className={"mb-4"}>
                        {intl.formatMessage({id: 'download_files_for_editing_then_use_the_upload_button_to_add_to_the_list'})}
                    </p>
                    <div className={"filesContainer"}>
                        {
                            item !== '' && item['files'].length > 0 && item['files'].map((file, i) => (
                                <FileCard
                                    key={i}
                                    id={file.id}
                                    type={file.type}
                                    title={file.name}
                                    size={file.size}
                                    path={file.path}
                                    lastAccessed={moment(file.updated_at).format("YYYY-MM-DD")}
                                    deleteButton={false}
                                />
                            ))
                        }
                    </div>
{/*                    <h2 className={"mb-4 mt-2"}>
                        Descarca fisiere pentru vizualizare
                    </h2>

                    <p className={"mb-4"}>
                        Descarca fisiere pentru editare,dupa care foloseste butonul incarca pentru a adauga in lista.
                    </p>
                    <div className={"filesContainer"}>
                        {
                            item !== '' && item['editedFiles'].length > 0 && item['editedFiles'].map((file, i) => (
                                
                                <FileCard
                                    key={i}
                                    id={file.id}
                                    type={file.type}
                                    title={file.name}
                                    size={file.size}
                                    path={file.path}
                                    lastAccessed={moment(file.updated_at).format("YYYY-MM-DD")}
                                    deleteButton={false}
                                />
                            ))
                        }
                    </div>*/}


                </Col>
            </Row>
        </div>
    )
}