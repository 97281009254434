import React from 'react';
import './style.css';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {server, server_path_files} from "../../../const";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function ViewOpportunityCard({
                                                id,
                                                type,
                                                image,
                                                company,
                                                userId,
                                                subject,
                                                date,
                                                description,
                                                region,
                                                domain,
                                                paid = 0,
                                                access = null,
                                                lead_type = 0
                                            }) {


    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const addNewPayment = (type,amount) => {

        var data = new FormData()
        if (parseInt(type) === 5){
            data.append('lead_id', id)
        }else {
            data.append('opportunity_id', id)
        }
        data.append('package_type', type)
        data.append('amount', amount)
        fetch(server + '/new_payment', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            var stripe = window.Stripe(json['publishable_key_stripe']);
            stripe.redirectToCheckout({
                sessionId: json['data']
           
            }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            });
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }


    return (
        <Row className={"viewOpCard"}>
            <Col md={2} className={"d-inline-flex"}>
                <div className={"info"}>
                    <img src={image ? (server_path_files + image) : ''} alt={""} className={"logoCont"}/>
                    <div className={"username"}>
                        {company}
                    </div>
                    <Link to={( lead_type === 1 ? '/profile-job-history/':"/company-about/") + userId  }>
                        {intl.formatMessage({id: 'profile'})}
                        <img src={"/images/arrow-right-line.svg"} alt={""}/>
                    </Link>
                </div>
            </Col>
            <Col md={8} className={"position-relative"}>
                <div className={"title"}>
                    {subject}
                </div>
                <div className={"date"}>
                    <img src={"/images/calendar-line.svg"} alt={""}/>
                    {date}
                </div>
                <div className={"desc"}>
                    {description}
                </div>

                <Row>
                    <div className={"collaboration"}>
                        {region}
                    </div>
                    {
                        domain &&
                        <div className={"manager"}>
                            {domain}
                        </div>
                    }
                </Row>

                <div className={"separator"}>

                </div>
            </Col>

            <Col md={2} className={"opButtons"}>
             {/*   <Button variant={"primary"}>
                    <Link to={'/'}>
                        <img src={"/images/check-line-white.svg"} alt={""}/>
                        Accept
                    </Link>
                </Button>*/}
                <Button variant={"secondary"}>
                    {
                        paid > 0 && access === null ?

                            <div onClick={() => addNewPayment(type === 5 ? 5:type ===4? 4:6,paid)}>
                                <img src={"/images/arrow-right-line.svg"} className={"editOportunity"} alt={""}/>
                                {intl.formatMessage({id: 'pay'})}

                            </div>
                            :
                            <Link to={type === 5 ? (lead_type === 1 ? '/agent-lead-view/' : '/agent/lead/') + id : '/view-opportunity/' + type + '/' + id}>
                                <img src={"/images/arrow-right-line.svg"} className={"editOportunity"} alt={""}/>
                                {intl.formatMessage({id: 'details'})}

                            </Link>
                    }
                </Button>
                <Button variant={"secondary"} className={"mb-0"}>
                    <Link to={'/inbox/' + userId}>
                        <img src={"/images/send-plane-line-blue.svg"} className={"editOportunity"} alt={""}/>
                        {intl.formatMessage({id: 'message'})}

                    </Link>
                </Button>

            </Col>
        </Row>
    )
}