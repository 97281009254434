import React from 'react';
import './style.css';
import {ResponsiveLine} from "nivo";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useIntl} from "react-intl";

export default function Chart2({total,paid}) {
    const intl = useIntl();
    const theme = {
        axis: {
            textColor: 'rgba(204, 204, 204, 1)',
            fontSize: '14px',
            tickColor: '#eee',

        },
        grid: {
            stroke: 'rgba(247, 107, 28, 0.09)',
            strokeWidth: 1,

        },
    };
    var total_sell = 0
    var obiectiv = 0


    const data2 = [
        {
            "id": intl.formatMessage({id: 'total_sales'}),
            "color": "rgba(65, 21, 224, 1)",
            "data": total
        },
        {
            "id": intl.formatMessage({id: 'sales_objective'}),
            "color": "rgba(55, 111, 255, 1)",
            "data": paid
            
        },

    ];

    return (
        <div className={"item chart"}>
            <div className={"chart2"}>
                <div className={"title"}>
                    {intl.formatMessage({id: 'sales_evolution'})}
                </div>
                <ResponsiveLine
                    data={data2}
                    colorBy={d => d.color}
                    tooltip={point => {
                        return <div>dsa</div>;
                    }}
                    theme={theme}
                    //borderClor={'red'}
                    margin={{top: 30, right: 12, bottom: 0, left: 0}}
                    xScale={{type: 'point'}}
                    yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false}}
                    yFormat=" >-.2f"
                   // curve="natural"
                    axisTop={{orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 36}}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    enableGridY={false}

                    lineWidth={5}

                    enablePoints={false}
                    //pointSize={0}
                    //pointColor={{ theme: 'background' }}
                    //pointBorderWidth={0}
                    //pointBorderColor={{ from: 'serieColor' }}
                    //pointLabelYOffset={-12}
                    enableArea={true}
                    areaOpacity={0.1}
                    enableCrosshair={true}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 120,
                            itemHeight: 120,
                            itemOpacity: 0,
                            symbolSize: 33,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, 0)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, 0)',
                                        itemOpacity: 0
                                    }
                                }
                            ]
                        }
                    ]}
                    defs={[{
                        id: 'gradientC',
                        type: 'linearGradient',
                        colors: [
                            {offset: 0, color: '#376FFF'},
                            {offset: 23, color: '#4115E0'},
                        ],
                    },]}
                    fill={[
                        {match: '*', id: 'gradientC'},
                    ]}


                />
            </div>
            <div className={"chart2Statistic"}>
                <div className={"title"}>
                    {intl.formatMessage({id: 'sales'})}
                </div>
                <div className={"itemCS"}>
                    <div className={"title"}>
                        {intl.formatMessage({id: 'estimated_receipts'})}
                    </div>

                    <div className={"d-inline"}>
                        <div className={"desc"}>
                            
                        </div>
                        <div className={"value"}>
                            {
                                
                                data2[0]['data'].forEach((item,i) => (
                                    total_sell += item.y
                                ))
                            }
                            {total_sell + sessionStorage.getItem('currency')}
                        </div>
                    </div>

                </div>
                <div className={"itemCS"}>
                    <div className={"title"}>
                        {intl.formatMessage({id: 'total_sales'})}
                    </div>

                    <div className={"d-inline"}>
                        <div className={"desc"}>
                            
                        </div>
                        <div className={"value"}>
                            {

                                data2[1]['data'].forEach((item,i) => (
                                    item.y !== 0 ?
                                    obiectiv += parseInt(item.y)
                                        :
                                        ''
                                ))
                            }
                            {obiectiv + sessionStorage.getItem('currency')}
                        </div>
                    </div>

                </div>

                <div className={"separator"}>
                </div>

                <div className={"objective"}>
                    <div className={"title"}>
                        {intl.formatMessage({id: 'objective_achieved'})}
                    </div>
                    <div className={"value"}>
                        {parseInt(obiectiv*100/total_sell) + ' %'}
                    </div>
                    <ProgressBar variant="success" now={parseInt(obiectiv*100/total_sell)} max={100}/>
                </div>
            </div>
        </div>
    )
}