import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import Leads from "../../Utils/Leads/Leads";
import {server} from "../../../const";
import {toast} from "react-toastify";
import moment from 'moment'
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

export default function CompanyActivity() {


    const user_id = useParams().id
    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const [leads,setLeads] = useState([])
    const [activityDom, setActivityDom] = useState([])

    useEffect(() => {
        const getActivity = () => {

            fetch(server + '/company/recent_activity/' + user_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['about']) {
                    setLeads(json['leads'])
                    let arr = []
                    for (const domain of json['domains']) {
                        arr.push(domain['activity'])
                    }
                    setActivityDom(arr)
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getActivity()
    }, [token,user_id, intl]);

    return (
        <div className={"companyActivity"}>
  
            <Row>
                <Col>
                    <h2 className={"mb-4 mt-2"}>
                        { intl.formatMessage({id: 'leads_made'})}
                    </h2>

                    <Row>
                        <Col mb={9}>
                            <div className={"leadsContainer"}>
                                {
                                    leads.map((lead,i) => (
                                        <Leads
                                            key={i}
                                            id={lead.id}
                                            type={parseInt(Math.random() * 4  + 1 )}
                                            title={lead.subject}
                                            dateStart={moment(lead.created_at).format('YYYY-MM-DD')}
                                            dateEnd={moment(lead.deadline).format('YYYY-MM-DD')}
                                        />
                                    ))
                                }
                            </div>
                        </Col>
                        
                        <Col md={3} className={"activityDom"}>
                            <h2>
                                { intl.formatMessage({id: 'fields_of_activity'})} :
                            </h2>

                            {
                                activityDom.map((item,i) => (
                                    <div className={"activityDomItem"} key={i}>
                                        <img src={"/images/check-line.svg"} alt={""}/>
                                        {item}
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>


                </Col>
            </Row>
        </div>
    )
}