import React, {useEffect, useRef, useState} from 'react';
import './style.css';
import {Button, Dropdown} from "react-bootstrap";
import DiscussionItem from "../Utils/DiscussionItem/DiscussionItem";
import {useParams} from "react-router-dom";
import {server, server_path_files} from "../../const";
import {toast} from "react-toastify";
import moment from "moment";
import Message from "../Utils/Message/Message";
import {useIntl} from "react-intl";

export default function Inbox() {

    const id = useParams().id

    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const [message, setMessage] = useState('')
    const [threads, setThreads] = useState('')
    const [thread, setThread] = useState('')
    const [myUserInfo, setMyUserInfo] = useState('')
    const [userInfo, setUserInfo] = useState('')
    const [threadId, setThreadId] = useState('')
    const [userId, setUserId] = useState(id.slice())
    const [refresh, setRefresh] = useState(false)
    const [usersList, setUsersList] = useState('')
    const [messagesList, setMessagesList] = useState('')
    const [searchedMessage, setSearchedMessage] = useState('')


    useEffect(() => {
        const getThreads = () => {
            fetch(server + '/messages', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                   // toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setThreads(json['data'])

                    json['data'].forEach((item, i) => {
                        if (item.participant.user_id === parseInt(userId))
                            if (threadId !== item.id) {
                                setThreadId(item.id)
                            }
                    })

                }

            }).catch(e => {
              //  toast.warning(intl.formatMessage({id: 'error'}));
            });
        }


        const getParticipants = () => {
            fetch(server + '/messages/participants/' + userId, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    //let participants = ;
                    json['data']['participant'].forEach((item, i) => {
                        if (item['user_id'] === parseInt(userId)) {
                            item['last_read'] = getTimestamp(item['last_read'])
                            setUserInfo(item)
                        } else {
                            setMyUserInfo(item)
                        }
                    })

                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        //  if (threads === '') {
        getThreads()
        if (userId !== ''){
            getParticipants()
        }
        //   }
    }, [token, userId, threadId, intl])

    useEffect(() => {
        setInterval(() => {
            setRefresh(!refresh)
        }, 6000)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        const getThreadInfo = () => {
            fetch(server + '/messages/' + threadId, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    if (thread !== json['data']['messages']) {
                        if (json['data']['id'] === threadId) {
                            setThread(json['data']['messages'])
                        }

                    }
                    //let participants = ;
                    json['data']['participant'].forEach((item, i) => {
                        if (item['user_id'] === parseInt(userId)) {
                            item['last_read'] = getTimestamp(item['last_read'])
                            setUserInfo(item)
                        } else {
                            setMyUserInfo(item)
                        }
                    })
                }

            }).catch(e => {
                toast.warning('A aparut o eroare!!!');
            });
        }

        if (threadId !== '') {
            getThreadInfo()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId, refresh])


    const addMessage = () => {

        var data = new FormData()
        data.append('user', userId)
        data.append('message', message)
        fetch(server + (threadId ? '/messages/' + threadId : '/messages'), {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (json['data']) {
                setThreadId(json['data']['id'])
            }
            setMessage('')
            setRefresh(!refresh)
            if (json['error']){
                toast.warning(json['error']);
            }
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const divRef = useRef(null);
    const divRef2 = useRef(null);

    useEffect(() => {
        divRef.current.scrollIntoView({behavior: 'smooth'});
    });

    const renderMessages = () => {
        let i = 0;
        let messages = thread.slice()
        let messageCount = messages.length;
        let tempMessages = [];

        while (i < messageCount) {
            let previous = messages[i - 1];
            let current = messages[i];
            let next = messages[i + 1];
            let isMine = current.user_id === myUserInfo['user_id'];
            let currentMoment = moment(current.created_at);
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;

            if (previous) {
                let previousMoment = moment(previous.created_at);
                let previousDuration = moment.duration(currentMoment.diff(previousMoment));
                prevBySameAuthor = previous.user_id === current.user_id;

                if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                    startsSequence = false;
                }

                if (previousDuration.as('hours') < 1) {
                    showTimestamp = false;
                }
            }

            if (next) {
                let nextMoment = moment(next.created_at);
                let nextDuration = moment.duration(nextMoment.diff(currentMoment));
                nextBySameAuthor = next.user_id === current.user_id;

                if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                    endsSequence = false;
                }
            } else {
                //setSearchedMessage(current.id)
            }
            tempMessages.push(
                <div className={"d-inline " + (current.id === searchedMessage ? 'active' :'')} key={i} ref={current.id === searchedMessage ? divRef : divRef2} name={current.id === searchedMessage ? 'ref' : 'ref2'}>
                    <Message
                        isMine={isMine}
                        startsSequence={startsSequence}
                        endsSequence={endsSequence}
                        showTimestamp={showTimestamp}
                        data={current}
                        image={isMine ? myUserInfo['logo'] : userInfo['logo']}
                    />
                </div>
            );
            i += 1;
        }

        if (searchedMessage === ''){
            tempMessages.push(
                <div key={i} ref={divRef} name={'ref'}/>
            ); 
        }
        

        return tempMessages;
    }

    const getUsers = (search) => {

        var data = new FormData()
        data.append('search', search)
        fetch(server + '/get_users', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            setUsersList(json['data'])
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
    const getMessages = (search) => {

        var data = new FormData()
        data.append('search', search)
        fetch(server + '/get_messages/' + threadId, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            setMessagesList(json['data'])
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const getTimestamp = (date) => {
        if (date !== undefined) {
            const now_date = moment(new Date(), 'YYYY-MM-DD  h:mm:ss')
            date = moment(date, 'YYYY-MM-DD  h:mm:ss')
            if (now_date.diff(date, "years") > 0) {
                return now_date.diff(date, "years") + ' years ago'
            } else {
                if (now_date.diff(date, "months") > 0) {
                    return now_date.diff(date, "months") + ' months ago'
                } else {
                    if (now_date.diff(date, "weeks") > 0) {
                        return now_date.diff(date, "weeks") + ' weeks ago'
                    } else {
                        if (now_date.diff(date, "days") > 0) {
                            return now_date.diff(date, "days") + ' days ago'
                        } else {
                            if (now_date.diff(date, "hours") > 0) {
                                return now_date.diff(date, "hours") + ' hours ago'
                            } else {
                                if (now_date.diff(date, "minutes") > 0) {
                                    return now_date.diff(date, "minutes") + ' minutes ago'
                                } else {
                                    if (now_date.diff(date, "seconds") > 0) {
                                        return now_date.diff(date, "seconds") + ' seconds ago'
                                    }
                                }
                            }
                        }
                    }
                }

            }

        }
    }

    return (
        <div className={"inbox"}>
            <div className={"rowMessage"}>
                <div className={"colMessagesList"}>
                    <div className={'p-3 h-100'}>
                        <div className={"messagesList"}>
                            <div className={"headerMessages"}>
                                <div className={"title"}>
                                    {intl.formatMessage({id: 'messages'})}
                                </div>
                                <div className={"newMessage"} onClick={() => {
                                    setUserInfo('')
                                    setThreadId('')
                                    setThread('')
                                    setUserId('')
                                }}>
                                    +
                                </div>
                            </div>

                            {
                                threads !== '' && threads.map((threadItem, i) => (
                                    <div key={i} onClick={() => {
                                        setUserId(threadItem.participant.user_id)
                                        setThreadId(threadItem.id)
                                        setSearchedMessage('')
                                    }}>
                                        <DiscussionItem
                                            image={threadItem.participant.logo}
                                            name={threadItem.participant.name ? threadItem.participant.name : (threadItem.participant.first_name + ' ' + threadItem.participant.last_name)}
                                            lastMessage={threadItem.latestMessage}
                                            active={threadItem.participant.user_id === parseInt(userId)}
                                            unread={threadItem.unread}
                                        />
                                    </div>
                                ))
                            }
                           
                        </div>
                    </div>
                </div>
                <div className={"colMessage"}>
                    <div className={"messageWindow"}>
                        <div className={"topBar p-0"}>
                            {
                                userInfo !== '' ?
                                    <div className={"topBar"}>
                                        <div className={"image"}>
                                            <img src={userInfo['logo'] ? (server_path_files + userInfo['logo']) : ''} alt={""}/>
                                        </div>
                                        <div>
                                            <div className={"name"}>
                                                {
                                                    userInfo['name'] ?
                                                        userInfo['name'] :
                                                        userInfo['first_name'] + ' ' + userInfo['last_name']
                                                }
                                            </div>
                                            <div className={"lastSeen"}>
                                                {
                                                    thread !== '' && (userInfo['last_read'] !== null && userInfo['last_read'] !== undefined ?
                                                        (
                                                            intl.formatMessage({id: 'last_access_first_lowercase'}) + ' ' + userInfo['last_read']
                                                        )


                                                        :
                                                        '')

                                                }
                                            </div>
                                        </div>

                                        <div className={"search d-inline"}>
                                            <div className={"cont"}>
                                                <input placeholder={ intl.formatMessage({id: 'search'})} onChange={(e) =>
                                                    e.target.value.length > 2 && getMessages(e.target.value)}/>
                                            </div>
                                            {messagesList !== '' &&
                                            <div className={"usersList"}>
                                                {
                                                    messagesList.messages.map((item, i) => (
                                                        <div className={"discussionItem"} onClick={() => setSearchedMessage(item.id)}>
                                                            <div className={"image"}>
                                                                {
                                                                    myUserInfo['user_id'] === item.user_id?
                                                                        <img src={server_path_files + myUserInfo['logo']} alt={""}/>
                                                                        :
                                                                        <img src={server_path_files + userInfo['logo']} alt={""}/>
                                                                }
                                                            </div>
                                                            <div className={"d-inline-flex"}>
                                                                <div className={"name m-auto"}>
                                                                    {item.body}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            }
                                        </div>
                                        {
                                            parseInt(userId) !== 0 &&
                                            <Dropdown>
                                                <Dropdown.Toggle variant={"info"}>

                                                    <div className={"itemHeader"}>
                                                        <img src={'/images/more-2-line.svg'} alt={""}/>
                                                    </div>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <div className={"title"} onClick={() => window.location.href = (userInfo['name'] ? '/company-about/'  + userInfo['user_id']: '/profile-job-history/' + userInfo['user_id'])}>
                                                        {intl.formatMessage({id: 'view_profile'})}
                                                        <img src={"/images/dashboard/arrow-right-line-purple.svg"} alt={""}/>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }
                                    
                                    </div>
                                    :
                                    <div className={"topBar"}>
                                        <input placeholder={ intl.formatMessage({id: 'start_a_conversation'})} onChange={(e) => {
                                            e.target.value.length > 2 && getUsers(e.target.value)
                                        }}/>
                                        {usersList !== '' &&
                                        <div className={"usersList"}>
                                            {
                                                usersList.map((item, i) => (
                                                    <div key={i} className={"discussionItem"} onClick={() => {
                                                        setUserId(item.user_id)
                                                        setUsersList('')
                                                    }}>
                                                        <div className={"image"}>
                                                            {
                                                                myUserInfo['user_id'] === item.user_id?
                                                                    <img src={server_path_files + myUserInfo['logo']} alt={""}/>
                                                                    :
                                                                    <img src={server_path_files + userInfo['logo']} alt={""}/>
                                                            } 
                                                        </div>
                                                        <div className={"d-inline-flex"}>
                                                            <div className={"name m-auto"}>
                                                                {
                                                                    item.name ?
                                                                        item.name
                                                                        :
                                                                        item.first_name + ' ' + item.last_name

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        }
                                    </div>
                            }

                        </div>
                        <div className={"messages"}>
                            <div className={"messagesList"}>
                                <div className="message-list-container">{renderMessages()}</div>
                            </div>
                            <div className={"addMessage"}>
                                <div>
                                    <input value={message} onChange={(e) => setMessage(e.target.value)}/>
                                    <Button variant={"primary"} onClick={() => message !== '' && addMessage()}>
                                        <img src={"/images/send-plane-line.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'send'})}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}