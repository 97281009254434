import React, {useEffect, useState} from 'react';
import Header from "./Header";
import {Badge, Col, Container, Row} from "react-bootstrap";
import './style.css';
import {NavLink} from "react-router-dom";
import {server, server_path_files} from "../../const";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {useIntl} from "react-intl";


export default function Layout({children}) {
    const user_id = sessionStorage.getItem('user_id')
    const intl = useIntl();

    const token = sessionStorage.getItem('token')

    const [logo, setLogo] = useState('')
    const [generalInfo, setGeneralInfo] = useState('')

    useEffect(() => {

        const getPlatformInfo = () => {
            fetch(server + '/platform', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    setLogo(json['data']['logo'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }


        const getGeneralInfo = () => {
            fetch(server + '/get_general_info', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    setGeneralInfo(json['data'])

                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        getPlatformInfo()
        getGeneralInfo()
    }, [token, intl])

    const confirmDownloadXML = (taskId) => {
        confirmAlert({
            title: intl.formatMessage({id: 'of_course_you_want_to_extract_lead_data'}),
            message: intl.formatMessage({id: 'the_file_contains_for_each_lead_id_user_id_name_region_deadline_budget_description_and_for_each_month_of_the_year_the_financial_situation_agent_debt_and_amount_paid'}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'download'}),
                    onClick: () => window.location.href = server + '/download_xml/' + user_id
                },
                {
                    label: intl.formatMessage({id: 'cancel'}),
                }
            ]
        });
    };


    return (
        <Container fluid id={generalInfo}>
            <Row>
                {
                    sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                        <Col md={2} className={"sideMenu"}>
                            <div className={"title"}>
                                <NavLink to={"/"}>
                                    {
                                        logo !== '' ?
                                            <img src={server_path_files + logo} alt={""}/>
                                            :
                                            'Example'
                                    }
                                </NavLink>

                            </div>

                            <NavLink to={"/"}>
                                <div>
                                    <img src={"/images/menu/fe-home.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'home'})}
                                </div>
                            </NavLink>
                            {
                                sessionStorage.getItem('profileType') === 'admin' &&

                                <NavLink to={"/reports"}>
                                    <div>
                                        <img src={"/images/menu/fe-home.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'reports'})}
                                    </div>
                                </NavLink>
                            }
                            {
                                sessionStorage.getItem('profileType') === 'admin' &&

                                <NavLink to={"/admin"}>
                                    <div>
                                        <img src={"/images/menu/fe-home.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'administration'})}
                                    </div>
                                </NavLink>
                            }

                            <NavLink to={"/company-about/" + user_id}>
                                <div>
                                    <img src={"/images/menu/info.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'about_the_company'})}
                                </div>
                            </NavLink>

                            <NavLink to={"/finance"}>
                                <div>
                                    <img src={"/images/menu/file-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'financial_activity'})}
                                </div>
                            </NavLink>

                            <NavLink to={"/invoices"}>
                                <div>
                                    <img src={"/images/file-text-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'invoices'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/leads"}>
                                <div>
                                    <img src={"/images/menu/BriefcaseSimple.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'leads'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/agent-leads-view"}>
                                <div>
                                    <img src={"/images/menu/BriefcaseSimple.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'agents_leads'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/opportunity"}>
                                <div>
                                    <img src={"/images/menu/flag-2-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'my_opportunities'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/collaboration/companies"}>
                                <div>
                                    <img src={"/images/menu/colab.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'collaborations_companies'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/collaboration/agents"}>
                                <div>
                                    <img src={"/images/menu/colab.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'collaborations_agents'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/contracts"}>
                                <div>
                                    <img src={"/images/menu/file-list-3-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'contracts'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/inbox/0"}>
                                <div>
                                    <img src={"/images/menu/mail-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'messages'})}
                                </div>
                            </NavLink>

                            <div className={"opportunityTitle"}>
                                {intl.formatMessage({id: 'opportunities_uppercase'})}
                            </div>
                            <NavLink to={"/view-opportunities/0"}>
                                <div>
                                    <div className={"containerLink"}>
                                        <img src={"/images/menu/Buildings.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'private_opportunities'})}
                                    </div>
                                    <Badge className={"opBadge"} pill>{generalInfo.privat}</Badge>
                                </div>
                            </NavLink>
                            <NavLink to={"/view-opportunities/1"}>
                                <div>
                                    <div className={"containerLink"}>
                                        <img src={"/images/menu/Stack.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'public_opportunities'})}
                                    </div>
                                    <Badge className={"opBadge"} pill>{generalInfo.public}</Badge>
                                </div>
                            </NavLink>
                            <NavLink to={"/view-opportunities/2"}>
                                <div>
                                    <div className={"containerLink"}>
                                        <img src={"/images/menu/Gift.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'financing'})}
                                    </div>
                                    <Badge className={"opBadge"} pill>{generalInfo.funding}</Badge>
                                </div>
                            </NavLink>
                            <NavLink to={"/view-opportunities/3"}>
                                <div>
                                    <div className={"containerLink"}>
                                        <img src={"/images/menu/Megaphone.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'auction'})}
                                    </div>
                                    <Badge className={"opBadge"} pill>{generalInfo.auction}</Badge>
                                </div>
                            </NavLink>
                            {
                                sessionStorage.getItem('profileType') === 'admin' &&

                                <NavLink to={"/view-opportunities/4"}>
                                    <div>
                                        <div className={"containerLink"}>
                                            <img src={"/images/menu/Megaphone.svg"} alt={""}/>
                                            {intl.formatMessage({id: 'opportunities_for_agents'})}
                                        </div>
                                        <Badge className={"opBadge"} pill>{generalInfo.forAgent}</Badge>
                                    </div>
                                </NavLink>
                            }
                            <div onClick={() => confirmDownloadXML()}>
                                <img src={"/images/menu/mail-line.svg"} alt={""}/>
                                {intl.formatMessage({id: 'download_xml'})}
                            </div>
                            <NavLink to={"/logout"}>
                                <div className={"float-bottom"}>
                                    <img src={"/images/menu/logout.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'logout'})}
                                </div>
                            </NavLink>


                        </Col> :
                        <Col md={2} className={"sideMenu"}>
                            <div className={"title"}>
                                {
                                    logo !== '' ?
                                        <img src={server_path_files + logo} alt={""}/>
                                        :
                                        'Example'
                                }
                            </div>
                            {/*    <div className={"currentPath"}>
                                <img src={"/images/menu/bookmark-3-line.svg"} alt={""}/>
                                Oportuninati Noi
                                <Badge pill>2</Badge>
                                <img src={"/images/menu/icon.svg"} alt={""}/>
                            </div>*/}
                            <NavLink to={"/"}>
                                <div>
                                    <img src={"/images/menu/fe-home.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'home'})}
                                </div>
                            </NavLink>

                            <NavLink to={"/profile-job-history/" + user_id}>
                                <div>
                                    <img src={"/images/menu/info.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'my_profile'})}
                                </div>
                            </NavLink>

                            <NavLink to={"/finance"}>
                                <div>
                                    <img src={"/images/menu/file-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'financial_activity'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/agent/leads"}>
                                <div>
                                    <img src={"/images/menu/BriefcaseSimple.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'leads'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/agent-leads"}>
                                <div>
                                    <img src={"/images/menu/BriefcaseSimple.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'my_leads'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/view-opportunities/4"}>
                                <div>
                                    <img src={"/images/menu/flag-2-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'opportunities'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/collaboration/companies"}>
                                <div>
                                    <img src={"/images/menu/colab.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'collaborations'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/contracts"}>
                                <div>
                                    <img src={"/images/menu/file-list-3-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'contracts'})}
                                </div>
                            </NavLink>
                            <NavLink to={"/inbox/0"}>
                                <div>
                                    <img src={"/images/menu/mail-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'messages'})}
                                </div>
                            </NavLink>

                            {/*      <div onClick={()=> getXml()}>
                                    <img src={"/images/menu/mail-line.svg"} alt={""}/>
                                    Descarca XML
                                </div>*/}
                            <NavLink to={"/logout"}>
                                <div className={"float-bottom"}>
                                    <img src={"/images/menu/logout.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'logout'})}
                                </div>
                            </NavLink>


                        </Col>
                }
                <Col md={10} className={"pageContent"}>
                    <Header/>
                    {children}
                </Col>
            </Row>
        </Container>


    )
}