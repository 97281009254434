import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import {server} from "../../const";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {useIntl} from "react-intl";
export default function PackagesAgents() {
    
    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const [activePackage,setActivePackage] = useState('')
    
    const packages = {
        free: [
            {name: 'create_user_profile', access: 1},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_3_companies_that_visited_the_profile', access: 1, now:activePackage['visitors_companies']},
            {name: 'visible_10_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'upload_cv_packages', access: 1},
            {name: 'access_3_leads', access: 1, now: activePackage['views_leads']},
            {name: 'access_to_5_opportunities', access: 1,now: activePackage['views_opportunities']},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_125_messages', access: 1, now:activePackage['messages']},
            {name: 'to_add_collaborations', access: 0},
        ],
        basic: [
            {name: 'create_user_profile', access: 1},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_3_companies_that_visited_the_profile', access: 1, now:activePackage['visitors_companies']},
            {name: 'visible_100_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'upload_cv_packages', access: 1},
            {name: 'access_50_leads', access: 1, now: activePackage['views_leads']},
            {name: 'access_to_25_opportunities', access: 1,now: activePackage['views_opportunities']},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_250_messages', access: 1, now:activePackage['messages']},
            {name: 'to_add_collaborations', access: 1},],
        plus: [
            {name: 'create_user_profile', access: 1},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_companies_that_visited_the_profile', access: 1, now:activePackage['visitors_companies']},
            {name: 'visible_250_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'upload_cv_packages', access: 1},
            {name: 'access_leads', access: 1},
            {name: 'access_to_50_opportunities', access: 1,now: activePackage['views_opportunities']},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_500_messages', access: 1, now:activePackage['messages']},
            {name: 'to_add_collaborations', access: 1},],
        premium: [
            {name: 'create_user_profile', access: 1},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_companies_that_visited_the_profile', access: 1, now:activePackage['visitors_companies']},
            {name: 'visible_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'upload_cv_packages', access: 1},
            {name: 'access_leads', access: 1},
            {name: 'access_opportunities', access: 1},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_5000_messages', access: 1, now:activePackage['messages']},
            {name: 'to_add_collaborations', access: 1},]
    }

    useEffect(() => {
        const getActivePackage = () => {
            fetch(server + '/get_active_package' , {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    setActivePackage(json['data'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getActivePackage()
    },[token, intl])

    const confirmNewPayment = (package_type,amount) => {
        confirmAlert({
            title: intl.formatMessage({id: 'do_you_want_the_subscription_to_be_extended_automatically'}),
            buttons: [
                {
                    label: intl.formatMessage({id: 'yes'}),
                    onClick:() => addNewPayment(package_type,amount,1)
                },
                {
                    label: intl.formatMessage({id: 'nu'}),
                    onClick:() => addNewPayment(package_type,amount,0)
                }
            ]
        });
    };
    
    const addNewPayment = (package_type,amount,reccuring) => {

        var data = new FormData()
        data.append('package_type', package_type)
        data.append('amount', amount)
        data.append('reccuring', reccuring)
        fetch(server + '/new_payment', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            var stripe = window.Stripe('pk_test_51J7EgjK2xL9fimGRoAaKKsDCYVYvY1mibeYbw8B3tgsEN6uE1Ed9Pr4liSfOi9eyCOkqb26J6fW7WTEAEZuylJhm00kCCMD9Qn');
            stripe.redirectToCheckout({
                sessionId: json['data']
            }).then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            });
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        <div className={"packages"}>
            <div className={"mainTitle"}>
                {intl.formatMessage({id: 'welcome'})} !
            </div>
            <p>
                {intl.formatMessage({id: 'we_are_really_happy_to_see_you_here'})} !
            </p>

            <div className={"mainTitle"}>
                {intl.formatMessage({id: 'your_plan_your_choice'})}
            </div>
            <p className={"mainDesc"}>
            </p>
            
            <Row>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 0 && 'active')} >
                        <div className={"title"}>
                            {intl.formatMessage({id: 'FREE_FOR_LIFETIME_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.free.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {intl.formatMessage({id: item.name}) +(activePackage['type'] === 0 && item.now !== undefined  ?
                                            (' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }   
                        </div>
                        {
                            activePackage['type'] !== 2 ?
                                <Button variant={"primary"} onClick={() => window.location.href = '/'}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                                <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                       
                    </div>
                    
                </Col>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 1 && 'active')}>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'BASIC_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.basic.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {item.name +(activePackage['type'] === 1 && item.now !== undefined  ? (
                                            ' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }   
                        </div>
                        {
                            activePackage['type'] !== 1 ?
                                <Button variant={"primary"} onClick={() => confirmNewPayment(1,5)}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                                <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                    </div>
                    
                </Col>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 2 && 'active')}>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'PLUS_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.plus.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {item.name +(activePackage['type'] === 2 && item.now !== undefined  ?
                                            (' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }   
                        </div>
                        {
                            activePackage['type'] !== 2 ?
                            <Button variant={"primary"} onClick={() => confirmNewPayment(2,10)}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                            <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                    </div>
                    
                </Col>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 3 && 'active')}>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'PREMIUM_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.premium.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {item.name +(activePackage['type'] === 3 && item.now !== undefined  ?
                                            (' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }   
                        </div>
                        {
                            activePackage['type'] !== 3 ?
                                <Button variant={"primary"} onClick={() => confirmNewPayment(3,15)}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                                <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                    </div>
                    
                </Col>
            </Row>
        </div>


    )
}