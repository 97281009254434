import React from 'react';
import './style.css';
import {ResponsiveLine} from "nivo";
import {useIntl} from "react-intl";

export default function Chart1({active, total}) {
    const intl = useIntl();
    const theme = {
        axis: {
            textColor: 'rgba(204, 204, 204, 1)',
            fontSize: '14px',
            tickColor: '#eee',

        },
        grid: {
            stroke: 'rgba(247, 107, 28, 0.09)',
            strokeWidth: 1,

        },
    };
    
    var active_total = 0
    var total_total = 0

    const data = [
        {
            "id": intl.formatMessage({id: 'visitors_profile'}),
            "color": "rgba(250, 217, 97, 1)",
            "data": total
        },
        {
            "id": intl.formatMessage({id: 'active_visitors'}),
            "color": "rgb(162,239,205,1)",
            "data": active
        },

    ];

    return (
        <div className={"item chart"}>
            <div className={"chart1"}>
                <ResponsiveLine
                    data={data}
                    colorBy={d => d.color}
                    tooltip={point => {
                        return <div>dsa</div>;
                    }}
                    theme={theme}
                    //borderClor={'red'}
                    margin={{top: 30, right: 12, bottom: 0, left: 0}}
                    xScale={{type: 'point'}}
                    yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false}}
                    yFormat=" >-.2f"
                //    curve="natural"
                    axisTop={{orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: 36}}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    enableGridY={false}

                    lineWidth={5}

                    enablePoints={false}
                    //pointSize={0}
                    //pointColor={{ theme: 'background' }}
                    //pointBorderWidth={0}
                    //pointBorderColor={{ from: 'serieColor' }}
                    //pointLabelYOffset={-12}
                    enableArea={true}
                    areaOpacity={0.1}
                    enableCrosshair={true}
                    useMesh={true}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 120,
                            itemHeight: 120,
                            itemOpacity: 0,
                            symbolSize: 33,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, 0)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, 0)',
                                        itemOpacity: 0
                                    }
                                }
                            ]
                        }
                    ]}
                    defs={[{
                        id: 'gradientC',
                        type: 'linearGradient',
                        colors: [
                            {offset: 0, color: '#faf047'},
                            {offset: 23, color: '#FF0000'},
                        ],
                    },]}
                    fill={[
                        {match: '*', id: 'gradientC'},
                    ]}


                />
            </div>
            <div className={"chart1Statistic"}>
                <div className={"crtItem"}>
                    <div>
                        <div className={"title"}>
                            {

                                data[0]['data'].forEach((item,i) => (
                                    total_total += item.y
                                ))
                            }
                            {total_total}
                        </div>
                        <div className={"description"}>
                            {intl.formatMessage({id: 'visitors_profile'})}
                        </div>
                        <img src={"/images/dashboard/Vector-green.svg"} alt={""}/>
                    </div>
                </div>
                <div className={"crtItem yellow"}>
                    <div>
                        <div className={"title"}>
                            {

                                data[1]['data'].forEach((item,i) => (
                                    active_total += item.y
                                ))
                            }
                            {active_total}
                        </div>
                        <div className={"description"}>
                            {intl.formatMessage({id: 'active_visitors'})}
                        </div>
                        <img src={"/images/dashboard/Vector-yellow.svg"} alt={""}/>
                    </div>
                </div>
            </div>
        </div>
    )
}