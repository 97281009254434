import React from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";

export default function Opportunity({id, title, desc, region, budget, deadline, date, image}) {
    const intl = useIntl();

    return (
        <div className={"opportunity"}>
            <Row>
                <Col md={2}>
                    <div className={"logoCont"}>
                        {
                            image ?
                                <img src={image} alt={""}/>
                                :
                                <img src={"/images/empty.svg"} alt={""}/>
                        }
                    </div>
                </Col>
                <Col md={10} className={"position-relative"}>
                    <div className={"title"}>
                        {title}
                    </div>
                    <div className={"desc"}>
                        {desc}
                    </div>
                    <Link to={'/opportunity/edit/' + id}>
                        <img src={"/images/edit-line.svg"} className={"editOportunity"} alt={""}/>
                    </Link>

                    <Row>
                        <Col>
                            <img src={"/images/region.svg"} className={"attrIcon"} alt={""}/>
                            <span className={"attrTitle"}>
                                { intl.formatMessage({id: 'region'})} :
                            </span>{' '}
                            <span className={"attrVal"}>
                                {region}
                            </span>
                        </Col>
                        <Col>
                            <img src={"/images/budget.svg"} className={"attrIcon"} alt={""}/>
                            <span className={"attrTitle"}>
                                { intl.formatMessage({id: 'budget'})} :
                            </span>{' '}
                            <span className={"attrVal"}>
                                {budget + sessionStorage.getItem('currency')}
                            </span>
                        </Col>
                        <Col>
                            <img src={"/images/deadline.svg"} className={"attrIcon"} alt={""}/>
                            <span className={"attrTitle"}>
                                { intl.formatMessage({id: 'deadline'})} :
                            </span>{' '}
                            <span className={"attrVal"}>
                                {deadline}
                            </span>
                        </Col>
                        <Col>
                            <img src={"/images/date.svg"} className={"attrIcon"} alt={""}/>
                            <span className={"attrVal"}>
                                {date}
                            </span>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </div>
    )
}