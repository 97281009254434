import React, {useEffect, useState} from 'react';
import CollaborationCard from "../../Utils/CollaborationCard/CollaborationCard";
import {Row, Col} from "react-bootstrap";
import './style.css'
import Select from "react-select";
import {customSelectStyle, server} from "../../../const";
import ReactPaginate from 'react-paginate';
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function CollaborationAgents() {
    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const [favourite, setFavourite] = useState(false);
    const [requests, setRequests] = useState(false);
    const [visitors, setVisitors] = useState('');

    const [allCompanies, setAllCompanies] = useState(true)
    const [collabs, setCollabs] = useState('')

    const [optionsDomains, setOptionsDomains] = useState([])
    const [optionsCountry, setOptionsCountry] = useState([])

    const [region, setRegion] = useState([])
    const [domanin, setDomanin] = useState([])

    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);


    const optionsVisitors = [
        {value: 0, label:  intl.formatMessage({id: 'visitors_profile'})},
        {value: 1, label: intl.formatMessage({id: 'active_visitors'})},
        {value: 2, label: intl.formatMessage({id: 'all_visitors'})},
    ]

    useEffect(() => {
        const getActivities = () => {

            fetch(server + '/activity', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr = []
                    setOptionsDomains([])
                    json['data'].forEach((opt, i) => {
                            arr.push({
                                value: opt.id,
                                label: opt.activity
                            })
                        }
                    )
                    setOptionsDomains(arr)
                    getCountries()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getCountries = () => {

            fetch(server + '/country', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    setOptionsCountry([])
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.id,
                                label: opt.country
                            })
                        }
                    )
                    setOptionsCountry(arr2)
                    getCollabs()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getCollabs = () => {
            var data = new FormData()
            if (region.value) {
                data.append('region[]', region.value)
            }
            if (domanin.value) {
                data.append('domain[]', domanin.value)
            }
            if (visitors !== '') {
                data.append('type', 3)
                data.append('visitors',visitors)
            } else {
                if (requests) {
                    data.append('type', 2)
                } else if (favourite) {
                    data.append('type', 1)
                } else {
                    data.append('type', 0)
                }
            }

            data.append('page', page)

            fetch(server + '/collaboratorsAgents', {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    setCollabs(json['data'])
                    setMaxPages(json['pages'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getActivities()
        if (maxPages > 0) {
            getCollabs()
        }

    }, [region, domanin, requests, favourite, token, page, maxPages,visitors, intl]);


    return (
        collabs !== '' &&
        <div className={"collaboration"}>
            <Row>
                <Col className={"title"}>
                    {
                        favourite ?
                            intl.formatMessage({id: 'favorite_collaborators'})
                            :
                            requests ?
                        intl.formatMessage({id: 'pending_applications'})
                                :
                                allCompanies ?
                        intl.formatMessage({id: 'collaborators_agents'})
                                    :
                        intl.formatMessage({id: optionsVisitors[visitors].label})

                    }
                </Col>
            </Row>
            <Row>
                <Col className={"col-12 col-md-5 col-lg-4 filters"}>
                    <div className={"item"}>
                        <span>{ intl.formatMessage({id: 'region'})}</span>
                        <Select
                            placeholder={ intl.formatMessage({id: 'select'})}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customSelectStyle}
                            options={optionsCountry}
                            value={region}
                            onChange={(e) => setRegion(e)}
                        />
                    </div>
                </Col>
                <Col className={"col-12 col-md-5 col-lg-4 filters"}>
                    <div className={"item"}>
                        <span>{ intl.formatMessage({id: 'domain'})}</span>
                        <Select
                            placeholder={ intl.formatMessage({id: 'select'})}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customSelectStyle}
                            options={optionsDomains}
                            value={domanin}
                            onChange={(e) => setDomanin(e)}
                        />
                    </div>

                </Col>
                    {
                        sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                            <Col className={"col-12 col-md-5 col-lg-4 filters"}>
                            <div className={"item"}>
                                <span>{ intl.formatMessage({id: 'visitors'})}</span>
                                <Select
                                    onChange={(val) => {
                                        setVisitors(val.value)
                                        setFavourite(false)
                                        setRequests(false)
                                        setAllCompanies(false)
                                        setPage(1)
                                    }}
                                    placeholder={ intl.formatMessage({id: 'select'})}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    styles={customSelectStyle}
                                    options={optionsVisitors}
                                />
                            </div>
                            </Col> :
                            <div></div>

                    }

                    {
                        sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                            <Col className={"col-12 col-md-5 col-lg-4 filters"}>
                            <div className={"item checkbox " + (requests ? "checked" : "")} onClick={() => {
                                setFavourite(false)
                                setRequests(!requests)
                                setAllCompanies(false)
                                setPage(1)
                            }}>
                                {
                                    requests ?
                                        <img src={"/images/check-line2.svg"} alt={""}/>
                                        :
                                        ''
                                }
                                { intl.formatMessage({id: 'pending_applications'})}
                            </div>
                        </Col>

                            :
                            <Col className={"col-12 col-md-5 col-lg-4 filters"}>
                            <div className={"item checkbox " + (allCompanies ? "checked" : "")} onClick={() => {
                                setFavourite(false)
                                setAllCompanies(!allCompanies)
                                setPage(1)
                            }}>
                                {
                                    allCompanies ?
                                        <img src={"/images/check-line2.svg"} alt={""}/>
                                        :
                                        ''
                                }
                                { intl.formatMessage({id: 'all_companies'})}
                            </div>
                        </Col>
                    }
                <Col className={"col-12 col-md-2 col-lg-4 filters"}>
                    <div className={"favourite " + (favourite ? "checked" : "")} onClick={() => {
                        setFavourite(!favourite)
                        setRequests(false)
                        setAllCompanies(false)
                        setPage(1)
                    }}>
                        {
                            favourite ?
                                <img src={"/images/heart-3-line2.svg"} alt={""}/>
                                :
                                <img src={"/images/heart-3-line.svg"} alt={""}/>
                        }
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    {
                        collabs.map((collab, i) => (
                            <CollaborationCard key={i}
                                               id={collab.user_id}
                                               image={collab.logo}
                                               name={collab.name ? collab.name : (collab.first_name +' '+ collab.last_name)}
                                               description={collab.lead_subject ? collab.lead_subject : collab.about}
                                               requestData={collab.lead_id ? collab.lead_id : ''}
                                               request={requests}
                                               deleteCollab={!requests && !favourite}
                                               favourite={favourite}
                                               collabFavorite={collab.favorite}
                                               lead={true}
                                               profilePath={'/profile-job-history/'}
                            />
                        ))
                    }
                </Col>
            </Row>

            {
                maxPages > 0 ?
                    <Row>
                        <Col className={"text-center"}>
                            <ReactPaginate
                                pageCount={maxPages}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={2}
                                onPageChange={(val) => setPage(val.selected + 1)}
                                forcePage={page - 1}
                                initialPage={page - 1}
                                containerClassName={"react-paginate"}
                                previousLabel={<img src={"/images/arrow-left-s-line.svg"} alt={""}/>}
                                nextLabel={<img src={"/images/arrow-right-s-line.svg"} alt={""}/>}
                            />
                        </Col>
                    </Row>
                    :
                    <Row></Row>
            }
        </div>
    )
}