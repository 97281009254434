import React from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import {NavLink} from "react-router-dom";
import {useIntl} from "react-intl";

export default function CompanyMenu({userId}) {
    const intl = useIntl();

    return (
        <div className={"companyMenu"}>
            <Row>
                <Col md={3}>
                    <NavLink to={"/company-about/" + userId}>
                        <div className={"tab"}>
                            {intl.formatMessage({id: 'about_the_company'})}
                        </div>
                    </NavLink>
                </Col>
                <Col md={3}>
                    <NavLink to={"/company-activity/" + userId}>
                        <div className={"tab"}>
                            {intl.formatMessage({id: 'recent_activity'})}
                        </div>
                    </NavLink>
                </Col>
                {
                    userId === sessionStorage.getItem('user_id') &&
                        <Col md={3}>
                            <NavLink to={"/company-files/" + userId}>
                                <div className={"tab"}>
                                    {intl.formatMessage({id: 'files_management'})}
                                </div>
                            </NavLink>
                        </Col>
                }
                {

                    userId === sessionStorage.getItem('user_id') &&
                        <Col md={3}>
                            <NavLink to={"/company-settings/" +userId}>
                                <div className={"tab"}>
                                    {intl.formatMessage({id: 'settings'})}
                                </div>
                            </NavLink>
                        </Col>
                }
            </Row>
        </div>
    )
}