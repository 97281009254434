import React from 'react';
import './style.css';
import {server_path_files} from "../../../const";

export default function DiscussionItem({image,name, lastMessage,active, unread}) {


    return (
        <div className={"discussionItem " + (active ? 'active ': ' ') + (unread ? 'unread ': ' ')}>
            <div className={"image"}>
                <img src={image ? (server_path_files + image) : ''} alt={""}/>
            </div>
            <div className={"d-inline-flex"}>
                <div className={"d-block"}>
                    <div className={"name"}>
                        {name}
                    </div>
                    <div className={"lastMessage"}>
                        {lastMessage}
                    </div>
                </div>
            </div>
        </div>
    )
}