import React, {useEffect, useState} from 'react';
import './style.css';
import {Col, Row} from "react-bootstrap";
import {customSelectStyle, server} from "../../../const";
import {toast} from "react-toastify";
import ReactPaginate from "react-paginate";
import moment from 'moment'
import ViewOpportunityCard from "../../Utils/ViewOpportunityCard/ViewOpportunityCard";
import Select from "react-select";
import {useIntl} from "react-intl";

export default function AgentLeadsCompany() {

    const intl = useIntl();
    const token = sessionStorage.getItem('token')

    const [optionsDomains, setOptionsDomains] = useState('')
    const [optionsCountry, setOptionsCountry] = useState('')

    const [activity, setActivity] = useState('');
    const [region, setRegion] = useState('');

    const [leads, setLeads] = useState('')

    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);
    
    
    
    useEffect(() => {
        const getData = () => {
            fetch(server + '/leads/company?page=' + page, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    json['data'].forEach((opp,i) => {
                        optionsCountry.forEach((item,j) => {
                            if (item.value === parseInt(opp['region'])){
                                json['data'][i]['region'] = item.label
                            }
                        })
                        json['data'][i]['domain'] = ''
                        optionsDomains.forEach((item,j) => {

                            opp['company']['domains'].forEach((optDom, k) => {
                                if (item.value === parseInt(optDom['id'])){
                                    if (json['data'][i]['domain'] !== ''){
                                        json['data'][i]['domain'] += ', '
                                    }
                                    json['data'][i]['domain'] += item.label
                                }
                            })

                        })
                    })
                    
                    setLeads(json['data'])
                    setMaxPages(json['pages'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        const getCountries = () => {

            fetch(server + '/country', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.id,
                                label: opt.country
                            })
                        }
                    )
                    setOptionsCountry(arr2)
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        
        const getActivities = () => {

            fetch(server + '/activity', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr = []
                    json['data'].forEach((opt, i) => {
                            arr.push({
                                value: opt.id,
                                label: opt.activity
                            })
                        }
                    )
                    setOptionsDomains(arr)
                    getCountries()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        
        if (optionsCountry.length === 0 && optionsDomains.length === 0){
            getActivities()
        }
        
        if (maxPages > 0 && optionsCountry.length !== 0 && optionsDomains.length !== 0) {
            getData()
        }
    }, [page,maxPages,token,optionsCountry,optionsDomains, intl]);


    return (
        sessionStorage.getItem('profileType') !== 'user' &&
        <div className={"leads"}>
            <Row>
                <Col>
                    <div className={"mainTitle"}>
                        <div>
                            <h2>
                                {intl.formatMessage({id: 'leads'})}
                            </h2>
                        </div>
                        <div></div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <div className={"filters"}>
                            <div className={"item"}>
                                <span>
                                {intl.formatMessage({id: 'region'})}</span>
                                <Select
                                    placeholder={ intl.formatMessage({id: 'select'})}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    styles={customSelectStyle}
                                    options={optionsCountry}
                                    value={region}
                                    onChange={(e) => setRegion(e)}
                                />
                            </div>
                            <div className={"item"}>
                                <span>
                                {intl.formatMessage({id: 'leads'})}</span>
                                <Select
                                    placeholder={ intl.formatMessage({id: 'select'})}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                    styles={customSelectStyle}
                                    options={optionsDomains}
                                    value={activity}
                                    onChange={(e) => setActivity(e)}
                                />
                            </div>

                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"list"}>
                        {
                            leads !== '' && leads.map((lead, i) => (
                                <ViewOpportunityCard key={i}
                                                     id={lead.id}
                                                     type={5}
                                                     image={lead.company.logo}
                                                     company={lead.company.name}
                                                     userId={lead.company.user_id}
                                                     subject={lead.subject}
                                                     date={moment(lead.deadline).format('YYYY-MM-DD')}
                                                     description={lead.about}
                                                     region={lead.region}
                                                     domain={lead.domain}
                                                     paid={lead.paid}
                                                     access={lead.access}
                                                     lead_type={1}
                                />
                            ))
                        }
                    </div>
                </Col>
            </Row>

            {
                maxPages > 0 ?
                    <Row>
                        <Col className={"text-center"}>
                            <ReactPaginate
                                pageCount={maxPages}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={2}
                                onPageChange={(val) => setPage(val.selected + 1)}
                                forcePage={page - 1}
                                initialPage={page - 1}
                                containerClassName={"react-paginate"}
                                previousLabel={<img src={"/images/arrow-left-s-line.svg"} alt={""}/>}
                                nextLabel={<img src={"/images/arrow-right-s-line.svg"} alt={""}/>}
                            />
                        </Col>
                    </Row>
                    :
                    <Row></Row>
            }

        </div>

    )
}