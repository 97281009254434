//export const server = 'http://backend-bucharest.tmstartupstudio.com/public/api';
//export const server_path_files = 'http://backend-bucharest.tmstartupstudio.com/public';

export const server_path_files = 'https://clossers.com/backend/public';
export const server = 'https://clossers.com/backend/public/api';


export const customSelectStyle = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'rgba(65, 21, 224, 0.1);' : state.isFocused ? 'rgba(65, 21, 224, 0.05);' : null,
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'Montserrat',
        color: state.isSelected ? 'rgba(65, 21, 224, 1)': 'rgba(80, 81, 97, 1)',
        ':active': {
            backgroundColor: 'rgba(65, 21, 224, 0.05);',
        },
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 1000
    }),
    container: () => ({
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        border: '0',
        boxShadow: '0'

    }),
    valueContainer: (provided, state) => ({
        ...provided,
        height: '100%',
        margin: '20px 0 0 4px ',
        position: 'relative'

    }),
    placeholder: (provided, state) => ({
        ...provided,
        top: '0',
        transform: '0',
        margin: '4px 0 0 4px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'Montserrat',
        color: 'black'

}),
    singleValue: (provided, state) => ({
        ...provided,
        top: '0',
        transform: '0',
        padding: '0',
        margin: '4px 0 0 4px',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'Montserrat',
        color: 'black'
        

}),
    input: (provided, state) => ({
        ...provided,
        top: '0',
        position: 'absolute',
        padding: '0',
        margin: '0'

}),
indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none!important'

}),
    indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%'

})}

export const readableMonths = [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie'
]

