import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import {Link} from "react-router-dom";
import Select from "react-select";
import {customSelectStyle, server} from "../../const";
import ViewOpportunityCard from "../Utils/ViewOpportunityCard/ViewOpportunityCard";
import {toast} from "react-toastify";
import moment from 'moment'
import ReactPaginate from "react-paginate";
import {useIntl} from "react-intl";

export default function ViewOpportunities({type}) {


    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const activities_filtre = JSON.parse(sessionStorage.getItem('activities_filtre'))
    const countries_filtre = JSON.parse(sessionStorage.getItem('countries_filtre'))

    const [optionsDomains, setOptionsDomains] = useState('')
    const [optionsCountry, setOptionsCountry] = useState('')

    const [activity, setActivity] = useState('');
    const [region, setRegion] = useState('');
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(1);

    const [opportunities, setOpportunities] = useState('');


    const typeOps = [
        intl.formatMessage({id: 'private_opportunities'}),
        intl.formatMessage({id: 'public_opportunities'}),
        intl.formatMessage({id: 'financing'}),
        intl.formatMessage({id: 'auction'}),
        intl.formatMessage({id: 'opportunities_for_agents'})
    ]

    const typeBtn = [
        intl.formatMessage({id: 'opportunities_first_lowercase'}),
        intl.formatMessage({id: 'opportunities_first_lowercase'}),
        intl.formatMessage({id: 'funding_first_lowercase'}),
        intl.formatMessage({id: 'auction_first_lowercase'}),
        intl.formatMessage({id: 'opportunities_for_agents_first_lowercase'})
    ]

    const setFilters = () => {
        if (activities_filtre.length !== 0 && countries_filtre.length !== 0) {
            let arr3 = []
            activities_filtre.forEach((act, i) => {
                    arr3.push({
                        value: act.id,
                        label: act.activity
                    })

                }
            )
            setActivity(arr3)
            arr3 = []
            countries_filtre.forEach((act, i) => {
                    arr3.push({
                        value: act.id,
                        label: act.country
                    })

                }
            )
            setRegion(arr3)
        } else {
            setActivity([])
            setRegion([])
        }
    }

    useEffect(() => {
        const getActivities = () => {

            fetch(server + '/activity', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr = []
                    json['data'].forEach((opt, i) => {
                            arr.push({
                                value: opt.id,
                                label: opt.activity
                            })

                        }
                    )
                    setMaxPages(json['pages'])
                    setOptionsDomains(arr)
                    getCountries()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        const getCountries = () => {

            fetch(server + '/country', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.id,
                                label: opt.country
                            })
                        }
                    )
                    setOptionsCountry(arr2)

                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        const getOpportunities = () => {

            if (optionsDomains.length === 0) {
                console.log('waiting')
                return;
            }

            fetch(server + '/opportunity/browse', {
                method: 'POST',
                body: JSON.stringify({
                    'region': region,
                    'domain': activity,
                    'type': type,
                    'page': page,

                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    json['data'].forEach((opp, i) => {
                        optionsCountry.forEach((item, j) => {
                            if (item.value === parseInt(opp['region'])) {
                                json['data'][i]['region'] = item.label
                            }
                        })
                        json['data'][i]['domain'] = ''
                        optionsDomains.forEach((item, j) => {

                            opp['company']['domains'].forEach((optDom, k) => {
                                if (item.value === parseInt(optDom['id'])) {
                                    if (json['data'][i]['domain'] !== '') {
                                        json['data'][i]['domain'] += ', '
                                    }
                                    json['data'][i]['domain'] += item.label
                                }
                            })

                        })
                    })
                    setMaxPages(json['pages'])
                    setOpportunities(json['data'])
                } else {
                    if (json['error'])
                        toast.warning(json['error'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }


        if (optionsDomains.length === 0 && optionsCountry.length === 0 && activity === '' && region === '') {
            setPage(1)

            if (activities_filtre.length !== 0 && countries_filtre.length !== 0) {
                let arr3 = []
                activities_filtre.forEach((act, i) => {
                        arr3.push({
                            value: act.id,
                            label: act.activity
                        })

                    }
                )
                setActivity(arr3)
                arr3 = []
                countries_filtre.forEach((act, i) => {
                        arr3.push({
                            value: act.id,
                            label: act.country
                        })

                    }
                )
                setRegion(arr3)
            } else {
                setActivity([])
                setRegion([])
            }

            getActivities()
        }

        if (optionsDomains.length !== 0 && optionsCountry.length !== 0 && activity !== '' && region !== '') {
            getOpportunities()
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsDomains, optionsCountry, type, token, page, activity, region]);


    return (
        opportunities !== '' &&
        <div className={"viewOps"}>
            <Row>
                <Col className={"maintitle"}>
                    <div>
                        {
                            sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                                typeOps[type]
                                :
                                intl.formatMessage({id: 'opportunities'})
                        }
                    </div>
                    {
                        sessionStorage.getItem('profileType') === 'admin' ?

                            <div>
                                <Link to={'/opportunity/create/' + type}>
                                    <Button variant={"primary"}>
                                        <img src={"/images/add-circle-line.svg"} alt={""}/>
                                        {intl.formatMessage({id: 'create'})} {typeBtn[type]}
                                    </Button>
                                </Link>
                            </div>
                            :
                            <div></div>
                    }

                </Col>
            </Row>
            <Row>
                <Col className={"col-12 col-md-3 filters"}>
                    <div className={"item select"}>
                        <span>{intl.formatMessage({id: 'region'})}</span>
                        <Select
                            isMulti
                            placeholder={intl.formatMessage({id: 'select'})}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customSelectStyle}
                            options={optionsCountry}
                            value={region}
                            onChange={(e) => setRegion(e)}
                        />
                    </div>
                </Col>
                <Col className={"col-12 col-md-3 filters"}>
                    <div className={"item select"}>
                        <span>{intl.formatMessage({id: 'domain'})}</span>
                        <Select
                            isMulti
                            placeholder={intl.formatMessage({id: 'select'})}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customSelectStyle}
                            options={optionsDomains}
                            value={activity}
                            onChange={(e) => setActivity(e)}
                        />
                    </div>
                </Col>
                {
                    sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                        <Col className={"col-12 col-md-3 filters"}>
                            <Link to={'/view-opportunities/0'} onClick={() => setPage(1)}>
                                <div className={"item checkbox " + (type === 0 ? "checked" : "")} onClick={() => {
                                }}>
                                    {
                                        type === 0 ?
                                            <img src={"/images/check-line2.svg"} alt={""}/>
                                            :
                                            ''
                                    }
                                    {intl.formatMessage({id: 'private_opportunities'})}
                                </div>
                            </Link>
                        </Col>
                        : ''
                }
                {
                    sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                        <Col className={"col-12 col-md-3 filters"}>
                            <Link to={'/view-opportunities/1'} onClick={() => setPage(1)}>
                                <div className={"item checkbox " + (type === 1 ? "checked" : "")} onClick={() => {
                                }}>
                                    {
                                        type === 1 ?
                                            <img src={"/images/check-line2.svg"} alt={""}/>
                                            :
                                            ''
                                    }
                                    {intl.formatMessage({id: 'public_opportunities'})}
                                </div>
                            </Link>
                        </Col>
                        : ''
                }{sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                <Col className={"col-12 col-md-3 filters"}>
                    <Link to={'/view-opportunities/2'} onClick={() => setPage(1)}>
                        <div className={"item checkbox " + (type === 2 ? "checked" : "")} onClick={() => {
                        }}>
                            {
                                type === 2 ?
                                    <img src={"/images/check-line2.svg"} alt={""}/>
                                    :
                                    ''
                            }
                            {intl.formatMessage({id: 'financing'})}
                        </div>
                    </Link>
                </Col>
                : ''
            }
                {sessionStorage.getItem('profileType') === 'admin' || sessionStorage.getItem('profileType') === 'company' ?
                    <Col className={"col-12 col-md-3 filters"}>
                        <Link to={'/view-opportunities/3'} onClick={() => setPage(1)}>
                            <div className={"item checkbox " + (type === 3 ? "checked" : "")} onClick={() => {
                            }}>
                                {
                                    type === 3 ?
                                        <img src={"/images/check-line2.svg"} alt={""}/>
                                        :
                                        ''
                                }
                                {intl.formatMessage({id: 'auction'})}
                            </div>
                        </Link>
                    </Col>
                    : ''
                }
                {
                    sessionStorage.getItem('profileType') === 'admin' &&
                    <Col className={"col-12 col-md-3 filters"}>
                        <Link to={'/view-opportunities/4'} onClick={() => setPage(1)}>
                            <div className={"item checkbox " + (type === 4 ? "checked" : "")} onClick={() => {
                            }}>
                                {
                                    type === 4 ?
                                        <img src={"/images/check-line2.svg"} alt={""}/>
                                        :
                                        ''
                                }
                                {intl.formatMessage({id: 'opportunities_for_agents'})}
                            </div>
                        </Link>
                    </Col>
                }

                <Col className={"col-12 col-md-3 filters"}>
                    <Button className={"setFilters"} variant={"secondary"} onClick={() => setFilters()}>
                        {intl.formatMessage({id: 'interest_filters'})}
                    </Button>


                </Col>
            </Row>
            <Row>
                <Col>

                    {
                        opportunities.map((opportunity, i) => (

                            <ViewOpportunityCard key={i}
                                                 id={opportunity.id}
                                                 type={type}
                                                 image={opportunity.company.logo}
                                                 company={opportunity.company.name}
                                                 userId={opportunity.company.user_id}
                                                 subject={opportunity.subject}
                                                 date={moment(opportunity.deadline).format('YYYY-MM-DD')}
                                                 description={opportunity.about}
                                                 region={opportunity.region}
                                                 domain={opportunity.domain}
                                                 paid={opportunity.paid}
                                                 access={opportunity.access}
                            />
                        ))
                    }
                </Col>
            </Row>
            {
                maxPages > 0 ?
                    <Row>
                        <Col className={"text-center"}>
                            <ReactPaginate
                                pageCount={maxPages}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={2}
                                onPageChange={(val) => setPage(val.selected + 1)}
                                forcePage={page - 1}
                                initialPage={page - 1}
                                containerClassName={"react-paginate"}
                                previousLabel={<img src={"/images/arrow-left-s-line.svg"} alt={""}/>}
                                nextLabel={<img src={"/images/arrow-right-s-line.svg"} alt={""}/>}
                            />
                        </Col>
                    </Row>
                    :
                    <Row></Row>
            }
        </div>
    )
}