import React, {useEffect, useState} from 'react';
import './style.css';
import Leads2 from "../../Utils/Leads2/Leads2";
import {Col, Row} from "react-bootstrap";
//import {Link} from "react-router-dom"; (also removed button from line 4 {Col, Row, Button})
import {server} from "../../../const";
import {toast} from "react-toastify";
import moment from 'moment'
import ReactPaginate from "react-paginate";
import {useIntl} from "react-intl";

export default function Opportunity() {


    const intl = useIntl();
    const token = sessionStorage.getItem('token')
    const [opportunities ,setOpportunities] = useState([])

    const [page,setPage] = useState(1);
    const [maxPages,setMaxPages] = useState(1);

    useEffect(() => {
        const getData = () => {
            fetch(server + '/opportunity/show_mine?page=' + page, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if(!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setOpportunities(json['data'])
                    setMaxPages(json['pages'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        if (maxPages > 0){
            getData()
        }
    }, [page,maxPages,token, intl])


    
    return (

        <div className={"myOpportunity"}>
            <Row className={"m-0"}>
                <Col className={"title"}>
                    <div>
                        <h2>
                            {intl.formatMessage({id: 'my_opportunities'})}
                        </h2>
                    </div>

                    <div>
{/*
                        <Link to={'/opportunity/create'}>
                            <Button variant={"primary"}>
                                <img src={"/images/add-circle-line.svg"} alt={""}/>
                                {intl.formatMessage({id: 'create_opportunity'})}
                            </Button>
                        </Link>*/}
                    </div>
                </Col>
            </Row>
            <Row className={"m-0"}>
                <Col>
                    {
                        opportunities.map((opportunity, i) => (
                            <Leads2
                                key={i}
                                id={opportunity.id}
                                title={opportunity.subject}
                                decription={opportunity.about}
                                region={opportunity.region}
                                budget={opportunity.budget}
                                dateStart={moment(opportunity.created_at).format('YYYY-MM-DD')}
                                dateEnd={moment(opportunity.deadline).format('YYYY-MM-DD')}
                                leads={false}
                                expired={opportunity.expired}
                                completed={opportunity.completed}
                            />
                        ))
                    }
                </Col>
            </Row>

            {
                maxPages > 0 ?
                    <Row>
                        <Col className={"text-center"}>
                            <ReactPaginate
                                pageCount={maxPages}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={2}
                                onPageChange={(val) => setPage(val.selected + 1)}
                                forcePage={page-1}
                                initialPage={page-1}
                                containerClassName={"react-paginate"}
                                previousLabel={<img src={"/images/arrow-left-s-line.svg"} alt={""}/>}
                                nextLabel={<img src={"/images/arrow-right-s-line.svg"} alt={""}/>}
                            />
                        </Col>
                    </Row>
                    :
                    <Row></Row>
            }


        </div>
    )
}