import React from 'react';
import './style.css';

export default function DateSelector({
                                         value,
                                         increment,
                                         decrement}) {

    
    return (
        <div className={"dateSelector"}>
            <div className={"action"} onClick={() => decrement()}>
                <img src={"/images/chevron-left.svg"} alt={""}/>
            </div>
            <div className={"value"}>
                {value}
            </div>
            <div className={"action"} onClick={() => increment()}>
                <img src={"/images/chevron-right.svg"} alt={""}/>
            </div>
            
        </div>
    )
}