import React from 'react';
import LeadOpportunityCreate from "../../Utils/LeadOpportunityCreate/LeadOpportunityCreate";
export default function LeadsCreate() {



    return (
        <div>
            <LeadOpportunityCreate
                opportunity={5}
            />
        </div>
    )
}