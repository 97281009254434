import React from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import {NavLink} from "react-router-dom";
import {useIntl} from "react-intl";

export default function ProfileUserMenu({userId}) {
    const intl = useIntl();

    return (
        <div className={"profileUserMenu"}>
            <Row>
                <Col md={3}>
                    <NavLink to={"/profile-job-history/" + userId}>
                        <div className={"tab"}>
                            {intl.formatMessage({id: 'biography'})}
                        </div>
                    </NavLink>
                </Col>
                <Col md={3}>
                    <NavLink to={"/profile-experience/" + userId}>
                        <div className={"tab"}>
                            {intl.formatMessage({id: 'the_experience'})}
                        </div>
                    </NavLink>
                </Col>
               
                {

                    userId === sessionStorage.getItem('user_id') && 
                    <Col md={3}>
                            <NavLink to={"/profile-settings/" +userId}>
                                <div className={"tab"}>
                                    {intl.formatMessage({id: 'settings'})}
                                </div>
                            </NavLink>
                        </Col>
                }
                
            </Row>
        </div>
    )
}