import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import {server} from "../../const";
import {toast} from "react-toastify";
import {confirmAlert} from "react-confirm-alert";
import {useIntl} from "react-intl";
export default function Packages() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const [activePackage,setActivePackage] = useState('')
    
    const packages = {
        free: [
            {name: 'create_user_profile', access: 1,},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_10_companies_and_10_agents_who_visited_the_profile', access: 1, now:activePackage['visitors_companies'],now2:activePackage['visitors_agents']},
            {name: 'visible_10_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'access_financial_activity', access: 1},
            {name: 'access_leads', access: 1},
            {name: 'add_a_maximum_of_3_opportunities', access: 1, now:activePackage['add_opportunities']},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_125_messages', access: 1, now:activePackage['messages']},
            {name: 'access_to_5_private_opportunities', access: 1,now: activePackage['views_opportunities']},
            {name: 'access_to_3_public_opportunities', access: 1, now: activePackage['views_public_opportunities']},
            {name: 'upload_1_file', access: 1, now: activePackage['upload_files']},
            {name: 'access_financing', access: 0},
            {name: 'access_auctions', access: 0},
            {name: 'upload_video', access: 0},
        ],
        basic: [
            {name: 'create_user_profile', access: 1},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_25_companies_and_25_agents_who_visited_the_profile', access: 1, now:activePackage['visitors_companies'],now2:activePackage['visitors_agents']},
            {name: 'visible_50_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'access_financial_activity', access: 1},
            {name: 'access_leads', access: 1},
            {name: 'add_a_maximum_of_10_opportunities', access: 1, now:activePackage['add_opportunities']},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_250_messages', access: 1, now:activePackage['messages']},
            {name: 'access_to_25_private_opportunities', access: 1,now: activePackage['views_opportunities']},
            {name: 'access_to_25_public_opportunities', access: 1, now: activePackage['views_public_opportunities']},
            {name: 'unlimited_file_upload', access: 1},
            {name: 'access_financing', access: 0},
            {name: 'access_auctions', access: 0},
            {name: 'upload_video', access: 0},],
        plus: [
            {name: 'create_user_profile', access: 1},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_25_companies_and_25_agents_who_visited_the_profile', access: 1},
            {name: 'visible_50_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'access_financial_activity', access: 1},
            {name: 'access_leads', access: 1},
            {name: 'add_a_maximum_of_10_opportunities', access: 1, now:activePackage['add_opportunities']},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_500_messages', access: 1, now:activePackage['messages']},
            {name: 'access_to_private_opportunities', access: 1,now: activePackage['views_opportunities']},
            {name: 'access_to_3_public_opportunities', access: 1},
            {name: 'unlimited_file_upload', access: 1},
            {name: 'access_financing', access: 1},
            {name: 'access_auctions', access: 0},
            {name: 'upload_video', access: 0},],
        premium: [
            {name: 'create_user_profile', access: 1},
            {name: 'full_dashboard', access: 1},
            {name: 'visible_25_companies_and_25_agents_who_visited_the_profile', access: 1, now:activePackage['visitors_companies'],now2:activePackage['visitors_agents']},
            {name: 'visible_50_companies', access: 1, now: activePackage['views_profiles']},
            {name: 'access_financial_activity', access: 1},
            {name: 'access_leads', access: 1},
            {name: 'add_a_maximum_of_10_opportunities', access: 1, now:activePackage['add_opportunities']},
            {name: 'access_collaborations', access: 1},
            {name: 'access_contracts', access: 1},
            {name: 'inbox_only_5000_messages', access: 1, now:activePackage['messages']},
            {name: 'access_to_private_opportunities', access: 1,now: activePackage['views_opportunities']},
            {name: 'access_to_3_public_opportunities', access: 1},
            {name: 'unlimited_file_upload', access: 1},
            {name: 'access_financing', access: 1},
            {name: 'access_auctions', access: 0},
            {name: 'upload_video', access: 0},]
    }

    const confirmNewPayment = (package_type,amount) => {
        confirmAlert({
            title: intl.formatMessage({id: 'do_you_want_the_subscription_to_be_extended_automatically'}),
            buttons: [
                {
                    label:intl.formatMessage({id: 'yes'}),
                    onClick:() => addNewPayment(package_type,amount,1)
                },
                {
                    label: intl.formatMessage({id: 'nu'}),
                    onClick:() => addNewPayment(package_type,amount,0)
                }
            ]
        });
    };
    
    useEffect(() => {
        const getActivePackage = () => {
            fetch(server + '/get_active_package' , {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']) {
                    setActivePackage(json['data'])

                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getActivePackage()
    },[token, intl])
    
    const addNewPayment = (package_type,amount,reccuring) => {

        var data = new FormData()
        data.append('package_type', package_type)
        data.append('amount', amount)
        data.append('reccuring', reccuring)
        fetch(server + '/new_payment', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
                var stripe = window.Stripe(json['publishable_key_stripe']);
                stripe.redirectToCheckout({
                    sessionId: json['data']
                }).then(function (result) {
                    // If `redirectToCheckout` fails due to a browser or network
                    // error, display the localized error message to your customer
                    // using `result.error.message`.
                });
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
    
    return (
        <div className={"packages"}>
            <div className={"mainTitle"}>
                {intl.formatMessage({id: 'welcome'})} !
            </div>
            <p>
                {intl.formatMessage({id: 'we_are_really_happy_to_see_you_here'})} !
            </p>

            <div className={"mainTitle"}>
                {intl.formatMessage({id: 'your_plan_your_choice'})}
            </div>
            <p className={"mainDesc"}>
            </p>
            
            <Row>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 0 && 'active')}>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'FREE_FOR_LIFETIME_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.free.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {intl.formatMessage({id: item.name}) +(activePackage['type'] === 0 && item.now !== undefined  ?
                                            (' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }   
                        </div>

                        {
                            activePackage['type'] !== 0 ?
                                <Button variant={"primary"} onClick={() => window.location.href = '/'}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                                <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                    </div>
                    
                </Col>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 1 && 'active')}>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'BASIC_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.basic.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {intl.formatMessage({id: item.name}) +(activePackage['type'] === 1 && item.now !== undefined  ?
                                            (' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }   
                        </div>

                        {
                            activePackage['type'] !== 1 ?
                                <Button variant={"primary"} onClick={() => confirmNewPayment(1,5)}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                                <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                    </div>
                    
                </Col>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 2 && 'active')}>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'PLUS_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.plus.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {intl.formatMessage({id: item.name}) +(activePackage['type'] === 2 && item.now !== undefined  ?
                                            (' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }

                           
                        </div>
                        {
                            activePackage['type'] !== 2 ?
                                <Button variant={"primary"} onClick={() => confirmNewPayment(2,10)}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                                <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                    </div>
                    
                </Col>
                <Col md={3}>
                    <div className={"secondContainer " + (activePackage['type'] === 3 && 'active')}>
                        <div className={"title"}>
                            {intl.formatMessage({id: 'PREMIUM_PACKAGE'})}
                        </div>
                        <div className={"packageList"}>
                            {
                                packages.premium.map((item,i) => (
                                    <div key={i} className={"text-align-left"}>
                                        <img src={parseInt(item.access) === parseInt(1) ? "/images/active.svg" : "/images/inactive.svg"} alt={""}/>
                                        {intl.formatMessage({id: item.name}) +(activePackage['type'] === 3 && item.now !== undefined  ?
                                            (' (' + intl.formatMessage({id: 'available'}) + ': ' + item.now +(item.now2 ? (', ' + item.now2 + ')') : ')')):'')}
                                    </div>
                                ))
                            }   
                        </div>

                        {
                            activePackage['type'] !== 3 ?
                                <Button variant={"primary"} onClick={() => confirmNewPayment(3,15)}>{intl.formatMessage({id: 'start'})}</Button>
                                :
                                <Button variant={"primary"}>{intl.formatMessage({id: 'active'})}</Button>
                        }
                    </div>
                    
                </Col>
            </Row>
        </div>


    )
}