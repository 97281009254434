import React, {useEffect, useState} from 'react';
import {
    Switch,
    Route, useParams, Link
} from "react-router-dom";
import CompanyMenu from "./CompanyMenu/CompanyMenu";
import CompanyAbout from "./CompanyAbout/CompanyAbout";
import CompanyActivity from "./CompanyActivity/CompanyActivity";
import CompanyFiles from "./CompanyFiles/CompanyFiles";
import CompanySetting from "./CompanySetting/CompanySetting";
import {Button, Col, Row} from "react-bootstrap";
import {server, server_path_files} from "../../const";
import ReactPlayer from "react-player";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function Company() {


    const id = useParams().id
    const intl = useIntl();

    const [viewVideo,setViewVideo] = useState(false);

    const token = sessionStorage.getItem('token')

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [about, setAbout] = useState("");
    const [presentation, setPrezentation] = useState("");
    const [link, setLink] = useState("");
    const [facebook, setFacebook] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [logoFile, setLogoFile] = useState([]);

    useEffect( () => {
        const getCompanyInfo = () => {
            fetch(server + '/company/' + id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if(json['name']) {
                    setName(json['name'])
                    setEmail(json['email'])
                    setPhone(json['phone'])
                    setAddress(json['address'])
                    setAbout(json['about'])
                    setLink(json['link'])
                    setFacebook(json['facebook'])
                    setLinkedin(json['linkedin'])
                    setPrezentation(json['presentation'])
                    setLogoFile(json['logo'])
                } else {
                    if (json['error'])
                        toast.warning(json['error'])
                }
            }).catch(e => {
                // toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        getCompanyInfo()
    },[token,id] );



    return (
        name &&
        <div>
            <div className={"companyHeader"}>
                <Row>
                    <Col md={2} className={"text-center"}>
                        <div className={"logoCont"}>
                            <img src={logoFile ? (server_path_files + logoFile) : ''} alt={""}/>
                        </div>
                        <div className={"companyTitle"}>
                            {name}
                        </div>
                        {/* <div className={"companyDomain"}>
                        {activity}
                    </div>*/}
                    </Col>
                    <Col md={7} className={"position-relative"}>
                        <h2>
                            {intl.formatMessage({id: 'about_us'})}
                        </h2>
                        <div className={"companyDesc"}>
                            {about}
                        </div>
                        <div>
                            <Link onClick={() => window.open(presentation ? (server_path_files + presentation) : '')}>
                                <Button variant="primary" className={"mr-4"} >
                                    <img src={"/images/download.svg"} className={"btn-icon"} alt={""}/>
                                    {intl.formatMessage({id: 'download_presentation'})}</Button>
                            </Link>
                            <Button variant="secondary" onClick={() => setViewVideo(true)}>
                                <img src={"/images/play-video.svg"} className={"btn-icon"} alt={""}/>
                                {intl.formatMessage({id: 'watch_the_video'})}
                            </Button>

                        </div>
                        <div className={"separator"}>

                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={"contactInfo"}>
                            <div className={"element"}>
                                {intl.formatMessage({id: 'contact_person'})}
                            </div>
                            <div className={"element"}>
                                <a href={"tel:+40" + phone}>
                                    <img src={"/images/phone.svg"} className={"icon"} alt={""}/>
                                    {phone}
                                </a>
                            </div>
                            <div className={"element"}>
                                <a href={"mailto:" + email}>
                                    <img src={"/images/email.svg"} className={"icon"} alt={""}/>
                                    {email}
                                </a>
                            </div>
                            <div className={"element"}>
                                <img src={"/images/location.svg"} className={"icon"} alt={""}/>
                                {address}
                            </div>
                            <div className={"element links"}>
                                <Link onClick={() => window.open(facebook)}>
                                    <img src={"/images/facebook.jpg"} className={"icon"} alt={""}/>
                                </Link>
                                <Link onClick={() => window.open(linkedin)}>
                                    <img src={"/images/linkedin.png"} className={"icon linkedin"} alt={""}/>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className={"video " + (viewVideo ? "d-flex" : "d-none")}>
                    <Col>
                        <div className={"videoPr"}>
                            
                            <ReactPlayer
                                url={link}
                                controls={true}
                                volume={viewVideo? 1 : 0}
                            />
                            <button onClick={() => setViewVideo(false)} className={"close"}>
                            x
                            </button>
                        </div>

                    </Col>
                </Row>
            </div>
            <CompanyMenu
            userId={id}
            />
            <div className={"tabContent"}>
                <Switch>
                    <Route path="/company-about/:id">
                        <CompanyAbout/>
                    </Route>
                    <Route path="/company-activity/:id" >
                        <CompanyActivity/>
                    </Route>
                    <Route path="/company-files/:id" >
                        <CompanyFiles/>
                    </Route>
                    <Route path="/company-settings/:id" >
                        <CompanySetting/>
                    </Route>
                </Switch>
            </div>
            
            
        </div>
    )
}