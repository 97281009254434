import React, {useEffect, useMemo, useState} from 'react';
import {Button, Row, Col} from "react-bootstrap";
import './style.css';
import Select from 'react-select'
import {customSelectStyle, server, server_path_files} from "../../../const";
import {useDropzone} from 'react-dropzone';
import {toast} from "react-toastify";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

export default function ProfileUserSettings() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const user_id = useParams().id

    const [optionsDomains, setOptionsDomains] = useState([])
    const [optionsCountry, setOptionsCountry] = useState([])
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [about, setAbout] = useState("");
    const [activity, setActivity] = useState([]);
    const [country, setCountry] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [commission, setCommission] = useState('');
    const [target, setTarget] = useState('');
    const [activityFiltre, setActivityFiltre] = useState("");
    const [countryFiltre, setCountryFiltre] = useState("");
    const [logoFile, setLogoFile] = useState([]);
    const [logoFileDb, setLogoFileDb] = useState([]);

    const [collabName, setCollabName] = useState('')
    const [collabDateStart, setCollabDateStart] = useState('')
    const [collabDateEnd, setCollabDateEnd] = useState('')
    const [collabBudged, setCollabBudged] = useState('')
    const [collabCommission, setCollabCommission] = useState('')

    const {getRootProps: getRootPropsFiles, getInputProps: getInputPropsFiles} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            let newFile = acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }));

            setLogoFile(newFile);
        }
    });

    const optionsCurrency = useMemo( () => {
       return [{value: 0,
           label: 'RON'}, {
           value: 1,
           label: 'EURO'}]; 
    }, [])
    const deleteFile = (files, setFiles, i) => {
        setFiles([])
    }

    const getThumbs = (files, setFiles) => {
        return (
            <div>
                {
                    files.map((file, i) => (
                        <div className={"thumb"} key={i}>
                            <div className={"droppedImage"}>

                                <img
                                    src={file.preview} alt={""}
                                />

                                <img
                                    src={"/images/delete.svg"}
                                    className={"deleteIcon"}
                                    onClick={() => deleteFile(files, setFiles, i)}
                                    alt={""}
                                />

                            </div>
                        </div>
                    ))
                }

            </div>
        )
    }


    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        logoFile.forEach(file => URL.revokeObjectURL(file.preview));
    }, [logoFile]);


    useEffect(() => {
        const getAgentInfo = () => {

            if (optionsDomains.length === 0 || optionsCountry.length === 0) {
                console.log('waiting')
                return;
            }

            fetch(server + '/agent/' + user_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }

            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']['email']) {
                    setFirstName(json['data']['first_name'])
                    setLastName(json['data']['last_name'])
                    setEmail(json['data']['email'])
                    setPhone(json['data']['phone'])
                    setAbout(json['data']['about'])
                    setCommission(json['data']['commission'])
                    setTarget(json['data']['target'])
                    setActivity([])
                    for (const currency_opt of optionsCurrency) {
                        if (currency_opt.value === json['data']['currency']) {
                            setCurrency({
                                value: currency_opt.value,
                                label: currency_opt.label
                            })
                        }
                    }

                    let arr = []
                    for (const act of json['data']['activity']) {
                        for (const dom of optionsDomains) {
                            if (dom.value === act) {
                                arr.push({
                                    value: dom.value,
                                    label: dom.label
                                })
                            }
                        }
                    }
                    setActivity(arr)

                    setCountry([])
                    arr = []
                    for (const act of json['data']['countries']) {
                        for (const co of optionsCountry) {
                            if (co.value === act) {
                                arr.push({
                                    value: co.value,
                                    label: co.label
                                })
                            }
                        }
                    }
                    setCountry(arr)

                    setActivityFiltre([])
                    arr = []
                    for (const act of json['data']['activity_filtre']) {
                        for (const dom of optionsDomains) {
                            if (dom.value === act) {
                                arr.push({
                                    value: dom.value,
                                    label: dom.label
                                })
                            }
                        }
                    }
                    setActivityFiltre(arr)

                    setCountryFiltre([])
                    arr = []
                    for (const act of json['data']['country_filtre']) {
                        for (const co of optionsCountry) {
                            if (co.value === act) {
                                arr.push({
                                    value: co.value,
                                    label: co.label
                                })
                            }
                        }
                    }
                    setCountryFiltre(arr)
                    setLogoFileDb([json['data']['logo']])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getActivities = () => {

            fetch(server + '/activity', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr = []
                    json['data'].forEach((opt, i) => {
                            arr.push({
                                value: opt.id,
                                label: opt.activity
                            })
                        }
                    )
                    setOptionsDomains(arr)
                    getCountries()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getCountries = () => {

            fetch(server + '/country', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.id,
                                label: opt.country
                            })
                        }
                    )
                    setOptionsCountry(arr2)
                    // getAgentInfo()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (optionsDomains.length === 0 && optionsCountry.length === 0) {
            getActivities()
        } else {
            if (firstName === "") {
                getAgentInfo()
            }
        }
    }, [optionsDomains, optionsCountry,optionsCurrency, firstName, token, user_id, intl]);

    const editAgentInfo = (e) => {
        e.preventDefault()
        var data = new FormData()
        data.append('first_name', firstName)
        data.append('last_name', lastName)
        data.append('about', about)
        data.append('email', email)
        data.append('phone', phone)
        //data.append('currency', currency.value)
        data.append('currency', 0)
        data.append('commission', commission)
        data.append('target', target)
        if (activity !== undefined && activity.length > 0) {
            for (const act of activity) {
                data.append('activity[]', act.value)
            }
        }
        if (country !== undefined && country.length > 0) {
            for (const co of country) {
                data.append('country[]', co.value)
            }
        }
        if (activityFiltre !== undefined && activityFiltre.length > 0) {
            for (const actFiltre of activityFiltre) {
                data.append('activity_filtre[]', actFiltre.value)
            }
        }
        if (countryFiltre !== undefined && countryFiltre.length > 0) {
            for (const country of countryFiltre) {
                data.append('country_filtre[]', country.value)
            }
        }
        data.append('logo', logoFile[0])

        fetch(server + '/agent', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            if (response.ok) {
                window.location.href = '/profile-settings/' + user_id
            } else {
                toast.warning(intl.formatMessage({id: 'error'}));
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const addCollaboration = (e) => {
        e.preventDefault()
        var data = new FormData()
        data.append('company_name', collabName)
        data.append('date_start', moment(collabDateStart).format('YYYY-MM-DD'))
        data.append('date_end', moment(collabDateEnd).format('YYYY-MM-DD'))
        data.append('budget', collabBudged)
        data.append('commission', collabCommission)

        fetch(server + '/agent/add_collaboration', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            if (response.ok) {
                window.location.reload()
            }
            return response.json()
        }).then(json => {
            if (json['error']) {
                toast.warning(json['error']);
            }
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        <Col md={9} className={"companySettings"}>
            <form className={"form-container p-0"} onSubmit={(e) => editAgentInfo(e)}>
                <h2>
                    {intl.formatMessage({id: 'personal_information'})}
                </h2>
                <Row className={"companyInfo"}>
                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'last_name'})}</span>
                            <input required value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'first_name'})}</span>
                            <input required value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'phone'})}</span>
                            <input required value={phone} onChange={(e) => setPhone(e.target.value)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'email'})}</span>
                            <input required value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                    </Col>
                </Row>

                <h3>
                    {intl.formatMessage({id: 'biography'})}
                </h3>
                <h5 className={"subtitle"}>
                    {/*Despre ce este oportunitatea ta ?*/}
                </h5>
                <textarea
                    required
                    className={"companyDescription"}
                    value={about} onChange={(e) => setAbout(e.target.value)}
                />
                <h3>
                    {intl.formatMessage({id: 'upload_image'})}
                </h3>
                <h5 className={"subtitle"}>
                    {intl.formatMessage({id: 'upload_a_picture_of_yourself_profile_picture'})}
                </h5>

                <Row>
                    <Col>

                        <div {...getRootPropsFiles()} className={"dropzone"}>
                            <input {...getInputPropsFiles()} />
                            <div className={"title"}>{intl.formatMessage({id: 'drag_and_drop_image_here_or'})}
                                <span>{intl.formatMessage({id: 'searching_for'})}</span>
                                {intl.formatMessage({id: 'to_choose_a_image'})}</div>
                        </div>
                        <aside className={"thumbsContainer"}>
                            {getThumbs(logoFile, setLogoFile)}
                        </aside>
                        <div>
                            {
                                logoFileDb.map((file, i) => (
                                    <div className={"thumb"} key={i}>

                                        <div className={"droppedImage"}>
                                            {
                                                <img
                                                    src={file ? (server_path_files + file) : ''} alt={""}
                                                />
                                            }
                                            <img
                                                src={"/images/delete.svg"}
                                                className={"deleteIcon"}
                                                onClick={() => deleteFile(logoFileDb, setLogoFileDb, i)}
                                                alt={""}
                                            />

                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
                <h3 className={"mt-5"}>
                    {intl.formatMessage({id: 'about_agent'})}
                </h3>

                <Row>

                    <Col md={4}>
                        <div className={"item select"}>
                            <span>{intl.formatMessage({id: 'field_of_activity'})}</span>
                            <Select
                                isMulti
                                placeholder={intl.formatMessage({id: 'select'})}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={customSelectStyle}
                                options={optionsDomains}
                                value={activity}
                                onChange={(e) => setActivity(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={"item select"}>
                            <span>{intl.formatMessage({id: 'countries'})} :</span>
                            <Select
                                isMulti
                                placeholder={intl.formatMessage({id: 'select'})}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={customSelectStyle}
                                options={optionsCountry}
                                value={country} onChange={(e) => setCountry(e)}
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className={"item select"}>
                            <span>{intl.formatMessage({id: 'currency'})} :</span>
                            <Select
                                placeholder={intl.formatMessage({id: 'select'})}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={customSelectStyle}
                                options={optionsCurrency}
                                value={currency} onChange={(e) => setCurrency(e)}
                                required
                            />
                        </div>
                    </Col>


                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'commission_applied'})}</span>
                            <input required value={commission} onChange={(e) => setCommission(e.target.value)}/>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'target'})}</span>
                            <input required value={target} onChange={(e) => setTarget(e.target.value)}/>
                        </div>
                    </Col>

                </Row>

                <h3>
                    {intl.formatMessage({id: 'interest_filters'})}
                </h3>

                <Row>

                    <Col md={6}>
                        <div className={"item select"}>
                            <span>{intl.formatMessage({id: 'field_of_activity'})}</span>
                            <Select
                                isMulti
                                placeholder={intl.formatMessage({id: 'select'})}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={customSelectStyle}
                                options={optionsDomains}
                                value={activityFiltre} onChange={(e) => {
                                setActivityFiltre(e)
                            }}
                                required
                            />

                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"item select"}>
                            <span>{intl.formatMessage({id: 'countries'})} :</span>
                            <Select
                                isMulti
                                placeholder={intl.formatMessage({id: 'select'})}
                                menuPortalTarget={document.body}
                                menuPosition={'fixed'}
                                styles={customSelectStyle}
                                options={optionsCountry}
                                value={countryFiltre} onChange={(e) => setCountryFiltre(e)}
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row className={"itemSave"}>
                    <Col>
                        <Button type={'submit'} variant={"primary"}>
                            {intl.formatMessage({id: 'save'})}
                        </Button>
                    </Col>
                </Row>
            </form>

            <Row>
                <Col className={"aboutCollab"}>
                    <div>
                        <h3>
                            {intl.formatMessage({id: 'about_collaborations'})}
                        </h3>
                    </div>
                </Col>
            </Row>
            <form className={"form-container p-0"} onSubmit={(e) => addCollaboration(e)}>
                <Row>
                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'company_name'})}</span>
                            <input required value={collabName} onChange={(e) => setCollabName(e.target.value)}/>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'date_start'})}</span>
                            <div>
                                <DateTimePicker
                                    value={collabDateStart === '' ? new Date() : collabDateStart}
                                    onChange={(date) => setCollabDateStart(date)}
                                    dateFormat={"yyyy-MM-dd"}
                                    format={"yyyy-MM-dd"}
                                    formatDate={"yyyy-MM-dd"}
                                    required
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'date_end'})}</span>
                            <div>
                                <DateTimePicker
                                    value={collabDateEnd === '' ? new Date() : collabDateEnd}
                                    onChange={(date) => setCollabDateEnd(date)}
                                    dateFormat={"yyyy-MM-dd"}
                                    format={"yyyy-MM-dd"}
                                    formatDate={"yyyy-MM-dd"}
                                    required
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'budget'})}</span>
                            <input required value={collabBudged} onChange={(e) => setCollabBudged(e.target.value)}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <div className={"item"}>
                            <span>{intl.formatMessage({id: 'commission'})}</span>
                            <input required value={collabCommission} onChange={(e) => setCollabCommission(e.target.value)}/>
                        </div>
                    </Col>

                    <Col md={6}>
                        <Button variant={"primary"} type={'submit'}>
                            {intl.formatMessage({id: 'save'})}
                        </Button>

                    </Col>


                </Row>
            </form>
        </Col>
    )
}