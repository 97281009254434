import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import {Link, useParams} from "react-router-dom";
import {server, server_path_files} from "../../../const";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function ProfileUserHeader() {
    const user_id = useParams().id

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const [agent,setAgent] = useState('')

    useEffect(() => {
        const getAgentInfo = () => {
            
            fetch(server + '/agent/' + user_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }

            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data']['email']) {
                    setAgent(json['data'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        
        if (agent === "") {
            getAgentInfo()
        }

    }, [token,agent,user_id, intl]);


    const addCv = (e) => {
        const data = new FormData();
        data.append('file[]', e);
        fetch(server + '/agent/add_cv', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }

        }).then(response => {
            if (response.ok) {
                 window.location.reload()
            } else {
                toast.warning(intl.formatMessage({id: 'error'}));
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
    
    return (
        agent !== '' && 
        <div className={"companyHeader"}>
            <Row>
                <Col md={2} className={"text-center"}>
                    <div className={"logoCont"}>
                        <img src={agent['logo'] ? (server_path_files + agent['logo']) : ''} alt={""}/>
                    </div>
                    <div className={"companyTitle"}>
                        {
                            agent['first_name'] + ' ' + agent['last_name']
                        }
                    </div>
                   {/* <div className={"companyDomain"}>
                        Agricultura
                    </div>*/}
                </Col>
                <Col md={7} className={"position-relative"}>
                    <h2>
                        {intl.formatMessage({id: 'about_me'})}
                    </h2>
                    <div className={"companyDesc"}>
                        {agent['about']}
                    </div>
                    <div>
                        <Link target="_blank" to={'/'} onClick={() => agent['cv'] !== null ? window.open(server_path_files + agent['cv']) : ''}>
                            <Button variant="primary" className={"mr-4"}>
                                <img src={"/images/download.svg"} className={"btn-icon"} alt={""}/>
                                {intl.formatMessage({id: 'download_cv'})}</Button>{' '}
                        </Link>
                        <label htmlFor={"file-upload"} className={"btn-secondary"}>
                            
                                <img src={"/images/arrow-up-line.svg"} className={"btn-icon"} alt={""}/>
                            {intl.formatMessage({id: 'upload_cv'})}
                        </label>
                        <input id={"file-upload"} className={"d-none"} type="file" 
                               onChange={(e) => addCv(e.target.files[0])}
                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf" />

                    </div>
                    <div className={"separator"}>

                    </div>
                </Col>
                <Col md={3}>
                    <div className={"contactInfo"}>
                        <div className={"element"}>
                            <img src={"/images/phone.svg"} className={"icon"} alt={""}/>
                            {agent['phone']}
                        </div>
                        <div className={"element"}>
                            <img src={"/images/email.svg"} className={"icon"} alt={""}/>
                            {agent['email']}
                        </div>
                        <div className={"element"}>
                            <img src={"/images/percent-line.svg"} className={"icon"} alt={""}/>
                            {intl.formatMessage({id: 'commission'})}  {': ' + agent['commission'] + ' %'}
                        </div>
                        <div className={"element"}>
                            <img src={"/images/target.svg"} className={"icon"} alt={""}/>
                            {intl.formatMessage({id: 'target'})}   {': ' + agent['target'] + ' ' + sessionStorage.getItem('currency')}
                        </div>
                     {/*   <div className={"element"}>
                            <img src={"/images/location.svg"} className={"icon"} alt={""}/>
                            
                        </div>*/}
                    </div>
                </Col>
            </Row>
        </div>
    )
}