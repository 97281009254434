import React from 'react';
import './style.css';
import {Link} from "react-router-dom";
import {server, server_path_files} from "../../../const";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function ContractsCard({
                                          id,
                                          image,
                                          name,
                                          uploadSign = false,
                                          contractSign = '',
                                          uploadUnsign = false,
                                          contractUnsign = '',
                                          access
                                      }) {
    const token = sessionStorage.getItem('token')
    const intl = useIntl();


    const uploadContract = (e, sign) => {
        const data = new FormData();
        data.append('file[]', e);
        data.append('sign', sign);
        fetch(server + '/contracts/upload/' + id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }

        }).then(response => {
            if (response.ok) {
                window.location.reload()
            } else {
                toast.warning(intl.formatMessage({id: 'error'}));
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    const deleteContract = (sign) => {
        const data = new FormData();
        data.append('sign', sign);
        fetch(server + '/contracts/delete/' + id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }

        }).then(response => {
            if (response.ok) {
                window.location.reload()
            } else {
                toast.warning(intl.formatMessage({id: 'error'}));
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
    return (
        <div className={"contractCard"}>
            <img src={"/images/Vector 68.svg"} alt={""} className={"imgLeft"}/>
            <img src={"/images/Vector 69.svg"} alt={""} className={"imgRight"}/>
            <div className={"profileImage"}>
                <img src={server_path_files + image} alt={""}/>
            </div>
            <div className={"name"}>
                {name}
            </div>

            <div className={"contractUnsign"}>


                {
                    access ?
                        <div>
                            {intl.formatMessage({id: 'contracts_unsigned'})}
                            {
                                contractUnsign ?
                                    <Link onClick={() => window.open(server_path_files + contractUnsign)} to={'/contracts'} >
                                        <img src={"/images/download-cloud-2-line.svg"} alt={""} className={"m-2 mt-0"}/>
                                    </Link>
                                    :
                                    <div className={"d-inline float-right"}>
                                        <label htmlFor={"file-upload"} className={"addFile "}>
                                            <img src={"/images/upload-cloud-down-2-line.svg"} alt={""} className={"m-2 mt-0"}/>
                                        </label>
                                        <input id={"file-upload"} className={"d-none"} type="file" onChange={(e) => uploadContract(e.target.files[0], 0)}/>

                                    </div>
                            }
                            {
                                uploadUnsign ?
                                    <img src={"/images/delete-bin-line.svg"} alt={""} onClick={() => deleteContract(0)}/>
                                    :
                                    ""
                            }

                        </div>
                        :
                        <div>
                            {intl.formatMessage({id: 'contracts_unsigned'})}
                            {
                                contractUnsign  && <Link onClick={() => window.open(server_path_files + contractUnsign)} to={'/contracts'}>
                                <img src={"/images/download-cloud-2-line.svg"} alt={""} className={"m-2 mt-0"}/>
                            </Link>}
                        </div>
                }
                <div>
                </div>
            </div>
            <div className={"contractSign"}>
                {
                    !access ?
                        <div>
                            {intl.formatMessage({id: 'contracts_signed'})}

                            {
                                contractSign ?
                                    <Link  onClick={() => window.open(server_path_files + contractSign)} to={'/contracts'} >
                                        <img src={"/images/download-cloud-2-line.svg"} alt={""} className={"m-2 mt-0"}/>
                                    </Link>
                                    :
                                    contractUnsign && <div  className={"m-2 mt-0"}>
                                        <label htmlFor={"file-upload"}>
                                            <img src={"/images/upload-cloud-down-2-line.svg"} alt={""}/>
                                        </label>
                                        <input id={"file-upload"} className={"d-none"} type="file" onChange={(e) => uploadContract(e.target.files[0], 1)}/>
                                    </div>
                            }
                            {
                                uploadSign ?
                                    <img src={"/images/delete-bin-line.svg"} alt={""} onClick={() => deleteContract(1)}/>
                                    :
                                    ""
                            }


                        </div>
                        :
                        <div>
                            {intl.formatMessage({id: 'contracts_signed'})}
                            {contractSign && <Link  onClick={() => window.open(server_path_files + contractSign)} to={'/contracts'} >
                                <img src={"/images/download-cloud-2-line.svg"} alt={""} className={uploadSign ? "m-2 mt-0" : ""}/>
                            </Link>}
                        </div>
                }


                <div>
                </div>
            </div>

        </div>
    )

}