export const eng = {
    'package_activated': 'Package has been activated!',
    'error': 'An error has occurred!',
    'companies': 'Companies',
    'agents': 'Agents',
    'agent': 'Agent',
    'No': 'No',
    'name': 'Name',
    'full_name': 'Full Name',
    'enter_full_name': 'Enter full name',
    'email': 'Email',
    'enter_email': 'Enter email address',
    'phone': 'Phone',
    'location': 'Location',
    'profile_completion': 'Profile completion',
    'actions': 'Actions',
    'block': 'Block',
    'my_leads': 'My leads',
    'leads': 'Leads',
    'agents_leads': 'Agents leads',
    'lead': 'Lead',
    'create_lead': 'Create lead',
    'region': 'Region',
    'regions': 'Regions',
    'budget': 'Budget',
    'deadline': 'Deadline',
    'edit': 'Edit',
    'domain': 'Domain',
    'profile': 'Profile',
    'pay': 'Pay',
    'details': 'Details',
    'message': 'Message',
    'collaboration': 'COLLABORATION',
    'renewable_resources_manager': 'RENEWABLE RESOURCES MANAGER',
    'back': 'Back',
    'description': 'Description',
    'accept': 'Accept',
    'decline': 'Decline',
    'download_files_for_viewing': 'Download files for viewing',
    'you_do_not_have_access_to_the_files_wait_until_you_receive_access': 'You do not have access to the files, wait until you receive access!',
    'download': 'Download',
    'delete': 'Delete',
    'last_accessed': 'Last accessed',
    'add_the_files': 'Add the files',
    'add_files': 'Add files',
    'add_file': 'Add file',
    'add_payment': 'Add payment',
    'add': 'Add',
    'upload_the_files': 'Upload the files',
    'are_you_sure_you_want_to_delete_this_task': 'Are you sure you want to delete this task?',
'this_task_cannot_be_restored_after_deletion_if_you_do_not_intend_to_delete_click_cancel_If_you_want_to_confirm_the_deletion_click_delete.': 'This task cannot be restored after deletion. If you do not intend to delete, click Cancel. If you want to confirm the deletion, click Delete.',
    'cancel':'Cancel',
    'opportunities_first_lowercase':'opportunities',
    'private_opportunities_first_lowercase':'private opportunities',
    'public_opportunities_first_lowercase':'public opportunities',
    'funding_first_lowercase':'funding',
    'auction_first_lowercase':'auction',
    'opportunities_for_agents_first_lowercase':'opportunities for agents',
    'leads_first_lowercase':'leads',
    'commision_first_lowercase':'commission',
    'information':'Information',
    'the_request_has_been_sent':'The request has been sent',
    'company': 'Company',
    'subject':'Subject',
    'payment_option':'Payment option',
    'amount':'Amount',
    'description_of_the_opportunity':'Description of the opportunity',
    'what_is_your_opportunity_about':'What is your opportunity about?',
    'opportunity_description_in_maximum_200_characters':'Opportunity description in maximum 200 characters',
    'files':'Files',
    'uploaded_files':'Uploaded files',
    'save_lead':'Save lead',
    'save_opportunity':'Save opportunity',
    'accomplished': 'Accomplished',
'units':'Units',
'task_management':'Task Management',
'create_task':'Create Task',
'create':'Create',
'task_information':'Task Information',
'favorite_collaborators':'Favorite Collaborators',
'collaborations':'Collaborations',
'collaborations_companies':'Collaborations - Companies',
'collaborations_agents':'Collaborations - Agents',
'pending_applications':'Pending Applications',
'collaborators_agents':'Collaborators - Agents',
'collaborators_companies':'Collaborators - Companies',
'visitors_profile':'Visitors Profile',
'active_visitors':'Active Visitors',
'all_visitors':'All Visitors',
'select':'Select',
'all_companies':'All Companies',
'visitors':'Visitors',
'are_you_sure_you_want_to_delete_this_contributor':'Are you sure you want to delete this contributor?',
'if_you_do_not_intend_to_delete_click_cancel_if_you_want_to_confirm_the_deletion_click_delete.':'If you do not intend to delete, click Cancel. If you want to confirm the deletion, click Delete.',
'company_opportunities':'Company Opportunities',
'opportunities_uppercase':'OPPORTUNITIES',
'download_company_materials':'Download Company Materials',
'leads_made':'Leads Made',
'fields_of_activity':'Fields of Activity',
'download_the_company_presentation':'Download the Company Presentation',
'enter_the_file_name':'Enter the File Name',
'about_us':'About Us',
'download_presentation':'Download Presentation',
'watch_the_video':'Watch the Video',
'contact_person':'Contact Person',
'about_the_company':'About the Company',
'recent_activity':'Recent Activity',
'settings':'Settings',
'files_management':'Files Management',
'save':'Save',
'secret_key':'Secret Key',
'publishable_key':'Publishable Key',
'stripe_data':'Stripe Data',
'keywords':'Keywords',
'complete_the_blanks':'Complete the Blanks',
'company_information':'Company Information',
'drag_and_drop_image_here_or':'Drag and Drop Image Here or',
'drag_and_drop_logo_here_or':'Drag and Drop Logo Here or',
'searching_for':'Searching for',
'to_choose_a_logo':'to choose a logo',
'to_choose_a_image':'to choose an image',
'company_description':'Company Description',
'company_overview_summary':'Company Overview Summary',
'about_the_company_max_500_characters':'About the Company (max 500 characters)',
'description_of_customers_of_interest':'Description of Customers of Interest',
'general_presentation':'General Presentation',
'about_clients_of_interest_max_400_characters':'About Clients of Interest (max 400 characters)',
'upload_the_company_presentation':'Upload the Company Presentation',
'upload_a_file_about_the_company':'Upload a File About the Company',
'drag_and_drop_file_here_or':'Drag and Drop File Here or',
'to_choose_a_file':'to choose a file',
'your_youtube_link':'Your YouTube Link',
'your_url':'Your URL',
'useful_links':'Useful Links',
'facebook':'Facebook',
'linkedin':'LinkedIn',
'field_of_activity':'Field of Activity',
'countries':'Countries',
'currency':'Currency',
'interest_filters':'Interest Filters',
'load_the_platform_logo':'Load the Platform Logo',
'title':'Title',
'metadata':'Metadata',
'contracts_signed':'Contracts Signed',
'contracts_unsigned':'Contracts Unsigned',
'last_opportunities':'Last Opportunities',
'expire':'Expire',
'private_opportunities':'Private Opportunities',
'opportunities':'Opportunities',
'public_opportunities':'Public Opportunities',
'financing':'Financing',
'auction':'Auction',
'opportunities_for_agents':'Opportunities for Agents',
'favorite_companies':'Favorite Companies',
'total_auctions_in_progress':'Total Auctions in Progress',
'activity':'Activity',
'tasks_in_progress':'Tasks in Progress',
'pending_contracts':'Pending Contracts',
'total_leads_in_the_works':'Total Leads in the Works',
'total_opportunities_in_the_works':'Total Opportunities in the Works',
'total_financing_in_progress':'Total Financing in Progress',
'objective_achieved':'Objective Achieved',
'total_sales':'Total Sales',
'total_objective':'Total Objective',
'estimated_receipts':'Estimated Receipts',
'sales':'Sales',
'sales_evolution':'Sales Evolution',
'sales_objective':'Sales Objective',
'objective':'Objective',
'total':'Total',
'date':'Date',
'agent_name':'Agent Name',
'commission_due':'Commission Due',
'goal_achievement_percentage':'Goal Achievement Percentage',
'my_opportunities':'My Opportunities',
'create_opportunity':'Create Opportunity',
'company_details':'Company Details',
'fee_charged':'Fee Charged',
'company_name':'Company Name',
'total_leads':'Total Leads',
'percentage_achieved':'Percentage Achieved',
'estimated_commission':'Estimated Commission',
'my_collaborations':'My Collaborations',
'contracts_concluded':'Contracts Concluded',
'contracts':'Contracts',
'congratulations':'Congratulations',
'at_the_moment_you_have_no_achieved_objective':'At the moment, you have no achieved objective',
'views_first_lowercase':'view',
'contract_first_lowercase':'contract',
'contracts_first_lowercase':'contracts',
'statistically':'Statistically',
'number_of_companies':'Number of Companies',
'number_of_agents':'Number of Agents',
'number_of_leads':'Number of Leads',
'notes':'Notes',
'you_have':'You have',
'active_leads_first_lowercase':'active leads',
'send':'Send',
'view_profile':'View Profile',
'messages':'Messages',
'last_access_first_lowercase':'last access',
'add_invoice':'Add Invoice',
'total_commission':'Total Commission',
'goal_achievement':'Goal Achievement',
'commissions_paid':'Commissions Paid',
'commission_applied':'Commission Applied',
'sales_agent':'Sales Agent',
'adjacent_documents':'Adjacent Documents',
'download_files_for_editing_then_use_the_upload_button_to_add_to_the_list.':'Download files for editing, then use the upload button to add to the list.',
'list_of_tasks':'List of Tasks',
'achievement_level':'Achievement Level',
'lead_realization':'Lead Realization',
'description_lead':'Lead Description',
'tasks_situation':'Tasks Situation',
'tasks':'Tasks',
'commission':'Commission',
'commissions':'Commissions',
'paid':'Paid',
'liability':'Liability',
'objectiv':'Objective',
'invoice':'Invoice',
'invoice_to':'Invoice To',
'view':'View',
'invoices':'Invoices',
'unpaid_invoices':'Unpaid Invoices',
'request_access_to_view':'Request Access to View',
'create_account':'Create Account',
'with_social_media_account_or_your_email':'With Social Media Account or Your Email',
'or':'or',
'account_type':'Account Type',
'password':'Password',
'agree':'Agree',
'terms_of_service_privacy_policy':'Terms of Service, Privacy Policy.',
'sign_up':'Sign Up',
'have_an_account':'Do you have an account',
'then_sign_in':'Then Sign In',
'sign_up_with_google':'Sign Up with Google',
'sign_in':'Sign In',
'do_not_have_an_account':"You don't have an account",
'remember_me':'Remember Me',
'invalid_email':'Invalid Email',
'welcome':'Welcome',
'sign_in_by_entering_the_information_below':'Sign in by entering information below',
'confirm_password':'Confirm Password',
'create_password':'Create Password',
'your_new_password_must_be_different':'Your new password must be different',
'number_of_users':'Number of Users',
'average_commission':'Average Commission',
'target':'Target',
'about_collaborations':'About Collaborations',
'date_start':'Start Date',
'date_end':'End Date',
'personal_information':'Personal Information',
'first_name':'First Name',
'last_name':'Last Name',
'biography': 'Biography',
'upload_image': 'Upload image',
'about_agent': 'About agent',
'upload_a_picture_of_yourself_profile_picture': 'Upload a picture of yourself: profile picture',
'the_experience': 'The experience',
'my_biography': 'My biography',
'companies_i_have_worked_with': 'Companies I have worked with',
'about_me': 'About me',
'download_cv': 'Download CV',
'upload_cv': 'Upload CV',
'packets': 'Packets',
'access_requests': 'Access requests',
'notifications': 'Notifications',
'you_have_a_new_contract_signed': 'You have a new contract signed',
'you_have_a_new_request': 'You have a new request',
'you_have_a_contract_to_sign': 'You have a contract to sign',
'you_have_been_added_to_favorites': 'You have been added to favorites',
'you_have_a_new_view': 'You have a new view',
'logout': 'Logout',
'download_xml': 'Download XML',
'my_profile': 'My profile',
'financial_activity': 'Financial activity',
'of_course_you_want_to_extract_lead_data': 'Of course, you want to extract lead data?',
'the_file_contains_for_each_lead_id_user_id_name_region_deadline_budget_description_and_for_each_month_of_the_year_the_financial_situation_agent_debt_and_amount_paid': 'The file contains for each lead: id, user id, name, region, deadline, budget, description, and for each month of the year, the financial situation (agent, debt, and amount paid)',
'reports': 'Reports',
'administration': 'Administration',
'do_you_want_the_subscription_to_be_extended_automatically': 'Do you want the subscription to be extended automatically?',
'yes': 'Yes',
'nu': 'No',
'active': 'Active',
'start': 'Start',
'vailable': 'Available',
'PREMIUM_PACKAGE': 'PREMIUM PACKAGE',
'PLUS_PACKAGE': 'PLUS PACKAGE',
'BASIC_PACKAGE': 'BASIC PACKAGE',
'we_are_really_happy_to_see_you_here': 'We are really happy to see you here',
'your_plan_your_choice': 'Your plan, your choice',
'FREE_FOR_LIFETIME_PACKAGE': 'FREE FOR LIFETIME PACKAGE',
'create_user_profile': 'Create user profile',
'full_dashboard': 'Full dashboard',
'visible_3_companies_that_visited_the_profile': 'Visible 3 companies that visited the profile',
'visible_companies_that_visited_the_profile': 'Visible companies that visited the profile',
'visible_10_companies_and_10_agents_who_visited_the_profile': 'Visible 10 companies and 10 agents who visited the profile',
'visible_25_companies_and_25_agents_who_visited_the_profile': 'Visible 25 companies and 25 agents who visited the profile',
'visible_10_companies': 'Visible 10 companies',
'visible_50_companies': 'Visible 50 companies',
'visible_100_companies': 'Visible 100 companies',
'visible_250_companies': 'Visible 250 companies',
'visible_companies': 'Visible companies',
'upload_cv_packages': 'Upload CV',
'access_financial_activity': 'Access financial activity',
'access_leads': 'Access leads',
'access_50_leads': 'Access 50 leads',
'access_3_leads': 'Access to 3 leads',
'access_to_5_opportunities': 'Access to 5 opportunities',
'access_to_25_opportunities': 'Access to 25 opportunities',
'access_to_50_opportunities': 'Access to 50 opportunities',
'access_opportunities': 'Access opportunities',
'add_a_maximum_of_3_opportunities': 'Add a maximum of 3 opportunities',
'add_a_maximum_of_10_opportunities': 'Add a maximum of 10 opportunities',
'access_collaborations': 'Access collaborations',
'access_contracts': 'Access contracts',
'inbox_only_125_messages': 'Inbox only 125 messages',
'inbox_only_250_messages': 'Inbox only 250 messages',
'inbox_only_500_messages': 'Inbox only 500 messages',
'inbox_only_5000_messages': 'Inbox only 5000 messages',
'to_add_collaborations': 'To add collaborations',
'access_to_5_private_opportunities': 'Access to 5 private opportunities',
'access_to_25_private_opportunities': 'Access to 25 private opportunities',
'access_to_private_opportunities': 'Access to private opportunities',
'access_to_3_public_opportunities': 'Access to 3 public opportunities',
'access_to_25_public_opportunities': 'Access to 25 public opportunities',
'access_to_public_opportunities': 'Access to public opportunities',
'unlimited_file_upload': 'Unlimited file upload',
'upload_1_file': 'Upload 1 file',
'access_financing': 'Access financing',
'access_auctions': 'Access auctions',
'upload_video': 'Upload video',
'search': 'Search',
'start_a_conversation': 'Start a conversation',
'expired': 'Expired',
'finished': 'Finished',

    'terms_of_service_title_1':'Terms of Service',
    'terms_of_service_content_1':'These terms of service (“Terms”, “Agreement”) are an agreement between SC ARIA ORIGINAL DESIGN SRL (“SC ARIA ORIGINAL DESIGN SRL”, “ARIA”, “us”, “we” or “our”) and you (“User”, “you” or “your”). This Agreement sets forth the general terms and conditions of your use of the https://clossers.com website and any of its products or services (collectively, “Website” or “Services”).',
    'terms_of_service_title_2':'Accounts and membership',
    'terms_of_service_content_2':'If you create an account at the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.',
    'terms_of_service_title_3':'User content',
    'terms_of_service_content_3':'We do not own any data, information or material (“Content”) that you submit to the Website in the course of using the Service. You shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all submitted Content. We may, but have no obligation to, monitor Content on the Website submitted or created using our Services by you. Unless specifically permitted by you, your use of the Website does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.',
    'terms_of_service_title_4':'Billing and payments',
    'terms_of_service_content_4':'You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). If auto-renewal is enabled for the Services you have subscribed for, you will be charged automatically in accordance with the term you selected. If, in our judgment, your purchase constitutes a high risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.',
    'terms_of_service_title_5':'Refund Policy',
    'terms_of_service_content_5':'We offer 30 days money back guarantee and top level support on all our plans. You can cancel anytime, for any reason.',
    'terms_of_service_title_6':'Accuracy of information',
    'terms_of_service_content_6':'Occasionally there may be information on our Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or on any related website is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or on any related website has been modified or updated.',
    'terms_of_service_title_7':'Third party services',
    'terms_of_service_content_7':'If you decide to enable, access or use third party services, be advised that your access and use of such other services is governed solely by the terms and conditions of such other services, and we do not endorse, are not responsible or liable for, and make no representations as to any aspect of such other services, including, without limitation, their content or the manner in which they handle data (including your data) or any interaction between you and the provider of such other services. You irrevocably waive any claim against SC ARIA ORIGINAL DESIGN SRL with respect to such other services. SC ARIA ORIGINAL DESIGN SRL is not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such other services, or your reliance on the privacy practices, data security processes or other policies of such other services. You may be required to register for or log into such other services on their respective websites. By enabling any other services, you are expressly permitting SC ARIA ORIGINAL DESIGN SRL to disclose your data as necessary to facilitate the use or enablement of such other service.',
    'terms_of_service_title_8':'Backups',
    'terms_of_service_content_8':'We perform regular backups of the Website and Content and will do our best to ensure completeness and accuracy of these backups. In the event of the hardware failure or data loss we will restore backups automatically to minimize the impact and downtime.',
    'terms_of_service_title_9':'Links to other websites',
    'terms_of_service_content_9':'Although this Website may be linked to other websites, we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites. We do not assume any responsibility or liability for the actions, products, services and content of any other third parties. You should carefully review the legal statements and other conditions of use of any website which you access through a link from this Website. Your linking to any other off-site pages or other websites is at your own risk.',
    'terms_of_service_title_10':'Prohibited uses',
    'terms_of_service_content_10':'In addition to other terms as set forth in the Agreement, you are prohibited from using the website or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.\n' +
        'You must be at least 16 years old to use the platform.\n',
    'terms_of_service_title_11':'Intellectual property rights',
    'terms_of_service_content_11':'This Agreement does not transfer from SC ARIA ORIGINAL DESIGN SRL to you any SC ARIA ORIGINAL DESIGN SRL or third party intellectual property, and all right, title, and interest in and to such property will remain (as between the parties) solely with SC ARIA ORIGINAL DESIGN SRL. All trademarks, service marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of SC ARIA ORIGINAL DESIGN SRL or SC ARIA ORIGINAL DESIGN SRL licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or Services may be the trademarks of other third parties. Your use of our Website and Services grants you no right or license to reproduce or otherwise use any SC ARIA ORIGINAL DESIGN SRL or third-party trademarks.',
    'terms_of_service_title_12':'Disclaimer of warranty',
    'terms_of_service_content_12':'You agree that your use of our Website or Services is solely at your own risk. You agree that such Service is provided on an “as is” and “as available” basis. We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement. We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected. You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material and/or data. We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service. No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.',
    'terms_of_service_title_13':'Limitation of liability',
    'terms_of_service_content_13':'To the fullest extent permitted by applicable law, in no event will SC ARIA ORIGINAL DESIGN SRL, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if SC ARIA ORIGINAL DESIGN SRL has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of SC ARIA ORIGINAL DESIGN SRL and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater of one dollar or any amounts actually paid in cash by you to SC ARIA ORIGINAL DESIGN SRL for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its essential purpose.',
    'terms_of_service_title_14':'Indemnification',
    'terms_of_service_content_14':'You agree to indemnify and hold SC ARIA ORIGINAL DESIGN SRL and its affiliates, directors, officers, employees, and agents harmless from and against any liabilities, losses, damages or costs, including reasonable attorneys’ fees, incurred in connection with or arising from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating to your Content, your use of the Website or Services or any willful misconduct on your part.',
    'terms_of_service_title_15':'Severability',
    'terms_of_service_content_15':'All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining provisions or portions thereof shall remain in full force and effect.',
    'terms_of_service_title_16':'Dispute resolution',
    'terms_of_service_content_16':'The formation, interpretation and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Cluj, Romania without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of Romania. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the state and federal courts located in Bucharest, Romania, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.',
    'terms_of_service_title_17':'Assignment',
    'terms_of_service_content_17':'You may not assign, resell, sub-license or otherwise transfer or delegate any of your rights or obligations hereunder, in whole or in part, without our prior written consent, which consent shall be at our own sole discretion and without obligation; any such assignment or transfer shall be null and void. We are is free to assign any of its rights or obligations hereunder, in whole or in part, to any third party as part of the sale of all or substantially all of its assets or stock or as part of a merger.',
    'terms_of_service_title_18':'Changes and amendments',
    'terms_of_service_content_18':'We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon posting of an updated version of this Agreement on the Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to such changes.',
    'terms_of_service_title_19':'Acceptance of these terms',
    'terms_of_service_content_19':'You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access the Website and its Services.',
    'terms_of_service_title_20':'Contacting us',
    'terms_of_service_content_20':'If you have any questions about this Policy, please contact us.\n' +
        'SC ARIA ORIGINAL DESIGN SRL\n' +
        'Address: STR. FIZICIENILOR, NR.16, BL.10A, SC.A, ET.3, AP.18\n' +
        'Bucharest, sector 3, Romania\n' +
        'VAT: 21336993\n' +
        '\n',
        'advantages' : 'Advantages',   
        'about' : 'About',
        'home' : 'Home',
        'login':'Authenticate',
        'language' : 'Language',
        'header_text_1':'We invest in your future! Project selected under the Regional Operational Program and co-financed by the European Union through the European Regional Development Fund.',
        'header_text_2':'The content of this material does not necessarily represent the official position of the European Union or the Romanian Government.',
        'home_section_1_title' : 'Your sales force.',
        'home_section_1_paragraph' : 'Clossers.com offers a professional service to connect companies from all fields with sales experts who have access to customers of interest or who are interested in earning sales commissions. This service represents a way of outsourcing the sales department or a part of this department to sales experts.',
        'home_section_2_paragraph' : 'We offer support to companies at the beginning of the journey or those that cannot afford to invest in a sales department and in the lead generation activity that sales agents must do: they can opt to direct their budgets into commissions , so that they only pay for the results.',
        'home_section_3_paragraph' : 'It is an attractive strategy for a large number of entrepreneurs and a chance for startups that have quality and interesting products and services to reach large customers, and they will not be able to do this without sales agents who have connections in these companies and that have developed a commercial and in some cases personal relationship with these clients of interest.',
        'home_section_card_1_title' : 'Advantages for sales agents',
    'home_section_card_2_title' : 'Advantages for companies',
    'home_section_card_1_paragraph' : 'Registering the profile in the portal, uploading the CV, the recommendations, the client portfolio to which access or / and the domains in which they are active and where they can generate leads and sign contracts, information on the expectations that they have from the companies regarding the minimum commission charged and any other information that may cause a company to contact him for contracting and starting work together.',
    'home_section_card_2_paragraph' : 'Registering the profile in the portal, uploading the company presentation and a set of documents that will help the sales agents to understand the vision, the strategy, the objectives that they want to solve by calling an external sales force, the commissions that they offer for each type of service and product separately, portfolio presentation documentation, presentation images and scripts, client portfolio, differentiators compared to competitors in the market, client testimonials and any other information that can support the sales agent. Also, the company will upload data regarding their interests compared to other companies, so that users of the same type of portal can easily find them to start a B2B collaboration.',
    'footer_email_placeholder' : 'Enter your email',
    'footer_email_send_button' : 'Send',    
}