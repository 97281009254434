import React, {useState} from 'react';
import './style.css';
import {toast} from 'react-toastify';
import {server} from "../../const";
import {useIntl} from "react-intl";


export default function ResetPassword() {

    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const login = (e) => {

        e.preventDefault()

        fetch(server + '/auth/login', {
            method: 'POST',
            body: JSON.stringify({
                'email': email,
                'password': password

            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }


        }).then(response => {
            if(response.ok) {
                sessionStorage.setItem('token', response.headers.get('Authorization') + 'test')
                if (email === 'admin@test.com'){
                    sessionStorage.setItem('profileType','admin')
                } else {
                    sessionStorage.setItem('profileType','user')
                }
                window.location.reload()

            }else {
                toast.warning(intl.formatMessage({id: 'error'}));
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        <div className="main">
            <div className={"background"}>
                <img className={"img1"} src={"/images/login/freepik  background complete  inject 31.svg"} alt={""}/>
            </div>
            <div className={"login"}>
                <img className={"img4"} src={"/images/login/freepik  Character  inject 31.svg"} alt={""}/>
                <img className={"img2"} src={"/images/login/freepik  Plant  inject 31.svg"} alt={""}/>
                <img className={"img3"} src={"/images/login/freepik  Floor  inject 31.svg"} alt={""}/>
                <div className={"fcontainer"}>
                    <form className={"form-container"} onSubmit={(e) => login(e)}>
                        <div className={"form-group"}>
                            <h1 className={"login_title"}> {intl.formatMessage({id: 'create_password'})}</h1>
                            <p className={"login_subtitle"}>{intl.formatMessage({id: 'your_new_password_must_be_different'})}</p>
                            <div>
                                <div className={"inputs"}>
                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'email'})}</span>
                                        <input type={"email"} value={email} onChange={(e) => setEmail(e.target.value)} className={"form-control"} id={"InputEmail1"}
                                               placeholder={intl.formatMessage({id: 'enter_email'})}/>
                                    </div>
                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'password'})}</span>
                                        <input type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} className={"form-control"} id={"InputPassword1"}
                                               placeholder={intl.formatMessage({id: 'password'})}/>
                                    </div>
                                    <div className={"item"}>
                                        <span>{intl.formatMessage({id: 'confirm_password'})}</span>
                                        <input type={"password"} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className={"form-control"}
                                               id={"InputPassword2"} placeholder={intl.formatMessage({id: 'confirm_password'})}/>
                                    </div>
                                    
                                    <button type={"submit"} className={"btn btn-primary btn-block w-100"}>{intl.formatMessage({id: 'save'})}</button>

                                </div>
                            </div>

                        </div>

                    </form>
                </div>
            </div>

        </div>
    )
}