import React, {useEffect, useState} from 'react';
import {Col} from "react-bootstrap";
import './style.css';
import {server} from "../../../const";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

export default function ProfileUserJobHistory() {

    const user_id = useParams().id

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const [about,setAbout] = useState('')
    const [collaboration,setCollaboration] = useState('')
    
    useEffect(() => {
        const getJobHistory = () => {
            fetch(server + '/agent/job_history/' + user_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['about']) {
                    setCollaboration(json['collaboration'])
                    setAbout(json['about'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getJobHistory()
    }, [token,user_id, intl]);
    
    return (
        <Col mb={9} className={"puJobHistory"}>
            <h2 className={"mb-4 mt-2"}>
                {intl.formatMessage({id: 'my_biography'})}
            </h2>
            <div className={"description"}>
                {about}
            </div>

            <h3 className={"mb-4"}>
                {intl.formatMessage({id: 'companies_i_have_worked_with'})} :
            </h3>
            <div>
                <table className={"companies"}>
                    <thead>
                    <tr>
                        <th>
                            {intl.formatMessage({id: 'company_name'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'date'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'budget'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'commission'})}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        collaboration !== '' && collaboration.map((company,i) => (
                            <tr key={i}>
                                <td className={"name"}>
                                    <img src={"/images/Android_O_Preview_Logo 2.svg"} alt={""}/>
                                    {company.company_name}
                                </td>
                                <td className={"date"}>
                                    <div>
                                        {company.date_start}
                                    </div>
                                    <div className={"dateEnd"}>
                                        {company.date_end}
                                    </div>
                                </td>
                                <td className={"budget"}>
                                    {company.budget + sessionStorage.getItem('currency')}
                                </td>
                                <td className={"commission"}>
                                    {company.commission + ' %'} 
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </Col>
    )
}