import React from 'react';
import {Dropdown} from "react-bootstrap";
import './style.css';
import {server,server_path_files} from "../../../const";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";


export default function FileCard({id,type,title,size,lastAccessed,path = "",deleteButton = false, pathDelete=''}) {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const classTypes = {
        pdf: {
            image: 'pdf 1.svg',
            class: 'yellow'
        },
        video: {
            image: 'file-video.svg',
            class: 'purple'
        },
        image: {
            image: 'file-img.svg',
            class: 'green'
        },
        doc: {
            image: 'doc 1.svg',
            class: 'blue'
        },
    }

    const deleteFile = () => {

        fetch(server + pathDelete + id, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            window.location.reload()
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }


    return (
        <div className={"fileCard"}>
            <div className={"imageCont " + classTypes[type].class } >
                {
                    deleteButton ?
                        <Dropdown className={"customDropdown actions"}>
                            <Dropdown.Toggle variant={"info"}>
                                <img src={"/images/more-line.svg"}  alt={""}/>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item  target="_blank" href={path ? (server_path_files + path) : ''}>{intl.formatMessage({id: 'download'})}</Dropdown.Item>
                                <Dropdown.Item onClick={() => deleteFile()}>{intl.formatMessage({id: 'delete'})}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        :
                        (
                            path && <div onClick={() => window.open(path ? (server_path_files + path) : '')}>
                            <img src={"/images/download-cloud-line.svg"} className={"actions"} alt={""}/>
                        </div>
                        )
                }
                <img src={"/images/" + classTypes[type].image} alt={""}/>
            </div>
            <div className={"title"}>
                {title}
            </div>
            <div className={"size"}>
                {size/1000} kb
            </div>
            {
                lastAccessed !== '' &&
                <div className={"lastAccessed"}>
                    {intl.formatMessage({id: 'last_accessed'})}: {lastAccessed}
                </div>
            }
        </div>
    )
}