import React from 'react';
import './style.css';
import ProgressBar from "react-bootstrap/ProgressBar";
import {Col, Row} from "react-bootstrap";
import {useIntl} from "react-intl";

export default function Activity({stats}) {
    const intl = useIntl();

    return (
        <div className={"item"}>
            <h2>
                {intl.formatMessage({id: 'activity'})}
            </h2>
            <div className={"itemActivity purple"}>
                <Row>
                    <Col md={2}>
                        <div className={"itemImg "}>
                            <img src={"/images/dashboard/checkbox-line.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div>
                            <div className={"title"}>
                                {intl.formatMessage({id: 'tasks_in_progress'})}
                            </div>
                            <div className={"activityProgress"}>
                                {stats['tasks_done'] + '/' + stats['tasks_all']}
                            </div>
                        </div>
                        <ProgressBar variant="success" now={stats['tasks_done']} max={stats['tasks_all']}/>
                    </Col>
                </Row>
            </div>

            <div className={"itemActivity green"}>
                <Row>
                    <Col md={2}>
                        <div className={"itemImg "}>
                            <img src={"/images/dashboard/file-list-3-line.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div>
                            <div className={"title"}>
                                {intl.formatMessage({id: 'pending_contracts'})}
                            </div>
                            <div className={"activityProgress"}>
                                {stats['contracts_signed'] + '/' + stats['contracts_all']}
                            </div>
                        </div>
                        <ProgressBar variant="success" now={stats['contracts_signed']} max={stats['contracts_all']}/>
                    </Col>
                </Row>
            </div>

            <div className={"itemActivity yellow"}>
                <Row>
                    <Col md={2}>
                        <div className={"itemImg "}>
                            <img src={"/images/dashboard/BriefcaseSimple Yellow.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div>
                            <div className={"title"}>
                                {intl.formatMessage({id: 'total_leads_in_the_works'})}
                            </div>
                            <div className={"activityProgress"}>
                                {stats['leads_accepted'] + '/' + stats['leads_all']}
                            </div>
                        </div>
                        <ProgressBar variant="success" now={stats['leads_accepted']} max={stats['leads_all']}/>
                    </Col>
                </Row>
            </div>

            <div className={"itemActivity blue"}>
                <Row>
                    <Col md={2}>
                        <div className={"itemImg "}>
                            <img src={"/images/dashboard/Stack.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div>
                            <div className={"title"}>
                                {intl.formatMessage({id: 'total_opportunities_in_the_works'})}
                            </div>
                            <div className={"activityProgress"}>
                                {stats['opportunities_accepted'] + '/' + stats['opportunities_all']}
                            </div>
                        </div>
                        <ProgressBar variant="success" now={stats['opportunities_accepted']} max={stats['opportunities_all']}/>
                    </Col>
                </Row>
            </div>

            {  sessionStorage.getItem('profileType') !== 'user' && 
            <div className={"itemActivity purple"}>
                <Row>
                    <Col md={2}>
                        <div className={"itemImg "}>
                            <img src={"/images/dashboard/Gift.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div>
                            <div className={"title"}>
                                {intl.formatMessage({id: 'total_financing_in_progress'})}
                            </div>
                            <div className={"activityProgress"}>
                                {stats['funding_accepted'] + '/' + stats['funding_all']}
                            </div>
                        </div>
                        <ProgressBar variant="success" now={stats['funding_accepted']} max={stats['funding_all']}/>
                    </Col>
                </Row>
            </div>}

            {  sessionStorage.getItem('profileType') !== 'user' && 
            <div className={"itemActivity green"}>
                <Row>
                    <Col md={2}>
                        <div className={"itemImg "}>
                            <img src={"/images/dashboard/Megaphone.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div>
                            <div className={"title"}>
                                {intl.formatMessage({id: 'total_auctions_in_progress'})}
                            </div>
                            <div className={"activityProgress"}>
                                {stats['auction_accepted'] + '/' + stats['auction_all']}
                            </div>
                        </div>
                        <ProgressBar variant="success" now={stats['auction_accepted']} max={stats['auction_all']}/>
                    </Col>
                </Row>
            </div>}

            <div className={"itemActivity yellow"}>
                <Row>
                    <Col md={2}>
                        <div className={"itemImg "}>
                            <img src={"/images/dashboard/building-2-line-yellow.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={10}>
                        <div>
                            <div className={"title"}>
                                {intl.formatMessage({id: 'favorite_companies'})}
                            </div>
                            <div className={"activityProgress"}>
                                {stats['companies_fav'] + '/' + stats['companies_all']}
                            </div>
                        </div>
                        <ProgressBar variant="success" now={stats['companies_fav']} max={stats['companies_all']}/>
                    </Col>
                </Row>
            </div>
        </div>
    )
}