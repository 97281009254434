import React from 'react';

export default function Checkbox({info,ck,disable}) {
    
    return (
        <label className={"containerCheckbox"}>
            { ck === 1 ?
                <input type={"checkbox"} checked disabled={disable}/> 
                : 
                <input type={"checkbox"} disabled={disable}/>
            }
            
            <span className={"checkmark"}>
                <img src={"/images/checkbox-thick.svg"} alt={""}/>
            </span>
            {info}
        </label>
    )
}