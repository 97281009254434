import React, {useEffect, useState} from 'react';
import {Col} from "react-bootstrap";
import './style.css';
import Leads from "../../Utils/Leads/Leads";
import {server} from "../../../const";
import {toast} from "react-toastify";
import moment from "moment";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

export default function ProfileUserExperience() {    
    
    const user_id = useParams().id

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const [leads,setLeads] = useState('')

    useEffect(() => {
        const getExperience = () => {
            fetch(server + '/agent/experience/' + user_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['leads']) {
                    setLeads(json['leads'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getExperience()
    }, [token,user_id, intl]);

    return (
        <Col mb={9} className={"puExperience"}>
            <div className={"leadsContainer"}>
                {
                    leads !== '' && leads.map((lead, i) => (
                        <Leads
                            key={i}
                            id={lead.id}
                            type={parseInt(Math.random() * 4  + 1 )}
                            title={lead.subject}
                            dateStart={ moment(lead.created_at).format('YYYY-MM-DD')}
                            dateEnd={lead.deadline}
                        />
                    ))
                }
            </div>
        </Col>
    )
}