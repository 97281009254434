import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import {readableMonths, server} from "../../../const";
import DateSelector from "../../Utils/DateSelector/DateSelector";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import moment from 'moment'
import {useIntl} from "react-intl";

export default function Invoice() {
    
    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    const id = useParams().id
    
    const [invoice, setInvoice] = useState('');
    useEffect(() => {
        const getInvoice = () => {
            fetch(server + '/invoice/' + id, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setInvoice(json['data'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getInvoice()
    }, [id,token, intl])

    return (

        <div className={"tabContent"}>
            {
                invoice !== '' &&
                <div className={"financeInvoice"}>
                    <Row className={"header"}>
                        <Col md={2} className={"title"}>
                            {intl.formatMessage({id: 'invoice'})}
                        </Col>
                        <Col md={2}></Col>
                        <Col md={3} className={"info"}>
                            <div>

                                <img src={"/images/map-pin-line.svg"} className={"icon d-inline"} alt={""}/>
                                {
                                    invoice.agentCountries.map((item, i) => (
                                        i < 1 &&
                                        item.country 
                                    ))
                                }

                            </div>
                        </Col>
                        <Col md={2}></Col>
                        <Col md={3} className={"info"}>
                            <div>
                                <img src={"/images/phone.svg"} className={"icon"} alt={""}/>
                                {invoice.agent_phone}
                            </div>
                            <div>
                                <img src={"/images/email.svg"} className={"icon"} alt={""}/>
                                {invoice.agent_email}
                            </div>
                        </Col>
                    </Row>

                    <Row className={"invoiceTo"}>
                        <Col md={2} className={"title"}>
                            {intl.formatMessage({id: 'invoice_to'})}:
                        </Col>
                        <Col md={3} className={"info"}>
                            <div>

                                <img src={"/images/map-pin-line.svg"} className={"icon d-inline"} alt={""}/>
                                {invoice.company_address}
                            </div>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={3} className={"info"}>
                            <div>
                                <img src={"/images/phone.svg"} className={"icon"} alt={""}/>
                                {invoice.company_phone}
                            </div>
                            <div>
                                <img src={"/images/email.svg"} className={"icon"} alt={""}/>
                                {invoice.company_email}
                            </div>
                        </Col>
                        <Col md={1}></Col>
                        <Col md={2} className={"info"}>
                            <div>
                                <div className={"date"}>
                                    {intl.formatMessage({id: 'date'})}:
                                </div>
                                <div className={"m-0"}>
                                    {moment(invoice.updated_at).format('YYYY-MM-DD')}
                                </div>
                            </div>
                        </Col>

                    </Row>


                    <Row>
                        <Col md={12}>

                            <DateSelector
                                value={readableMonths[invoice.month]}
                            />

                            <DateSelector
                                value={invoice.year}
                            />
{/*
                            <Button variant={"primary"} className={"printButton"}>
                                <img src={"/images/printer-line-white.svg"} alt={""}/>
                                Print
                            </Button>*/}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <table className={"tasks"}>
                                <thead>
                                <tr>
                                    <th>
                                        {intl.formatMessage({id: 'tasks'})}
                                    </th>
                                    <th>
                                        {intl.formatMessage({id: 'commission'})}
                                    </th>
                                    <th>
                                        {intl.formatMessage({id: 'paid'})}
                                    </th>
                                    <th>
                                        {intl.formatMessage({id: 'liability'})}
                                    </th>
                                    <th>
                                        {intl.formatMessage({id: 'objectiv'})}
                                    </th>
                                    <th>
                                        {intl.formatMessage({id: 'total'})}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                        <tr>
                                            <td>
                                                {invoice.subject}
                                            </td>
                                            <td>
                                                {invoice.commission + ' %'}
                                            </td>
                                            <td>
                                                {invoice.paidTotal + sessionStorage.getItem('currency')}
                                            </td>
                                            <td className={"dat"}>
                                                {invoice.due + sessionStorage.getItem('currency')}
                                            </td>
                                            <td className={"obi"}>
                                                {parseFloat(100 * invoice.paidTotal / (invoice.commission * invoice.budget / 100)).toFixed(1) + ' %'}
                                            </td>
                                            <td>
                                                {invoice.commission * invoice.budget / 100 + sessionStorage.getItem('currency')}
                                            </td>
                                        </tr>

                                </tbody>
                            </table>
                        </Col>
                    </Row>

                </div>
            }
        </div>
        
    )
}