import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import {Link, useParams} from "react-router-dom";
import Select from "react-select";
import {customSelectStyle, server, server_path_files} from "../../../const";
import FileCard from "../../Utils/FileCard/FileCard";
import {toast} from "react-toastify";
import moment from "moment";
import {useIntl} from "react-intl";

export default function AgentViewLead() {

    const intl = useIntl();
    const id = useParams().id
    const token = sessionStorage.getItem('token')

    const [optionsDomains, setOptionsDomains] = useState([])
    const [optionsCountry, setOptionsCountry] = useState([])

    const [item, setItem] = useState('')
    const [newFiles, setNewFiles] = useState([])


    useEffect(() => {

        const getData = () => {
            fetch(server + "/lead/" + id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    if (json['error']){
                        toast.warning(json['error'])
                    } else{
                        toast.warning(intl.formatMessage({id: 'error'}))
                    }
                } else {
                    let arr = []
                    for (const act of json['data']['region']) {
                        for (const co of optionsCountry) {
                            if (co.value === parseInt(act)) {
                                arr.push({
                                    value: co.value,
                                    label: co.label
                                })
                            }
                        }
                    }
                    json['data']['region'] = arr
                    setItem(json['data'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getCountries = () => {

            fetch(server + '/country', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr2 = []
                    json['data'].forEach((opt, i) => {
                            arr2.push({
                                value: opt.id,
                                label: opt.country
                            })
                        }
                    )
                    setOptionsCountry(arr2)
                    getActivities()
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        const getActivities = () => {

            fetch(server + '/activity', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['data'][0]) {
                    let arr = []
                    setOptionsDomains([])
                    json['data'].forEach((opt, i) => {
                            arr.push({
                                value: opt.id,
                                label: opt.activity
                            })
                        }
                    )
                    setOptionsDomains(arr)

                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (optionsCountry.length === 0 && item === '') {
            getCountries()
        }

        if (item === '') {
            if (optionsCountry.length === 0) {
                return;
            }
            getData()
        }


    }, [id, token, optionsCountry, item, intl]);

    const addAccept = (value) => {
        var data = new FormData()
        data.append('accepted', value)
        fetch(server +  '/lead_agent/accept_request/' + id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            window.location.href='/agent-leads-view'
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }


    const addFile = (e) => {
        let newFile = []
        newFile.push(Object.assign(e, {
            preview: URL.createObjectURL(e)
        }))
        setNewFiles(newFiles.concat(newFile));
    }

    const saveFiles = () => {
        
        var data = new FormData()
        for (const file of newFiles) {
            data.append('file[]', file)
        }

        fetch(server + "/lead/add_files/" + id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.href = '/agent/lead/' + id
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }
    
    return (
        item !== '' &&
        <div className={"viewLead"}>
            <Row>
                <Col className={"mainTitle"}>
                    {intl.formatMessage({id: 'lead'})}
                </Col>
            </Row>
            <Row>
                <Col className={"filters"}>
                    <div className={"item"}>
                        <span> {intl.formatMessage({id: 'region'})}</span>
                        <Select
                            placeholder={ intl.formatMessage({id: 'select'})}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customSelectStyle}
                            options={optionsCountry}
                            value={item.region}
                        />
                    </div>
                    <div className={"item"}>
                        <span> {intl.formatMessage({id: 'domain'})}</span>
                        <Select
                            placeholder={ intl.formatMessage({id: 'select'})}
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                            styles={customSelectStyle}
                            options={optionsDomains}
                        />
                    </div>

                </Col>
            </Row>
            <Row className={"viewLeadContent"}>
                <Col className={"p-0"}>
                    <Row className={"viewOpCard p-0"}>
                        <Col md={2} className={"d-inline-flex"}>
                            <div className={"info"}>
                                <img src={item.company.logo ? (server_path_files + item.company.logo) : ''} alt={""} className={"logoCont"}/>
                                <div className={"username"}>
                                    {item.company.first_name +' '+item.company.last_name}
                                </div>
                                <Link to={"/profile-job-history/" + item.company.user_id}>
                                    {intl.formatMessage({id: 'profile'})}
                                    <img src={"/images/arrow-right-line.svg"} alt={""}/>
                                </Link> 
                            </div>
                        </Col>
                        <Col md={8} className={"position-relative"}>
                            <div className={"mainTitle"}>
                                {item.subject}
                            </div>
                            <div className={"date"}>
                                <img src={"/images/calendar-line.svg"} alt={""}/>
                                {moment(item.created_at).format('YYYY-MM-DD')}
                            </div>
                            <div className={"desc"}>
                                {item.about}
                            </div>

                            <Row>
                                <div className={"collaboration"}>
                                    {intl.formatMessage({id: 'collaboration'})}

                                </div>
                                <div className={"manager"}> {intl.formatMessage({id: 'renewable_resources_manager'})}

                                </div>
                            </Row>

                            <div className={"separator"}>

                            </div>
                        </Col>

                        <Col md={2} className={"opButtons"}>
                            <Button variant={"secondary"} className={"back"}>
                                <Link to={'/agent/leads'}>
                                    <img src={"/images/arrow-left-line.svg"} className={"editOportunity"} alt={""}/>
                                    {intl.formatMessage({id: 'back'})}
                                </Link>
                            </Button>
                            <Button variant={"secondary"} className={"mb-0"}>
                                <Link to={'/inbox/' + item.user_id}>
                                    <img src={"/images/send-plane-line-blue.svg"} className={"editOportunity"} alt={""}/>
                                    {intl.formatMessage({id: 'message'})}
                                </Link>
                            </Button>

                        </Col>
                    </Row>

                    <Row className={"description"}>
                        <Col>
                            <h4>
                                {intl.formatMessage({id: 'description'})}:
                            </h4>
                            <div>
                                {item.about}
                            </div>
                        </Col>
                    </Row>

                    {
                        item.access !== 1 ?
                            <Row>
                                <Col className={"p-0"}>
                                    <div className={"files p-0"}>
                                        {
                                            item.access === 0 &&
                                            <div className={"d-flex justify-content-center"}>

                                                <Button variant={'secondary'} className={'m-3'} onClick={() => addAccept(1)}>
                                                    {intl.formatMessage({id: 'accept'})}
                                                </Button>
                                                <Button variant={'primary'} className={'m-3'} onClick={() => addAccept(0)}>
                                                    {intl.formatMessage({id: 'decline'})}
                                                </Button>

                                            </div>
                                        }
                                        
                                        

                                        <div className={"mainTitle"}>
                                            <h3 className={"d-inline-block"}>
                                                {intl.formatMessage({id: 'download_files_for_viewing'})}
                                            </h3>

                                            <h6>
                                                {intl.formatMessage({id: 'you_do_not_have_access_to_the_files_wait_until_you_receive_access'})}
                                            </h6>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <div className={"files p-0"}>

                                        <div>
                                            <div className={"mainTitle"}>
                                                <h3 className={"d-inline-block"}>
                                                    {intl.formatMessage({id: 'download_files_for_viewing'})}
                                                </h3>
                                                
                                            </div>
                                            
                                            <div className={"filesContainer"}>
                                                {
                                                    item.files.map((file, i) => (
                                                        <FileCard
                                                            key={i}
                                                            id={file.id}
                                                            type={file.type}
                                                            title={file.name}
                                                            size={file.size}
                                                            lastAccessed={moment(file.updated_at).format('YYYY-MM-DD')}
                                                            deleteButton={false}
                                                            path={file.path}
                                                        />
                                                    ))
                                                }
                                            </div>

                                            <div className={"mainTitle"}>
                                                <h3 className={"d-inline-block"}>
                                                    {intl.formatMessage({id: 'upload_the_files'})}

                                                </h3>

                                                <label htmlFor={"file-upload"} className={"addFile btn-primary"}>
                                                    <img src={"/images/add-circle-line.svg"} alt={""}/>
                                                    {intl.formatMessage({id: 'add_files'})}
                                                </label>
                                                <input id={"file-upload"} className={"d-none"} type="file"
                                                       onChange={(e) => addFile(e.target.files[0])}
                                                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf"/>

                                            </div>


                                            <div className={"filesContainer"}>
                                                {
                                                    
                                                    item.editedFiles.map((file, i) => (
                                                        <FileCard
                                                            key={i}
                                                            id={file.id}
                                                            type={file.type}
                                                            title={file.name}
                                                            size={file.size}
                                                            lastAccessed={moment(file.updated_at).format('YYYY-MM-DD')}
                                                            deleteButton={false}
                                                            path={file.path}
                                                        />
                                                    ))
                                                }
                                                {
                                                    newFiles.map((file, i) => (
                                                        <FileCard
                                                            key={i}
                                                            id={i}
                                                            type={file.type === 'application/pdf' ? 'pdf' : 'doc'}
                                                            title={file.name}
                                                            size={file.size}
                                                            lastAccessed={''}
                                                            deleteButton={false}
                                                        />
                                                    ))
                                                }
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className={"saveFiles"}>
                                        <Button variant={"primary"} onClick={() => saveFiles()}>
                                            {intl.formatMessage({id: 'add_the_files'})}

                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                    }

                </Col>
            </Row>
        </div>
    )
}