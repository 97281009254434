import React from 'react';
import './style.css';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {server, server_path_files} from "../../../const";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function Notification({id,type,description,date,company = 0, image}) {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();
    
    const title = [
        intl.formatMessage({id: 'you_have_a_new_request'}),
        intl.formatMessage({id: 'you_have_a_contract_to_sign'}),
        intl.formatMessage({id: 'you_have_been_added_to_favorites'}),
        intl.formatMessage({id: 'you_have_a_new_view'}),
    ]    
    
    const link = [
        '/collaboration/',
        '/contracts',
        '/',
        '/collaboration',
    ]
    const getTimestamp = (date) => {
        if (date !== undefined) {
            const now_date = moment(new Date(), 'YYYY-MM-DD  h:mm:ss')
            now_date.set('hours',now_date.get('hours') - 3)
            date = moment(date, 'YYYY-MM-DD  h:mm:ss')
            if (now_date.diff(date, "years") > 0) {
                return now_date.diff(date, "years") + ' years ago'
            } else {
                if (now_date.diff(date, "months") > 0) {
                    return now_date.diff(date, "months") + ' months ago'
                } else {
                    if (now_date.diff(date, "weeks") > 0) {
                        return now_date.diff(date, "weeks") + ' weeks ago'
                    } else {
                        if (now_date.diff(date, "days") > 0) {
                            return now_date.diff(date, "days") + ' days ago'
                        } else {
                            if (now_date.diff(date, "hours") > 0) {
                                return now_date.diff(date, "hours") + ' hours ago'
                            } else {
                                if (now_date.diff(date, "minutes") > 0) {
                                    return now_date.diff(date, "minutes") + ' minutes ago'
                                } else {
                                    if (now_date.diff(date, "seconds") > 0) {
                                        return now_date.diff(date, "seconds") + ' seconds ago'
                                    }
                                }
                            }
                        }
                    }
                }

            }

        }
    }

    const markAsRead = () => {
        fetch(server + '/notifications/mark_as_read/' + id, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    
    return (
        <Link className={"notification"} to={link[type] +(type === 0 ?  company === 0 ? 'agents': 'companies' : '') } onClick={() => markAsRead()}>
            <Row>
                <Col md={12}>
                    <div className={"separator"}>
                    </div>
                </Col>
                
                <Col md={2} className={"itemNot"}>
                    <div className={"itemImg "}>
                        <img src={server_path_files + image} alt={""}/>
                    </div>
                </Col>
                <Col md={6}>
                    <div className={"title"}>
                        {type === 1 && company === 1 ?
                            intl.formatMessage({id:  'you_have_a_new_contract_signed'}) : intl.formatMessage({id: title[type]})}
                    </div>
                    <div className={"desc"}>
                        {description}
                    </div>
                </Col>
                <Col md={4} className={"date"}>
                    {getTimestamp(date)}
                </Col>
            </Row>
        </Link>

    )
}