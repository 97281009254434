import React, {useEffect, useState} from 'react';
import {Switch, Route} from "react-router-dom";
import FinanceAbout from "./FinanceAbout/FinanceAbout";
import FinanceDescription from "./FinanceDescription/FinanceDescription";
import FinanceMenu from "./FinanceMenu/FinanceMenu";
import FinanceTask from "./FinanceTask/FinanceTask";
import {Button, Col, Row} from "react-bootstrap";
import moment from "moment";
import Modal from "react-modal";
import {server} from "../../const";
import {toast} from "react-toastify";
import "./style.css";
import {useIntl} from "react-intl";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        height: '50%'
    }
};


export default function Finance() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [note, setNote] = React.useState('');
    const [leads, setLeads] = useState('')
    const [activeLeadIndex, setActiveLeadIndex] = useState(0)
    const [activeLead, setActiveLead] = useState('')


    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }
    useEffect(() => {
        const getFinance = () => {
            fetch(server + '/finance/' + (sessionStorage.getItem('profileType') === 'user' ?  'agent':'company'), {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setLeads(json['data'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (leads === '') {
            getFinance()
        } else {
            if (leads[activeLeadIndex]) {

                if (sessionStorage.getItem('profileType') === 'user' ){
                    if (leads[activeLeadIndex]['agent_note'] !== null){
                        setNote(leads[activeLeadIndex]['agent_note'])
                    } else {
                        setNote('')
                    }
                } else {
                    if (leads[activeLeadIndex]['company_note'] !== null){
                        setNote(leads[activeLeadIndex]['company_note'])
                    } else {
                        setNote('')
                    }
                }
                
                
                
                setActiveLead(leads[activeLeadIndex])
            }
        }

    }, [token, activeLeadIndex, leads, intl]);

    const addNote = () => {
        var data = new FormData()
        sessionStorage.getItem('profileType') === 'user' ?
        data.append('agent_note', note)
            : 
            data.append('company_note', note)
            

        fetch(server + '/lead/note/' + activeLead['id'], {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.href = '/finance'
            }
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        leads !== '' && activeLead !== '' &&
        <div>
            <div className={"financeTitle"}>
                {intl.formatMessage({id: 'you_have'})} {leads.length} {intl.formatMessage({id: 'active_leads_first_lowercase'})}
            </div>

            <div className={"financeHeader"}>
                <div className={"arrowLeft"} onClick={() => leads[activeLeadIndex - 1] && setActiveLeadIndex(activeLeadIndex - 1)}>
                    <img src={"/images/arrow-left-s-line.svg"} alt={""}/>
                </div>

                <div className={"arrowRight"} onClick={() => leads[activeLeadIndex + 1] && setActiveLeadIndex(activeLeadIndex + 1)}>
                    <img src={"/images/arrow-right-s-line.svg"} alt={""}/>
                </div>
                <Row>
                    <Col md={2}>
                        <div className={"logoCont"}>
                            <img src={"/images/BriefcaseSimple.svg"} alt={""}/>
                        </div>
                    </Col>
                    <Col md={8} className={"position-relative"}>
                        <div className={"title"}>
                            {activeLead['subject']}
                        </div>
                        <div className={"desc"}>
                            {activeLead['about']}
                        </div>

                        <Row>
                            <Col>
                                <img src={"/images/region.svg"} className={"attrIcon"} alt={""}/>
                                <span className={"attrTitle"}>
                                 {intl.formatMessage({id: 'region'})} :
                            </span>{' '}
                                <span className={"attrVal"}>
                                {activeLead['region']}
                            </span>
                            </Col>
                            <Col>
                                <img src={"/images/budget.svg"} className={"attrIcon"} alt={""}/>
                                <span className={"attrTitle"}>
                                 {intl.formatMessage({id: 'budget'})} :
                            </span>{' '}
                                <span className={"attrVal"}>
                                {activeLead['budget'] + sessionStorage.getItem('currency')}
                            </span>
                            </Col>
                            <Col>
                                <img src={"/images/deadline.svg"} className={"attrIcon"} alt={""}/>
                                <span className={"attrTitle"}>
                                {intl.formatMessage({id: 'deadline'})} :
                            </span>{' '}
                                <span className={"attrVal"}>
                                {activeLead['deadline']}
                            </span>
                            </Col>
                            <Col>
                                <img src={"/images/date.svg"} className={"attrIcon"} alt={""}/>
                                <span className={"attrVal"}>
                                {moment(activeLead['created_at']).format("YYYY-MM-DD")}
                            </span>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={2} className={"d-flex"}>
                        <div>
                            <Button variant={"primary"} className={"mb-3"} onClick={() => window.location.href = '/inbox/' + activeLead['user_id']}>
                                <img src={"/images/send-plane-line.svg"} alt={""}/>
                                {intl.formatMessage({id: 'message'})}
                            </Button>

                                <Button variant={"primary"} onClick={() => openModal()}>
                                <img src={"/images/send-plane-line.svg"} alt={""}/>
                                    {intl.formatMessage({id: 'notes'})}
                            </Button>
                        </div>

                    </Col>
                </Row>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => closeModal()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <h2> {intl.formatMessage({id: 'notes'})}</h2>
                    <form>
                        <div>
                            <textarea className={"w-100 h-70"} style={{height: '160px'}} value={note} onChange={(e) => setNote(e.target.value)}/>
                            <Button className={"d-block float-right"} variant={"primary"} onClick={() => addNote()}> {intl.formatMessage({id: 'save'})}</Button>
                        </div>
                    </form>
                </Modal>
            </div>

            <FinanceMenu/>
            <div className={"tabContent"}>
                <Switch>
                    <Route path="/finance" exact>
                        <FinanceAbout
                            lead_id={activeLead['id']}
                        />
                    </Route>
                    <Route path="/finance/description">
                        <FinanceDescription
                            lead_id={activeLead['id']}
                        />
                    </Route>
                    <Route path="/finance/task">
                        <FinanceTask
                            lead_id={activeLead['id']}
                        />
                    </Route>
                </Switch>
            </div>


        </div>
    )
}