import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import {readableMonths, server} from "../../../const";
import DateSelector from "../../Utils/DateSelector/DateSelector";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function FinanceTask({lead_id}) {

    const date = new Date();
    const token = sessionStorage.getItem('token')
    const intl = useIntl();


    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());
    const [tasks, setTasks] = useState('')
    const [progress, setProgress] = useState(0)


    const incrementMonth = () => {
        setMonth(month === 11 ? 0 : month + 1)
    }
    const decrementMonth = () => {
        setMonth(month === 0 ? 11 : month - 1)
    }


    useEffect(() => {

        const getTasks = () => {
            setProgress(0)
            var data = new FormData()
            data.append('month', month)
            data.append('year', year)
            fetch(server + "/finance/tasks/" + lead_id, {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setTasks(json['data'])
                    let nrTasksDone = 0
                    let proc = 0
                    json['data'].forEach((task, i) => {
                        if (task['statistics'] !== null && task['statistics']['done'] === 1) {
                            proc += 100 * task['statistics']['units'] / task['total']
                            nrTasksDone += 1
                        }
                    })
                    if (nrTasksDone > 0){
                        setProgress(proc / nrTasksDone)
                    }
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getTasks()
    }, [lead_id, token, month, year, intl])


    const addDoneTask = (task_id) => {
        var data = new FormData()
        data.append('month', month)
        data.append('year', year)
        fetch(server + '/finance/add_done_task/' + task_id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.reload()
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }


    const addUnitsTask = (task_id, units) => {
        var data = new FormData()
        data.append('month', month)
        data.append('year', year)
        data.append('units', units)
        fetch(server + '/finance/add_units_task/' + task_id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.reload()
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        tasks !== '' &&
        <div className={"financeTask"}>
            <Row>
                <Col md={12}>

                    <DateSelector
                        value={readableMonths[month]}
                        increment={incrementMonth}
                        decrement={decrementMonth}
                    />

                    <DateSelector
                        value={year}
                        increment={() => setYear(year + 1)}
                        decrement={() => setYear(year - 1)}
                    />
                </Col>
            </Row>

            <Row className={"title"}>
                <Col>
                    <h2 className={"d-inline-block"}>
                        {intl.formatMessage({id: 'list_of_tasks'})}
                    </h2>

                    <h2 className={"d-inline-block"}>
                        {intl.formatMessage({id: 'achievement_level'})} {parseFloat(progress).toFixed(2) + ' %'}
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col>
                    {tasks !== '' &&
                    <table className={"table tasks"}>
                        <thead>
                        <tr>
                            <th>
                                {intl.formatMessage({id: 'accomplished'})}
                            </th>
                            <th className={"denumire"}>
                                {intl.formatMessage({id: 'name'})}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'units'})}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'deadline'})}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            tasks.map((task, i) => (
                                <tr key={i}>
                                    <td className={"position-relative"}>
                                        <label className={"containerCheckbox"} >
                                            {task.statistics !== null && task.statistics.done === 1 ?
                                                <input type={"checkbox"} checked disabled={sessionStorage.getItem('profileType') !== 'user'} onChange={() => ''}/>
                                                :
                                                <input type={"checkbox"} disabled={sessionStorage.getItem('profileType') !== 'user'} onChange={() => sessionStorage.getItem('profileType') === 'user' && addDoneTask(task.id)}/>
                                            }

                                            <span className={"checkmark"}>
                                                <img src={"/images/checkbox-thick.svg"} alt={""}/>
                                            </span>
                                        </label>

                                    </td>
                                    <td className={"denumire"}>
                                        {task.name}
                                    </td>
                                    <td>
                                        <div className={"d-flex"}>
                                            {
                                                sessionStorage.getItem('profileType') === 'user' &&
                                                <button className={"updateUnits"}
                                                        onClick={() => addUnitsTask(task.id, task.statistics !== null ? task.statistics.units - 1 : 0)}
                                                >
                                                    -</button>
                                            }
                                            <div className={"units"}>
                                                {task.statistics !== null ?
                                                    task.statistics.units
                                                    :
                                                    0}
                                            </div>
                                            {
                                                sessionStorage.getItem('profileType') === 'user' &&
                                                <button className={"updateUnits"}
                                                        onClick={() => addUnitsTask(task.id, task.statistics !== null ? task.statistics.units + 1 : 1)}
                                                >+</button>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        {task.deadline}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>

                    </table>
                    }
                </Col>
            </Row>

        </div>
    )
}