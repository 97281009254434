import React, {useEffect, useState} from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import Opportunity from "../../Utils/Opportunity/Opportunity";
import FileCard from "../../Utils/FileCard/FileCard";
import {server} from "../../../const";
import {toast} from "react-toastify";
import moment from 'moment'
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";


export default function CompanyAbout() {

    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const user_id = useParams().id
    
    const [opportunities,setOpportunities] = useState([])
    const [about,setAbout] = useState('')
    const [files,setFiles] = useState([])


    useEffect(() => {

        const getAbout = () => {

            fetch(server + '/company/about/' + user_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }


            }).then(response => {
                return response.json()
            }).then(json => {
                if (json['about_clients']) {
                    setFiles(json['files'])
                    setOpportunities(json['opportunities'])
                    setAbout(json['about_clients'])
                }
            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        
        if (about === ''){
            getAbout()
        }
    },[about,token,user_id,intl] );



    return (
        <div className={"companyAbout"}>
            <Row>
                <Col>
                    <h2 className={"mb-3"}>
                        { intl.formatMessage({id: 'company_opportunities'})}
                    </h2>
                    <p>
                        {about}
                    </p>
                    
                    {
                        opportunities.map((opportunity,i) => (
                            <Opportunity
                                id={opportunity.id}
                                title={opportunity.subject}
                                desc={opportunity.about}
                                region={opportunity.region}
                                budget={opportunity.budget}
                                deadline={moment(opportunity.created_at).format('YYYY-MM-DD')}
                                date={moment(opportunity.deadline).format('YYYY-MM-DD')}
                                image={""}
                            />
                        ))
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className={"mb-4 mt-2"}>
                        { intl.formatMessage({id: 'download_company_materials'})}
                    </h2>
                   
                    <div className={"filesContainer"}>
                        {
                             files && files.map((file,i) => (
                                <FileCard
                                    key={i}
                                    id={file.id}
                                    type={file.type}
                                    title={file.name}
                                    size={file.size}
                                    path={file.path}
                                    lastAccessed={moment(file.updated_at).format('YYYY-MM-DD')}
                                    deleteButton={false}
                                />
                            ))
                        }    
                    </div>
                   
                    
                </Col>
            </Row>
        </div>
    )
}