import React, {useEffect, useState} from 'react';
import './style.css';
import {Col, Dropdown, Row} from "react-bootstrap";
import {server} from "../../const";
import {toast} from "react-toastify";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useIntl} from "react-intl";

export default function Admin() {

    const intl = useIntl();
    const token = sessionStorage.getItem('token')
    
    const [data, setData] = useState('')
    const [type, setType] = useState('company')
    
    useEffect(() => {
        const getData = () => {
            var data = new FormData()
            data.append('type', type)
            fetch(server + '/admin', {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    setData(json['data'])
                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }

        if (data === '') {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token,type])

    const blockUser = (user_id) => {

        var data = new FormData()
        data.append('user_id', user_id)
        fetch(server + '/block_user', {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            toast.success(json['data'])
        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        sessionStorage.getItem('profileType') === 'admin' &&
        <div className={"report"}>
            <Row>
                <Col>
                        <div className={"item checkbox " + (type === 'company' ? "checked" : "")} onClick={() => {
                            setType('company')
                            setData('')
                        }}>
                            {
                                type === 'company' ?
                                    <img src={"/images/check-line2.svg"} alt={""}/>
                                    :
                                    ''
                            }
                            {intl.formatMessage({id: 'companies'})}
                        </div>
                        <div className={"item checkbox " + (type === 'agent' ? "checked" : "")} onClick={() => {
                            setType('agent')
                            setData('')
                        }}>
                            {
                                type === 'agent' ?
                                    <img src={"/images/check-line2.svg"} alt={""}/>
                                    :
                                    ''
                            }
                            {intl.formatMessage({id: 'agents'})}
                        </div>
                </Col>
            </Row>
            
            {
                data && data.length !== 0 &&
                <table className={"tasks invoices w-100"}>
                    <thead>
                    <tr>
                        <th>
                            {intl.formatMessage({id: 'no'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'name'})}

                        </th>
                        <th>
                            {intl.formatMessage({id: 'email'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'companies'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'location'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'companies'})}
                        </th>
                        <th>
                            {intl.formatMessage({id: 'actions'})}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((item, i) => (
                            <tr key={i}>
                                <td>
                                    {i}
                                </td>
                                <td>
                                    {item.name ? item.name : item.first_name +' '+ item.last_name}
                                </td>
                                <td>
                                    {item.email}
                                </td>
                                <td>
                                    {item.phone}
                                </td>
                                <td>
                                    {item.address ? item.address : ''}
                                </td>
                                <td className={"etape"}>
                                    <div className={"value"}>
                                        {parseFloat(item.phone? 100: 0).toFixed(1) + ' %'}
                                    </div>
                                        <ProgressBar variant="success" now={parseInt(item.phone? 100: 0)} max={100}/>
                                </td>
                                <td>
                                    <div className={"actions"}>
                                        <img className={"mess"} src={'/images/send-plane-line-blue.svg'} alt={''} onClick={() => window.location.href='/inbox/' + item.user_id}/>
                                        <Dropdown>
                                            <Dropdown.Toggle variant={"info"}>

                                                <div className={"itemHeader"}>
                                                    <img src={'/images/more-2-line.svg'} alt={""}/>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className={"title"} onClick={() => blockUser(item.user_id)}>
                                                    {intl.formatMessage({id: 'block'})}
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>

            }

         
        </div>
    )
}