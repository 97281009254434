import React from 'react';
import {useIntl} from "react-intl";
export default function PackagesAgents() {
    const intl = useIntl();


    return (
        <div className={"packages"}>
            <h3>
                {intl.formatMessage({id: 'package_activated'})}
            </h3>
        </div>


    )
}