import React, {useEffect, useState} from 'react';
import {Row, Col, Button} from "react-bootstrap";
import './style.css';
import {readableMonths, server, server_path_files} from "../../../const";
import DateSelector from "../../Utils/DateSelector/DateSelector";
import {toast} from "react-toastify";
import {useIntl} from "react-intl";

export default function FinanceAbout({lead_id}) {

    const date = new Date();
    const token = sessionStorage.getItem('token')
    const intl = useIntl();

    const [statistics, setStatistics] = useState('')
    const [commission, setCommission] = useState(0)
    const [due, setDue] = useState(0)
    const [paid, setPaid] = useState(0)
    const [budget, setBudget] = useState(0)

    const [month, setMonth] = useState(date.getMonth());
    const [year, setYear] = useState(date.getFullYear());

    const incrementMonth = () => {
        setMonth(month === 11 ? 0 : month + 1)
    }

    const decrementMonth = () => {
        setMonth(month === 0 ? 11 : month - 1)
    }

    useEffect(() => {
        const getFinanceStatistics = () => {
            var data = new FormData()
            data.append('month', month)
            data.append('year', year)
            fetch(server + '/finance/statistics/' + lead_id, {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {
                return response.json()
            }).then(json => {
                if (!json['data']) {
                    toast.warning(intl.formatMessage({id: 'error'}));
                } else {
                    if (json['data']['statistics'] !== null) {
                        setDue(json['data']['statistics']['due'])
                        setPaid(json['data']['statistics']['paid'])
                    } else {
                        setDue(0)
                        setPaid(0)
                    }

                    if (json['data']['commission'] !== null) {
                        setCommission(json['data']['commission'])
                    } else {
                        setCommission(0)
                    }
                    setBudget(json['data']['budget'])
                    setStatistics(json['data'])

                }

            }).catch(e => {
                toast.warning(intl.formatMessage({id: 'error'}));
            });
        }
        getFinanceStatistics()
    }, [year, month, lead_id, token, intl])


    const addFinanceStatistics = () => {
        var data = new FormData()
        data.append('month', month)
        data.append('year', year)
        data.append('commission', commission)
        data.append('due', due)
        fetch(server + '/finance/add_statistics/' + lead_id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.reload()
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }


    const addInvoice = () => {
        var data = new FormData()
        data.append('month', month)
        data.append('year', year)
        data.append('commission', commission)
        data.append('due', due)
        fetch(server + '/finance/add_invoice/' + lead_id, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => {
            return response.json()
        }).then(json => {
            if (!json['data']) {
                toast.warning(intl.formatMessage({id: 'error'}));
            } else {
                window.location.reload()
            }

        }).catch(e => {
            toast.warning(intl.formatMessage({id: 'error'}));
        });
    }

    return (
        <div className={"finance"}>
            <Row>
                <Col md={12}>

                    <DateSelector
                        value={readableMonths[month]}
                        increment={incrementMonth}
                        decrement={decrementMonth}
                    />

                    <DateSelector
                        value={year}
                        increment={() => setYear(year + 1)}
                        decrement={() => setYear(year - 1)}
                    />

                    {
                        sessionStorage.getItem('profileType') === 'user' &&
                            <Button variant={"primary"} className={"printButton m-2 mt-0"} onClick={() => addFinanceStatistics()}>
                                <img src={"/images/edit-2-line.svg"} alt={""}/>
                                {intl.formatMessage({id: 'save'})}
                            </Button>
                    }

                    {
                        sessionStorage.getItem('profileType') === 'user' &&
                        <Button className={"printButton"} variant={"secondary"} onClick={() => addInvoice()}>
                        <img src={"/images/check-line2.svg"} alt={""}/>
                            {intl.formatMessage({id: 'add_invoice'})}
                    </Button>}

                </Col>
            </Row>
            <Row>
                <Col>
                    <table className={"table sellers"}>
                        <thead>
                        <tr>
                            <th className={"name"}>
                                {intl.formatMessage({id: 'sales_agent'})}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'commission_applied'})}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'commissions_paid'})}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'commission_due'})}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'goal_achievement'})}{/*cat e realizat(platit)*/}
                            </th>
                            <th>
                                {intl.formatMessage({id: 'total_commission'})}
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {statistics !== '' &&
                        <tr>
                            <td className={"name"}>
                                <img src={server_path_files + statistics['agent']['logo']} alt={""}/>

                                {statistics['agent']['first_name'] + ' ' + statistics['agent']['last_name']}

                            </td>

                            {
                                sessionStorage.getItem('profileType') === 'user' && month >= date.getMonth() && year >= date.getFullYear() ?
                                    <td className={"p-0"}>
                                        <input value={commission} onChange={(e) => setCommission(e.target.value)}/>
                                    </td>
                                    :
                                    <td>
                                        {commission + ' %'}
                                    </td>
                            }

                            <td>
                                {(paid !== null? paid: 0) + sessionStorage.getItem('currency')}
                            </td>

                            {
                                sessionStorage.getItem('profileType') === 'user' && month >= date.getMonth() && year >= date.getFullYear() ?
                                    <td className={"dat p-0"}>
                                        <input value={due} onChange={(e) => setDue(e.target.value)}/>
                                    </td>

                                    :
                                    <td className={"dat"}>
                                        {due - paid + sessionStorage.getItem('currency')}
                                    </td>
                            }


                            <td className={"obi"}>

                                {
                                    paid ?
                                        parseFloat(((paid * 100) / ((commission * budget) / 100))).toFixed(2) + ' %'
                                        :
                                        0 + ' %'
                                }

                            </td>
                            <td>
                                {commission && ((commission * budget) / 100) + sessionStorage.getItem('currency')}
                            </td>
                        </tr>}
                        </tbody>
                        {
                            sessionStorage.getItem('profileType') !== 'user' &&
                            <thead>
                            <tr>
                                <th className={"name"}>
                                    {intl.formatMessage({id: 'total'})}
                                </th>
                                <th>
                                    {commission + ' %'}
                                </th>
                                <th>
                                    {(paid !== null? paid: 0 )+ sessionStorage.getItem('currency')}
                                </th>
                                <th>
                                    {due - paid + sessionStorage.getItem('currency')}
                                </th>
                                <th>

                                    {
                                        paid ?
                                            parseFloat(((paid * 100) / ((commission * budget) / 100))).toFixed(2) + ' %'
                                            :
                                            0 + ' %'
                                    }
                                </th>
                                <th>
                                    {(commission * budget) / 100 + sessionStorage.getItem('currency')}
                                </th>
                            </tr>

                      {/*      <tr>
                                <th colSpan={3} className={"empty"}>

                                </th>
                                <th className={"green"}>
                                    <div className={"d-flex"}>
                                        <div className={"w-100"}>
                                            Tax : 15 %
                                        </div>
                                    </div>

                                </th>
                                <th className={"green"}>
                                    <div className={"d-flex"}>
                                        <div className={"info"}>
                                            Grand Total :
                                        </div>
                                        <div className={"info"}>
                                            345678
                                        </div>
                                    </div>
                                </th>
                                <th className={"green"}>
                                    <div className={"d-flex"}>
                                        <div className={"info"}>
                                            Sub-Total Amound :
                                        </div>
                                        <div className={"info"}>
                                            34 000
                                        </div>
                                    </div>
                                </th>
                            </tr>*/}
                            </thead>
                        }

                    </table>
                </Col>
            </Row>

        </div>
    )
}