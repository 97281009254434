import React from 'react';
import {Row, Col} from "react-bootstrap";
import './style.css';
import {NavLink} from "react-router-dom";
import {useIntl} from "react-intl";

export default function FinanceMenu() {
    const intl = useIntl();

    return (
        <div className={"FinanceMenu"}>
            <Row>
                <Col md={3}>
                    <NavLink to={"/finance"} exact>
                        <div className={"tab"}>
                            {intl.formatMessage({id: 'lead_realization'})}
                        </div>
                    </NavLink>
                </Col>
                <Col md={3}>
                    <NavLink to={"/finance/description"}>
                        <div className={"tab"}>
                            {intl.formatMessage({id: 'description_lead'})}
                        </div>
                    </NavLink>
                </Col>
                <Col md={3}>
                    <NavLink to={"/finance/task"}>
                        <div className={"tab"}>
                            {intl.formatMessage({id: 'tasks_situation'})}
                        </div>
                    </NavLink>
                </Col>
            </Row>
        </div>
    )
}