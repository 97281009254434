import React from 'react';
import './style.css';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";

export default function Leads2({id, title,decription,region,budget, dateStart, dateEnd,leads = false,expired,completed, type =0}) {
    const intl = useIntl();
    
    return (
        <Row className={"leads2"}>
            <Col md={2} className={"d-inline-flex"}>
                <div className={"logoCont"}>
                    <img src={"/images/" + (completed ? 'BriefcaseSimpleGreen.svg' : expired ? 'BriefcaseSimpleRed.svg' : 'BriefcaseSimple.svg')} alt={""}/>
                </div>
            </Col>
            <Col md={8} className={"position-relative"}>
                <div className={"title"}>
                    {title}
                </div>
                <div className={"desc"}>
                    {decription}
                </div>

                <Row>
                    <Col>
                        <img src={"/images/region.svg"} className={"attrIcon"} alt={""}/>
                        <span className={"attrTitle"}>
                            {intl.formatMessage({id: 'region'})} :
                            </span>{' '}
                        <span className={"attrVal"}>
                                {region}
                            </span>
                    </Col>
                    <Col>
                        <img src={"/images/budget.svg"} className={"attrIcon"} alt={""}/>
                        <span className={"attrTitle"}>
                                {intl.formatMessage({id: 'budget'})} :
                            </span>{' '}
                        <span className={"attrVal"}>
                            {budget + sessionStorage.getItem('currency')}
                            </span>
                    </Col>
                    <Col>
                        <img src={"/images/deadline.svg"} className={"attrIcon"} alt={""}/>
                        <span className={"attrTitle"}>
                                {intl.formatMessage({id: 'deadline'})} :
                            </span>{' '}
                        <span className={"attrVal"}>
                                {dateEnd}
                            </span>
                    </Col>
                    <Col>
                        <img src={"/images/date.svg"} className={"attrIcon"} alt={""}/>
                        <span className={"attrVal"}>
                            {dateStart}
                            </span>
                    </Col>
                </Row>

                <div className={"separator"}>

                </div>
            </Col>

            <Col md={2} className={"d-flex justify-content-end"}>
                <Button variant={"secondary"}>
                    <Link to={leads ? (type === 1? '/agent-leads/edit/': '/leads/edit/') + id : '/opportunity/edit/' + id}>
                        <img src={"/images/edit-line-blue.svg"} className={"editOportunity"} alt={""}/>
                        {intl.formatMessage({id: 'edit'})}
                    </Link>
                </Button>
            </Col>
        </Row>
    )
}