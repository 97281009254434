import React from 'react';
import './style.css';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import moment from 'moment'
import {useIntl} from "react-intl";

export default function LastOpportunities({opportunities}) {
    const intl = useIntl();
    const typeOps = [
        'private_opportunities',
        'public_opportunities',
        'financing',
        'auction',
        'opportunities_for_agents'
    ]
    return (
        <div className={"item"}>
            <h2>
                {intl.formatMessage({id: 'last_opportunities'})}
            </h2>
            {
                opportunities.map((item,i) => (
                    <div key={i} className={"itemOpportunity " + (i === 0 || i === 3? 'blue': i===2 ? 'yellow' : 'green')}>
                        <Row>
                            <Col md={2} className={"text-center"}>
                                <div className={"logoCont"}>
                                    <img src={
                                        i === 1 ? 
                                            "/images/dashboard/building-line.svg":
                                            i === 2 ?
                                                "/images/dashboard/MegaphoneWhite.svg" :
                                                i === 3 ?
                                                    "/images/dashboard/BriefcaseSimple.svg":
                                                    "/images/dashboard/BriefcaseSimple.svg"
                                                    
                                    } alt={""}/>
                                </div>
                            </Col>
                            <Col md={7} className={"position-relative"}>

                                <div className={"title"}>
                                    {intl.formatMessage({id: typeOps[item.type]})}  {': '+ item.subject}
                                </div>
                                <div>
                                    <Row>
                                        <Col md={6}>
                                            <div className={"dateStart"}>
                                                Start : {moment(item.created_at).format("YYYY-MM-DD")}
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className={"dateEnd"}>
                                                {intl.formatMessage({id: 'expire'})} : {item.deadline}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={"separator"}>

                                </div>
                            </Col>
                            <Col md={3}>
                                <div className={"detailsOp"}>
                                    <div className={"value"}>
                                        {item.budget + sessionStorage.getItem('currency')}
                                    </div>
                                    <div className={"details"}>
                                        <Link to={"/view-opportunity/"+ item.type +"/" + item.id}>
                                            {intl.formatMessage({id: 'details'})}
                                            <img src={"/images/dashboard/arrow-right-line-purple.svg"} alt={""}/>
                                        </Link>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>
                ))
            }
        </div>
    )
}